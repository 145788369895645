import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { useParams } from "react-router-dom";
import { handlecloseloader, handleopenloader } from "../Loader/Loader";
import { handleopensnackbar } from "../Snackbarwithmessages/Snackbarwithmessages";
import type { RootState } from "../store";

// Define a type for the slice state
interface testState {
  fetcheddatadashboard: [];
  fetcheddataprivatedashboard: [];
  fetcheddatasubmitted: [];
  fetchedannouncements: []
  fetchdashboardstats: [];
  alldatabaseconfig: any;
}

// Define the initial state using that type
const initialState: testState = {
  fetcheddatadashboard: [],
  fetcheddataprivatedashboard: [],
  fetcheddatasubmitted: [],
  fetchedannouncements: [],
  fetchdashboardstats: [],
  alldatabaseconfig: [],
};

export const searchtest = createAsyncThunk(
  "testredux/searchtest",
  (payload: any, { dispatch }) => {
    dispatch(handleopenloader());
    return axios
      .post(`/getexamdata/searchtest`, payload)
      .then(function (response) {
        dispatch(handlecloseloader());
        return response?.data;
      })
      .catch(function (e) {
        dispatch(handlecloseloader());

        const payloaddata = {
          messages: e?.response?.data?.msg || e?.msg,
          severity: "error",
        };

        dispatch(handleopensnackbar(payloaddata));
      });
  }
);


export const fetchdashboardstats = createAsyncThunk(
  "testredux/fetchdashboardstats",
  (payload: any, { dispatch }) => {
    dispatch(handleopenloader());
    return axios
      .get(`/dashboard/getdashboarddata`)
      .then(function (response) {
        dispatch(handlecloseloader());
        return response?.data;
      })
      .catch(function (e) {
        dispatch(handlecloseloader());

        const payloaddata = {
          messages: e?.response?.data?.msg || e?.msg,
          severity: "error",
        };

        dispatch(handleopensnackbar(payloaddata));
      });
  }
);
export const fetchdashboard = createAsyncThunk(
  "testredux/fetchdashboard",
  (payload: any, { dispatch }) => {
    dispatch(handleopenloader());
    return axios
      .get(`/dashboard/getalltestseries/${payload.type}`)
      .then(function (response) {
        dispatch(handlecloseloader());
        return response?.data;
      })
      .catch(function (e) {
        dispatch(handlecloseloader());

        const payloaddata = {
          messages: e?.response?.data?.msg || e?.msg,
          severity: "error",
        };

        dispatch(handleopensnackbar(payloaddata));
      });
  }
);

export const fetchannouncement = createAsyncThunk(
  "testredux/fetchannouncement",
  (payload: any, { dispatch }) => {
    dispatch(handleopenloader());
    return axios
      .get(`/dashboard/getannoucements`)
      .then(function (response) {
        dispatch(handlecloseloader());
        return response?.data;
      })
      .catch(function (e) {
        dispatch(handlecloseloader());

        const payloaddata = {
          messages: e?.response?.data?.msg || e?.msg,
          severity: "error",
        };

        dispatch(handleopensnackbar(payloaddata));
      });
  }
);

export const fetchassignedtestseries = createAsyncThunk(
  "testredux/fetchassignedtestseries",
  (payload: any, { dispatch }) => {
    dispatch(handleopenloader());
    return axios
      .get(`/dashboard/getassignedtestseries`)
      .then(function (response) {
        dispatch(handlecloseloader());
        return response?.data;
      })
      .catch(function (e) {
        dispatch(handlecloseloader());

        const payloaddata = {
          messages: e?.response?.data?.msg || e?.msg,
          severity: "error",
        };

        dispatch(handleopensnackbar(payloaddata));
      });
  }
);

export const fetchsubmittedtest = createAsyncThunk(
  "testredux/fetchsubmittedtest",
  (payload: any, { dispatch }) => {
    dispatch(handleopenloader());
    return axios
      .get(`/dashboard/getallsubmittedtestseries/${payload?.type}`)
      .then(function (response) {
        dispatch(handlecloseloader());
        return response?.data;
      })
      .catch(function (e) {
        dispatch(handlecloseloader());

        const payloaddata = {
          messages: e?.response?.data?.msg || e?.msg,
          severity: "error",
        };

        dispatch(handleopensnackbar(payloaddata));
      });
  }
);

const token = JSON.parse(
  localStorage.getItem("userdetails") as string
)?.token

export const getalldatabaseconfig = createAsyncThunk(
  "testredux/getalldatabaseconfig",
  (payload: any, { dispatch }) => {
    dispatch(handleopenloader());
    return axios
      .get(`/dashboard/getdatabaseconfig`, { headers: { authorization: `Bearer ${token}` } })
      .then(function (response) {
        dispatch(handlecloseloader());
        if (response?.status === 200 && response?.data?.success) {
          return response?.data;
        }
      })
      .catch(function (e) {
        dispatch(handlecloseloader());
      });
  }
);

export const TestSeriesSlice = createSlice({
  name: "testseries",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchdashboard.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.fetcheddatadashboard = action.payload;
      }
    );
    builder.addCase(
      fetchannouncement.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.fetchedannouncements = action.payload;
      }
    );
    builder.addCase(
      fetchsubmittedtest.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.fetcheddatasubmitted = action.payload;
      }
    );
    builder.addCase(
      searchtest.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.fetcheddatadashboard = action.payload;
      }
    );
    builder.addCase(
      fetchassignedtestseries.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.fetcheddataprivatedashboard = action.payload;
      }
    );
    builder.addCase(
      fetchdashboardstats.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.fetchdashboardstats = action.payload;
      }
    );
    builder.addCase(
      getalldatabaseconfig.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.alldatabaseconfig = action.payload;
      }
    );
  },
});

export default TestSeriesSlice.reducer;
