import { useEffect, useState } from "react";
import { Alert, Badge, Button, OverlayTrigger } from "react-bootstrap";
import { FaPlay } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import DashboardModal from "../../../../../app/pages/dashboard/dashboardModal/DashboardModal";
import { VscDebugStart, VscInspect } from "react-icons/vsc";
import { BsCamera, BsFillPlayFill, BsMouse } from "react-icons/bs";
import Tooltip from "react-bootstrap/Tooltip";
import { FiCopy } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { MdOutlineSchedule, MdOutlineScreenShare, MdScreenShare } from "react-icons/md";
import "./Cards20-2.scss";
import Modalpre from "./preregistrationmodal/Modalpre";
import DOMPurify from "dompurify";
import { DateTime } from "luxon";
import { useAppSelector } from "../../../../../Redux/hooks";

type Props = {
  className: string;
  description: string;
  title: string;
  color: string;
  img: string;
  duration: string;
  createdat: string;
  testid: string;
  scheduled: string;
  test_settings: any;
  istestseries: number;
  result_declare: any;
  count: any;
  id: number;
  fetchdata: any;
  preregistration_status: number;
  preregistration_to: String;
  preregisteration_from: String;
  fetchdataprewhole: any;
};

const renderTooltip = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    This is a scheduled test
  </Tooltip>
);
const renderTooltip4 = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    Camera needed
  </Tooltip>
);
const renderTooltip5 = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    Screen sharing needed
  </Tooltip>
);

export function LessDataCardsWidget20({
  className,
  description,
  title,
  color,
  img,
  duration,
  createdat,
  testid,
  scheduled,
  test_settings,
  istestseries,
  result_declare,
  id,
  count,
  fetchdata,
  preregistration_status,
  preregistration_to,
  preregisteration_from,
  fetchdataprewhole,
}: Props) {
  const systemTimeZone = useAppSelector((state) => state.testseries.alldatabaseconfig).data[0]?.config_value || process.env.REACT_APP_TIMEZONE;


  const [show, setShow] = useState(false);
  const [countvalue, setcountvalue] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);




  useEffect(() => {
    let abc: any = [];

    fetchdata.forEach((each: any) => {

      if (each?.id === id) {
        abc.push(each?.id);
      }
    });
    setcountvalue(abc?.length);
  }, [count, fetchdata, title, id]);




  const [dateandtime, setdateandtime] = useState<any>("");


  useEffect(() => {
    if (scheduled && systemTimeZone) {
      // Convert the datetime string to ISO 8601 format (adding "T" and timezone offset)
      const isoFormattedDateTime = scheduled.replace(' ', 'T') + '+00:00';

      const scheduleDateTime = DateTime.fromISO(isoFormattedDateTime, { zone: systemTimeZone.toLowerCase() });

      if (scheduleDateTime.isValid) {
        const localDateTime = scheduleDateTime.toLocal();
        const localDateTimeString = localDateTime.toFormat("yyyy-MM-dd HH:mm:ss");

        console.log("🚀 ~ useEffect ~ localDateTime:", localDateTimeString);
        setdateandtime(localDateTimeString);
      } else {
        console.log("Invalid scheduled datetime format:", scheduled);
      }
    }
  }, [scheduled, systemTimeZone]);



  const [fromtime, setfromtime] = useState<any>(false)
  const [totime, settotime] = useState<any>(false)

  const [preregistrationdatetimestatus, setpreregistrationstatus] =
    useState<boolean>(false);
  const [preregistrationdatetimestatus2, setpreregistrationstatus2] =
    useState<boolean>(false);


  useEffect(() => {
    if (preregistration_status === 1 && preregisteration_from && preregistration_to && systemTimeZone) {
      const isoFormattedDateTimeTo = preregistration_to.replace(' ', 'T') + '+00:00';
      const isoFormattedDateTimeFrom = preregisteration_from.replace(' ', 'T') + '+00:00';

      const preTo = DateTime.fromISO(isoFormattedDateTimeTo, { zone: systemTimeZone.toLowerCase() });
      const preFrom = DateTime.fromISO(isoFormattedDateTimeFrom, { zone: systemTimeZone.toLowerCase() });


      if (preTo.isValid && preFrom.isValid) {


        const currentLocalTime = DateTime.local(); // Current local time of the user's PC
        const localDateTime = preTo.toLocal();
        const localDateTime2 = preFrom.toLocal();
        const localDateTimeString = localDateTime.toFormat("yyyy-MM-dd HH:mm:ss");
        const localDateTimeString2 = localDateTime2.toFormat("yyyy-MM-dd HH:mm:ss");


        settotime(localDateTimeString);
        setfromtime(localDateTimeString2);

        if (currentLocalTime >= localDateTime2 && currentLocalTime <= localDateTime) {
          setpreregistrationstatus(true);
        } else {
          setpreregistrationstatus2(true);
        }
      } else {
        setpreregistrationstatus(false);
        setpreregistrationstatus2(false);
      }


    } else {
      console.log("Invalid preregsitration datetime format:", preregisteration_from, preregistration_to, systemTimeZone);
    }


  }, [preregistration_status, preregisteration_from, preregistration_to, systemTimeZone]);






  const [existinpre, setexistinpre] = useState(false);
  useEffect(() => {
    fetchdataprewhole.map((each: any) => {
      if (each?.assesment_id == testid) {
        setexistinpre(true);
      }
    });
  }, [fetchdataprewhole, testid]);


  return (
    <>
      <div
        className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end abdxhnjksad ${className} maincardforhover`}
        style={{
          backgroundColor: "#03045e",
          backgroundImage: `url('${img}')`,
          height: "200px",
        }}
      >
        {/* header */}
        <div className="card-header pt-5" style={{ padding: '50px 30px ' }}>
          {/* title */}
          <span>
            <div>

              <Badge bg="primary" style={{ marginBottom: "6px" }}>
                {istestseries == 1 ? "Test Series" : ""}
              </Badge>
            </div>
            <div>

              <Badge bg="danger" style={{ marginBottom: "6px" }}>
                {preregistrationdatetimestatus ? "Pre-registration open" : ""}
              </Badge>
            </div>
            <div>

              <Badge style={{ marginBottom: "6px" }}>
                {preregistrationdatetimestatus2
                  ? "Pre-registered candidates only"
                  : ""}
              </Badge>
            </div>
          </span>
          <span
            className="d-flex justify-content-between fw-bold fs-6 text-white opacity-75 "
            style={{ marginTop: "-18px !important" }}
          >
            {(JSON.parse(test_settings)?.randomphotos || JSON.parse(test_settings)?.live_proctoring_camera) &&
              (JSON.parse(test_settings)?.randomphotos == "true" || JSON.parse(test_settings)?.live_proctoring_camera == "true") && (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip4}
                >
                  <span className="permissionsicons">
                    <BsCamera style={{ cursor: "pointer" }} size={20} />
                  </span>
                </OverlayTrigger>
              )}
            {(JSON.parse(test_settings)?.allowscreenrecording || JSON.parse(test_settings)?.live_proctoring_screen) &&
              (JSON.parse(test_settings)?.allowscreenrecording == "true" || JSON.parse(test_settings)?.live_proctoring_screen == "true") && (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip5}
                >
                  <span className="permissionsicons">
                    <MdOutlineScreenShare style={{ cursor: "pointer" }} size={20} />
                  </span>
                </OverlayTrigger>
              )}

            {scheduled && (
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <span>
                  <MdOutlineSchedule style={{ cursor: "pointer" }} size={20} />
                </span>
              </OverlayTrigger>
            )}

            {/* <span>{creator name here}</span> */}
          </span>
          <div className="card-title d-flex flex-column" style={{ width: "100%" }}>
            <h2 style={{ color: "white" }} onClick={() => {
              window.location.href = `/testoverview/${testid}`;
            }}>
              {title}
            </h2>
          </div>


        </div>

        {/* body */}
        <div className={`card-body d-flex  align-items-end pt-0 lowerendcard`} >
          {/* multiple div */}
          <div className="d-flex align-items-center flex-column mt-3 w-100">
            <div
              className="d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100  mb-4"
              style={{ marginTop: "-18px !important" }}
            >
              <span className="duration">Duration : {Math.round(parseFloat(duration) * 60)} Minutes</span>
              {/* <span>{creator name here}</span> */}
            </div>

            {/* <div
              className="d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100"
              style={{ marginTop: "10px !important" }}
            >
              {preregistrationdatetimestatus && (
                <Alert
                  style={{ padding: "4px 16px", width: "100%" }}
                  variant={"primary"}
                >
                  <div>
                    <div>
                      <div>Registration open</div>
                      <div className="datentime">{fromtime && fromtime}</div>{" "}
                      <div className="datenjoinee">to</div>{" "}
                      <div className="datentime">{totime && totime}</div>{" "}
                    </div>{" "}
                  </div>
                </Alert>
              )}
            </div> */}

            {/* div 4 */}

            {/* end buttton div */}
            <div className="h-40px mx-3 mb-4 w-100 wrap ">
              {!preregistrationdatetimestatus && preregistration_status ? (
                existinpre ? (
                  <div style={{ width: "72%", marginBottom: "10px" }}>
                    <div
                      className="playbutton"
                      onClick={() => {
                        window.location.href = `/testoverview/${testid}`;
                      }}
                    >
                      <p className="paragraphstart">
                        Start test
                        <BsFillPlayFill
                          size={20}
                          style={{ marginBottom: "0px" }}
                        />
                      </p>
                    </div>
                  </div>
                ) : (
                  <p style={{ color: "red" }}>
                    You have not preregistered for this test
                  </p>
                )
              ) : (
                ""
              )}

              {!preregistration_status && (
                <div style={{ width: "72%", marginBottom: "10px" }}>
                  <div
                    className="playbutton"
                    onClick={() => {
                      window.location.href = `/testoverview/${testid}`;
                    }}
                  >
                    <p className="paragraphstart">
                      Start test
                      <BsFillPlayFill
                        size={20}
                        style={{ marginBottom: "0px" }}
                      />
                    </p>
                  </div>
                </div>
              )}
              {preregistrationdatetimestatus && !existinpre && (
                <Link
                  to={`/dashboard/preregister/${id}`}
                  style={{ marginBottom: "10px", textDecoration: "underline" }}
                >
                  <Button variant="primary" style={{ marginBottom: "10px" }}>
                    Register now
                  </Button>
                </Link>
              )}
              {/* {preregistrationdatetimestatus && existinpre && (
                <p style={{ color: "white" }}>
                  You Have preregistered for this test please wait until the
                  test starts{" "}
                  <span
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: "#009cf5",
                    }}
                    onClick={handleShow2}
                  >
                    Update submitted form
                  </span>
                </p>
              )} */}
            </div>
            {show2 && (
              <Modalpre
                show={show2}
                handleClose={handleClose2}
                title={title}
                id={id}
              />
            )}
            <DashboardModal
              show={show}
              title={title}
              description={description}
              handleClose={handleClose}
            />
          </div>
        </div>
      </div>
    </>
  );
}
