import { useLocation } from "react-router-dom";
import { SidebarMenuMain } from "./SidebarMenuMain";
import { SidebarMenuMaindashboard } from "./SidebarMenuMaindashboard";
import Button from "@mui/material/Button";
import { FiLogOut } from "react-icons/fi";
import { toAbsoluteUrl } from "../../../../helpers";

const SidebarMenu = () => {
  const location = useLocation();
  // function handlelogout() {
  //   localStorage.removeItem("userdetails");
  //   localStorage.removeItem("application_mode");
  //   window.location.href = "/auth/1";
  // }
  return (
    <div className="app-sidebar-menu overflow-hidden flex-column-fluid">
      <div
        id="kt_app_sidebar_menu_wrapper"
        className="app-sidebar-wrapper hover-scroll-overlay-y my-5"
        data-kt-scroll="true"
        data-kt-scroll-activate="true"
        data-kt-scroll-height="auto"
        data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
        data-kt-scroll-wrappers="#kt_app_sidebar_menu"
        data-kt-scroll-offset="5px"
        data-kt-scroll-save-state="true"
        style={{ height: "79vh", overflow: "hidden" }}
      >
        <div
          className="menu menu-column menu-rounded menu-sub-indention px-3"
          id="#kt_app_sidebar_menu"
          data-kt-menu="true"
          data-kt-menu-expand="false"
        >
          {(location.pathname.slice(1, 5) == "test" || location.pathname.includes("preview")) && <SidebarMenuMain />}
          {location.pathname.includes("dashboard") && (
            <SidebarMenuMaindashboard />
          )}
        </div>
      </div>

      {/* {location.pathname.includes("dashboard") && (
        <Button
          style={{ width: "100%", marginLeft: "1px" }}
          variant="contained"
          color="error"
          onClick={handlelogout}
        >
          Logout <FiLogOut style={{ marginLeft: "2px" }} />{" "}
        </Button>
      )} */}
    </div>
  );
};

export { SidebarMenu };
