import {
  Avatar,
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { OverlayTrigger } from "react-bootstrap";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { handlechangepreview } from "../../../../Redux/CameraPreviewTrigger/CameraPreviewTrigger";
import { useAppDispatch, useAppSelector } from "../../../../Redux/hooks";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { HeaderNotificationsMenu, HeaderUserMenu } from "../../../partials";
import TimerContainer from "../sidebar/sidebar-menu/allquestionsaside/TimerContainer";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useEffect, useState } from "react";
import {
  fetchallnotifications,
  fetchunreadnotification,
  markasreadnotificationall,
  markasreadnotificationaone,
} from "../../../../Redux/Notifications/Notificationslice";
import AnimatedRecordingIcon from "../../../../app/pages/testpage/RecordingIcon/AnimatedRecordingIcon";
import styled from "@emotion/styled";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { fetchMyprofile } from "../../../../Redux/Profiledetails/Profiledetails";
import {
  HeadingsTypography,
  StyledTypography,
} from "../../../../utils/StyledTypographyHeadings";

const itemClass = "ms-1 ms-lg-3";
const btnClass =
  "btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px";
const userAvatarClass = "symbol-35px symbol-md-40px";
const btnIconClass = "svg-icon-1";

const RotatingBorder = styled("div")({
  position: "relative",
  display: "inline-block",
  borderRadius: "50%",
  overflow: "hidden",
  width: "150px", // Adjust size as needed
  height: "150px", // Adjust size as needed
  border: "10px solid transparent", // Adjust border width
  borderImage: "linear-gradient(45deg, #f06, #4a90e2) 1", // Gradient colors and spread
  animation: "spin 2s linear infinite",
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
});

const Navbar = () => {
  const location = useLocation();
  const fetchedsetting: any = useAppSelector(
    (state) => state?.tests?.fetchedsetting
  );

  const dispatch = useAppDispatch();

  const userProfile = useAppSelector(
    (state) => state?.profiledata?.fetchprofiledata
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handlelogout() {
    localStorage.removeItem("userdetails");
    localStorage.removeItem("application_mode");
    sessionStorage.clear();
    window.location.href = "/auth/1";
  }

  const open = Boolean(anchorEl);

  // const changePreviewhandler = () => {
  //   dispatch(handlechangepreview());
  // };

  const fetchunreadnotificationdata = useAppSelector(
    (state) => state.notificationredux.fetchedunreadnotificationdata
  );

  useEffect(() => {
    if (
      !location.pathname.includes("preview-test") ||
      location.pathname.slice(1, 5) == "test"
    ) {
      dispatch(fetchunreadnotification(dispatch));
      setInterval(() => {
        dispatch(fetchunreadnotification(dispatch));
      }, 40000);
    }
  }, []);

  function markallasread() {
    dispatch(markasreadnotificationall(dispatch)).then(() => {
      dispatch(fetchallnotifications(dispatch));
      dispatch(fetchunreadnotification(dispatch));
    });
  }

  function markoneasread(nid: number, url: string) {
    dispatch(markasreadnotificationaone({ notificationid: nid })).then(() => {
      window.location.href = url;
    });
  }

  const inTest =
    location.pathname.includes("preview") ||
    location.pathname.slice(1, 5) == "test";

  return (
    <div className="app-navbar flex-shrink-0">
      <div className={clsx("app-navbar-item gap-4", itemClass)}>
        <div
          className={clsx("cursor-pointer symbol", userAvatarClass)}
          // data-kt-menu-trigger="{default: 'click'}"
          // data-kt-menu-attach="parent"
          // data-kt-menu-placement="bottom-end"\
          style={{ marginRight: "20px" }}
        ></div>
        {(fetchedsetting?.randomphotos == "true" ||
          fetchedsetting?.live_proctoring_camera == "true") &&
          inTest && (
            // <Tooltip title="Preview camera">
            <div
              style={{ marginRight: "12px", cursor: "pointer" }}
              // onClick={changePreviewhandler}
            >
              {/* <img
                src={toAbsoluteUrl("/media/recording_dot.gif")}
                alt=""
                height={32}
              /> */}
              <AnimatedRecordingIcon />
            </div>
            // </Tooltip>
          )}
        {inTest && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              gap: 8,
            }}
          >
            <HeadingsTypography
              variant="h5"
              sx={{
                letterSpacing: "1px",
                fontSize: { xs: 12, sm: 14, xl: 18 },
              }}
            >
              {userProfile?.name?.length > 25
                ? userProfile?.name?.slice(0, 25) + "..."
                : userProfile?.name}
            </HeadingsTypography>
            <TimerContainer />
          </Box>
        )}

        {!inTest && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              gap: 2,
            }}
          >
            <div className={clsx("app-navbar-item", itemClass)}>
              <div
                data-kt-menu-trigger="{default: 'click'}"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
                // className={btnClass}
              >
                {fetchunreadnotificationdata?.data?.length ? (
                  <span
                    className="badge badge-light-primary"
                    style={{
                      position: "relative",
                      top: "-11px",
                      left: "47px",
                    }}
                  >
                    {fetchunreadnotificationdata?.data?.length}
                  </span>
                ) : (
                  ""
                )}
                <NotificationsIcon
                  fontSize="large"
                  onClick={() => {
                    dispatch(fetchallnotifications(dispatch));
                  }}
                  sx={{
                    cursor: "pointer",
                    color: "#000000 !important",
                    "&:hover": {
                      color: "#000000 !important",
                    },
                  }}
                />
              </div>
              <HeaderNotificationsMenu
                markoneasread={markoneasread}
                markallasread={markallasread}
                data={fetchunreadnotificationdata?.data}
              />
            </div>

            <Avatar
              alt="user_profile_image"
              src={
                userProfile && userProfile?.userimage
                  ? userProfile?.userimage
                  : toAbsoluteUrl("/media/blankprofile.webp")
              }
              sx={{ width: 40, height: 40, cursor: "pointer" }}
              onClick={handleClick}
            />

            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              disableScrollLock={true}
              PaperProps={{
                style: {
                  width: "300px",
                  borderRadius: "8px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: 2,
                  paddingY: 2,
                  paddingX: 5,
                  borderRadius: "8px",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 2,
                    paddingX: 20,
                  }}
                >
                  <Avatar
                    alt="user_profile_image"
                    src={
                      userProfile && userProfile?.userimage
                        ? userProfile?.userimage
                        : toAbsoluteUrl("/media/blankprofile.webp")
                    }
                    sx={{ width: 50, height: 50, cursor: "pointer", flex: 1 }}
                    onClick={handleClick}
                  />

                  <Box sx={{ flex: 1 }}>
                    <StyledTypography variant="body1" sx={{ fontWeight: 800 }}>
                      {userProfile?.name}
                    </StyledTypography>
                    <StyledTypography variant="body2" sx={{ fontWeight: 600 }}>
                      {userProfile?.email?.length > 35
                        ? userProfile?.email?.slice(0, 35) + "..."
                        : userProfile?.email}
                    </StyledTypography>
                  </Box>
                </Box>

                <MenuList sx={{ width: "300px" }}>
                  <MenuItem
                    sx={{
                      mb: 2,
                      "&:hover": {
                        backgroundColor: "#03045e",
                        "& .MuiListItemIcon-root > svg": {
                          color: "white",
                        },
                        "& .MuiTypography-root": {
                          color: "white",
                        },
                      },
                    }}
                    onClick={() => {
                      navigate("/dashboard/myprofile");
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <AccountCircleIcon
                        fontSize="medium"
                        sx={{ color: "#03045e" }}
                      />
                    </ListItemIcon>
                    <StyledTypography variant="body1" sx={{ fontWeight: 600 }}>
                      Profile
                    </StyledTypography>
                  </MenuItem>
                </MenuList>
              </Box>

              <Box
                sx={{
                  alignItems: "flex-end",
                  overflow: "hidden",
                  paddingX: 2,
                  paddingY: 1,
                }}
              >
                <Button
                  onClick={handlelogout}
                  sx={{
                    width: "100%",
                    borderRadius: 2,
                    backgroundColor: "#fed9d9",

                    paddingY: 1,
                    paddingX: 2,
                    "&:hover": {
                      backgroundColor: "#fed9d9",
                      color: "#B71D18",
                    },
                  }}
                >
                  <HeadingsTypography
                    variant="h5"
                    sx={{
                      color: "#B71D18",
                      textTransform: "initial",
                      letterSpacing: "1px",
                      "&:hover": {
                        color: "#B71D18",
                      },
                    }}
                  >
                    Logout
                  </HeadingsTypography>
                </Button>
              </Box>
            </Popover>
          </Box>
        )}

        <HeaderUserMenu />
      </div>
    </div>
  );
};

export { Navbar };
