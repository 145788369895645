import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

type Variant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

const headingSizes = {
  h1: "36px",
  h2: "30px",
  h3: "24px",
  h4: "20px",
  h5: "16px",
  h6: "14px",
};

export const HeadingsTypography = styled(Typography)(({ theme, variant }: { theme?: any, variant?: Variant}) => ({
  fontFamily: "Noto Sans, sans-serif !important",
  fontSize: headingSizes[variant ?? "h1"] || "inherit !important",
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Mulish, sans-serif !important",
})) as typeof Typography & {
  component?: React.ComponentType<any>;
};
