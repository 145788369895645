import { FC, useEffect } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { checkIsActive, KTSVG, WithChildren } from "../../../../helpers";
import { useLayout } from "../../../core";
import { BsExclamationCircleFill } from "react-icons/bs";
import { Tooltip } from "@mui/material";
import { useAppSelector } from "../../../../../Redux/hooks";

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  className: string;
  onClick?: () => void
};

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  className,
  onClick,
  hasBullet = false,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { app } = config;
  const profiledata = useAppSelector(
    (state) => state?.profiledata?.fetchprofiledata
  );
  // 
  return (
    <div className="menu-item">
      {onClick ?
        <div
          className={clsx(`menu-link without-sub ${className}`, { active: isActive })}
          onClick={onClick}
        >
          {hasBullet && (
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
          )}
          {icon && app?.sidebar?.default?.menu?.iconType === "svg" && (
            <span className="menu-icon">
              {" "}
              {to == "/dashboard/myprofile" && !profiledata?.userimage ? (
                <Tooltip title="Profile incomplete">
                  <div style={{ marginTop: "-3px" }}>
                    <BsExclamationCircleFill fill="red" size={18} />
                  </div>
                </Tooltip>
              ) : (
                <KTSVG path={icon} className="svg-icon-2" />
              )}
            </span>
          )}
          {fontIcon && app?.sidebar?.default?.menu?.iconType === "font" && (
            <i className={clsx("bi fs-3", fontIcon)}></i>
          )}
          <span className="menu-title">{title}</span>
        </div>
        :


        <Link
          className={clsx(`menu-link without-sub ${className}`, { active: isActive })}
          to={to}
        >
          {hasBullet && (
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
          )}
          {icon && app?.sidebar?.default?.menu?.iconType === "svg" && (
            <span className="menu-icon">
              {" "}
              {to == "/dashboard/myprofile" && !profiledata?.userimage ? (
                <Tooltip title="Profile incomplete">
                  <div style={{ marginTop: "-3px" }}>
                    <BsExclamationCircleFill fill="red" size={18} />
                  </div>
                </Tooltip>
              ) : (
                <KTSVG path={icon} className="svg-icon-2" />
              )}
            </span>
          )}
          {fontIcon && app?.sidebar?.default?.menu?.iconType === "font" && (
            <i className={clsx("bi fs-3", fontIcon)}></i>
          )}
          <span className="menu-title">{title}</span>
        </Link>
      }
      {children}
    </div>
  );
};

export { SidebarMenuItem };
