/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef } from "react";
import { Outlet, Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { RingsAnimation } from "./VantaEffect";
import "./AuthLayout.css";
import { GlobeAnimation } from "./VantaGlobeAnimation";

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById("root");
    if (root) {
      root.style.height = "100%";
    }
    return () => {
      if (root) {
        root.style.height = "auto";
      }
    };
  }, []);
  const vantaRef = useRef(null);
  return (
    <div style={{ height: "100vh" }} ref={vantaRef}>
      <div
        style={{ opacity: "1 !important" }}
        className="d-flex flex-column flex-lg-row flex-column-fluid h-100"
      >
        {/* begin::Body */}
        <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1 ">
          {/* begin::Form */}
          <div className="d-flex flex-center flex-column flex-lg-row-fluid">
            {/* begin::Wrapper */}
            <div className="w-lg-500px maincard">
              <Outlet />
            </div>
            {/* end::Wrapper */}
          </div>
          {/* end::Form */}
        </div>
        {/* end::Body */}

        {/* begin::Aside */}
        <div
          className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"
          style={{ height: "27vh" }}
        >
          <GlobeAnimation vantaRef={vantaRef} />
          {/* begin::Content */}
          <div className="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">
            {/* begin::Image */}
            {/* <img
              className="mx-auto w-275px w-md-50 w-xl-450px mb-10 mb-lg-20"
              src={toAbsoluteUrl("/media/misc/auth-bg.png")}
              alt=""
              style={{ opacity: '0' }}
            /> */}
            {/* end::Image */}

            {/* begin::Title */}

            {/* end::Text */}
          </div>
          {/* end::Content */}
        </div>
        {/* end::Aside */}
      </div>
    </div>
  );
};

export { AuthLayout };
