import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { searchtest } from "../../../../Redux/Dashboard/Testseries";
import { useAppDispatch } from "../../../../Redux/hooks";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { MdSearch } from "react-icons/md";
import "./Dashboardsearch.css";
import axios from "axios";

type Props = {};

const Dashboardsearch = (props: Props) => {
  const [items, setItems] = useState([]);
  useEffect(() => {
    axios
      .get("/dashboard/getsearchdata")
      .then((res) => {
        setItems(res?.data?.result);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);



  const dispatch = useAppDispatch();
  const [searchpayload, setsearchpayload] = useState<{ query: string }>({
    query: "",
  });
  const handleOnSearch = (string: any, results: any) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    setsearchpayload({ query: string });
  };

  const searchhandler = () => {
    dispatch(searchtest(searchpayload));
  };

  const formatResult = (item: any) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
      </>
    );
  };
  return (
    <div>
      {" "}
      <div className="d-flex searchofdash">
        <div style={{ width: "100vw" }}>
          <ReactSearchAutocomplete
            items={items}
            onSearch={handleOnSearch}
            autoFocus
            styling={{ borderRadius: "4px", zIndex: 2 }}
            formatResult={formatResult}
            placeholder={"search"}
          />
        </div>
        <Button
          variant=""
          style={{
            marginLeft: "4px",
            backgroundColor: "#03045e",
            color: "white",
          }}
          onClick={searchhandler}
        >
          <MdSearch size={18} />
        </Button>{" "}
      </div>
    </div>
  );
};

export default Dashboardsearch;
