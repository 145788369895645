import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { useLayout } from "../../core";
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../Redux/hooks";
import { fetchMyprofile } from "../../../../Redux/Profiledetails/Profiledetails";

const SidebarLogo = () => {
  const { config } = useLayout();
  const [isActive, setIsActive] = useState(false);

  const MainLogoLight = "/media/logos/mtesthub-new-logo.svg";
  const MainLogoDark = "/media/logos/mtesthub-new-logo-dark.svg";

  const SecondaryLogoLight = "/media/logos/mtesthub-new-logo-sec-light.svg";
  const SecondaryLogoDark = "/media/logos/mtesthub-new-logo-sec-dark.svg";

  const applicationMode = localStorage.getItem("application_mode");

  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled;
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled;
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? "collapse"
    : appSidebarDefaultMinimizeDesktopEnabled
      ? "minimize"
      : "";
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? "active" : "";
  const appSidebarDefaultMinimizeDefault =
    config.app?.sidebar?.default?.minimize?.desktop?.default;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    navigate("/dashboard/myprofile");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();
  const dispatch = useAppDispatch();

  const userProfile = useAppSelector((state) => state?.profiledata?.fetchprofiledata);
  useEffect(() => {
    if (localStorage.getItem("userdetails")) {
      dispatch(fetchMyprofile(dispatch));
    }
  }, []);

  const handleSidebarClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsActive(!isActive);
  };


  return (
    //this class for header of logout
    <div className="app-sidebar-logo py-5" id="kt_app_sidebar_logo">
      {isActive ? (
        <Box
          component="img"
          alt="mtestHub Logo"
          src={toAbsoluteUrl(applicationMode === "dark" ? SecondaryLogoDark : SecondaryLogoLight)}
          className="app-sidebar-logo-default"
          sx={{
            ml: 0,
            width: 60,
            height: 60,
            padding: 0,
            transition: 'all 0.5s ease-in-out'
          }}
        />
      ) : (
        <Box
          component="img"
          alt="mtestHub Logo"
          src={toAbsoluteUrl(applicationMode === "dark" ? MainLogoDark : MainLogoLight)}
          className="app-sidebar-logo-default"
          sx={{
            width: 180,
            height: 180,
            paddingX: 1,
            mx: 2,
            transition: 'all 0.5s ease-in-out'
          }}
        />
      )}

      {!(location.pathname.slice(1, 5) == "test" || location.pathname.includes("preview")) &&
        (appSidebarDefaultMinimizeDesktopEnabled ||
          appSidebarDefaultCollapseDesktopEnabled) && (
          <div
            id="kt_app_sidebar_toggle"
            className={clsx(
              "app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate",
              { active: appSidebarDefaultMinimizeDefault }
            )}
            data-kt-toggle="true"
            data-kt-toggle-state={toggleState}
            data-kt-toggle-target="body"
            data-kt-toggle-name={`app-sidebar-${toggleType}`}
            onClick={handleSidebarClick}
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr079.svg"
              className="svg-icon-2 rotate-180"
            />
          </div>
        )}
    </div>
  );
};

export { SidebarLogo };
