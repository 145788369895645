import { useEffect, useState } from "react";
import { FaPlay } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import DashboardModal from "../../../../../app/pages/dashboard/dashboardModal/DashboardModal";
import { VscDebugStart, VscInspect } from "react-icons/vsc";
import { BsCamera, BsFillPlayFill, BsMouse } from "react-icons/bs";
// import Tooltip from "react-bootstrap/Tooltip";
import { FiCopy } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import {
  MdOutlineSchedule,
  MdOutlineScreenShare,
  MdScreenShare,
} from "react-icons/md";
import "./Cards20-2.scss";
import Modalpre from "./preregistrationmodal/Modalpre";
import DOMPurify from "dompurify";
import { DateTime } from "luxon";
import { useAppSelector } from "../../../../../Redux/hooks";
import {
  AccessTime,
  BusinessCenter,
  MonetizationOn,
  Work,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  keyframes,
  Tooltip,
  Typography,
} from "@mui/material";
import { toAbsoluteUrl } from "../../../../helpers";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ScreenShareIcon from "@mui/icons-material/ScreenShare";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { formatDateTime } from "../../../../../utils/formatDates";
import { HeadingsTypography, StyledTypography } from "../../../../../utils/StyledTypographyHeadings";

type Props = {
  className?: string;
  description?: string;
  title?: string;
  color?: string;
  img?: string;
  duration?: string;
  createdat?: string;
  testid?: string;
  scheduled?: string;
  test_settings?: any;
  istestseries?: number;
  result_declare?: any;
  count?: any;
  id?: number;
  fetchdata?: any;
  preregistration_status?: number;
  preregistration_to?: String;
  preregisteration_from?: String;
  fetchdataprewhole?: any;
  organization_logo?: any;
  organization_name?: any;
};

// const renderTooltip = (props: any) => (
//   <Tooltip id="button-tooltip" {...props}>
//     This is a scheduled test
//   </Tooltip>
// );
// const renderTooltip4 = (props: any) => (
//   <Tooltip id="button-tooltip" {...props}>
//     Camera needed
//   </Tooltip>
// );
// const renderTooltip5 = (props: any) => (
//   <Tooltip id="button-tooltip" {...props}>
//     Screen sharing needed
//   </Tooltip>
// );

const blinkingAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

export function CardsWidget20({
  className,
  description,
  title,
  color,
  img,
  duration,
  createdat,
  testid,
  scheduled,
  test_settings,
  istestseries,
  result_declare,
  id,
  count,
  fetchdata,
  preregistration_status,
  preregistration_to,
  preregisteration_from,
  fetchdataprewhole,
  organization_logo,
  organization_name,
}: Props) {
  const systemTimeZone =
    useAppSelector((state) => state.testseries.alldatabaseconfig).data[0]
      ?.config_value || process.env.REACT_APP_TIMEZONE;

  const [show, setShow] = useState(false);
  const [countvalue, setcountvalue] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  useEffect(() => {
    let abc: any = [];

    fetchdata.forEach((each: any) => {
      if (each?.id === id) {
        abc.push(each?.id);
      }
    });
    setcountvalue(abc?.length);
  }, [count, fetchdata, title, id]);

  const [dateandtime, setdateandtime] = useState<any>("");

  useEffect(() => {
    if (scheduled && systemTimeZone) {
      // Convert the datetime string to ISO 8601 format (adding "T" and timezone offset)
      const isoFormattedDateTime = scheduled.replace(" ", "T") + "+00:00";

      const scheduleDateTime = DateTime.fromISO(isoFormattedDateTime, {
        zone: systemTimeZone.toLowerCase(),
      });

      if (scheduleDateTime.isValid) {
        const localDateTime = scheduleDateTime.toLocal();
        const localDateTimeString = localDateTime.toFormat(
          "yyyy-MM-dd HH:mm:ss"
        );

        console.log("🚀 ~ useEffect ~ localDateTime:", localDateTimeString);
        setdateandtime(localDateTimeString);
      } else {
        console.log("Invalid scheduled datetime format:", scheduled);
      }
    }
  }, [scheduled, systemTimeZone]);

  const [fromtime, setfromtime] = useState<any>(false);
  const [totime, settotime] = useState<any>(false);

  const [preregistrationdatetimestatus, setpreregistrationstatus] =
    useState<boolean>(false);
  const [preregistrationdatetimestatus2, setpreregistrationstatus2] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      preregistration_status === 1 &&
      preregisteration_from &&
      preregistration_to &&
      systemTimeZone
    ) {
      const isoFormattedDateTimeTo =
        preregistration_to.replace(" ", "T") + "+00:00";
      const isoFormattedDateTimeFrom =
        preregisteration_from.replace(" ", "T") + "+00:00";

      const preTo = DateTime.fromISO(isoFormattedDateTimeTo, {
        zone: systemTimeZone.toLowerCase(),
      });
      const preFrom = DateTime.fromISO(isoFormattedDateTimeFrom, {
        zone: systemTimeZone.toLowerCase(),
      });

      if (preTo.isValid && preFrom.isValid) {
        const currentLocalTime = DateTime.local(); // Current local time of the user's PC
        const localDateTime = preTo.toLocal();
        const localDateTime2 = preFrom.toLocal();
        const localDateTimeString = localDateTime.toFormat(
          "yyyy-MM-dd HH:mm:ss"
        );
        const localDateTimeString2 = localDateTime2.toFormat(
          "yyyy-MM-dd HH:mm:ss"
        );

        settotime(localDateTimeString);
        setfromtime(localDateTimeString2);

        if (
          currentLocalTime >= localDateTime2 &&
          currentLocalTime <= localDateTime
        ) {
          setpreregistrationstatus(true);
        } else {
          setpreregistrationstatus2(true);
        }
      } else {
        setpreregistrationstatus(false);
        setpreregistrationstatus2(false);
      }
    } else {
      console.log(
        "Invalid preregsitration datetime format:",
        preregisteration_from,
        preregistration_to,
        systemTimeZone
      );
    }
  }, [
    preregistration_status,
    preregisteration_from,
    preregistration_to,
    systemTimeZone,
  ]);

  const [existinpre, setexistinpre] = useState(false);
  useEffect(() => {
    fetchdataprewhole.map((each: any) => {
      if (each?.assesment_id == testid) {
        setexistinpre(true);
      }
    });
  }, [fetchdataprewhole, testid]);

  const truncatedDescription =
    description && description.length > 80
      ? description.slice(0, 80) + "..."
      : description;
  const sanitizedDescription = DOMPurify.sanitize(truncatedDescription || "");

  const applicationMode = localStorage.getItem("application_mode");

  return (
    // <>
    //   <div
    //     className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end abdxhnjksad ${className} maincardforhover`}
    //     style={{
    //       backgroundColor: "#03045e",
    //       backgroundImage: `url('${img}')`,
    //       height: "426px",
    //     }}
    //   >
    //     {/* header */}
    //     <div className="card-header pt-5" style={{ padding: '50px 30px ' }}>
    //       {/* title */}
    //       <span>
    //         <div>

    //           <Badge bg="primary" style={{ marginBottom: "6px" }}>
    //             {istestseries == 1 ? "Test Series" : ""}
    //           </Badge>
    //         </div>
    //         <div>

    //           <Badge bg="danger" style={{ marginBottom: "6px" }}>
    //             {preregistrationdatetimestatus ? "Pre-registration open" : ""}
    //           </Badge>
    //         </div>
    //         <div>

    //           <Badge style={{ marginBottom: "6px" }}>
    //             {preregistrationdatetimestatus2
    //               ? "Pre-registered candidates only"
    //               : ""}
    //           </Badge>
    //         </div>
    //       </span>
    //       <span
    //         className="d-flex justify-content-between fw-bold fs-6 text-white opacity-75 "
    //         style={{ marginTop: "-18px !important" }}
    //       >
    //         {(JSON.parse(test_settings).randomphotos || JSON.parse(test_settings).live_proctoring_camera) &&
    //           (JSON.parse(test_settings).randomphotos == "true" || JSON.parse(test_settings).live_proctoring_camera == "true") && (
    //             <OverlayTrigger
    //               placement="top"
    //               delay={{ show: 250, hide: 400 }}
    //               overlay={renderTooltip4}
    //             >
    //               <span className="permissionsicons">
    //                 <BsCamera style={{ cursor: "pointer" }} size={20} />
    //               </span>
    //             </OverlayTrigger>
    //           )}
    //         {(JSON.parse(test_settings).allowscreenrecording || JSON.parse(test_settings).live_proctoring_screen) &&
    //           (JSON.parse(test_settings).allowscreenrecording == "true" || JSON.parse(test_settings).live_proctoring_screen == "true") && (
    //             <OverlayTrigger
    //               placement="top"
    //               delay={{ show: 250, hide: 400 }}
    //               overlay={renderTooltip5}
    //             >
    //               <span className="permissionsicons">
    //                 <MdOutlineScreenShare style={{ cursor: "pointer" }} size={20} />
    //               </span>
    //             </OverlayTrigger>
    //           )}

    //         {scheduled && (
    //           <OverlayTrigger
    //             placement="top"
    //             delay={{ show: 250, hide: 400 }}
    //             overlay={renderTooltip}
    //           >
    //             <span>
    //               <MdOutlineSchedule style={{ cursor: "pointer" }} size={20} />
    //             </span>
    //           </OverlayTrigger>
    //         )}

    //         /* <span>{creator name here}</span> */}{
    //       </span>
    //       <div className="card-title d-flex flex-column" style={{ width: "100%" }}>
    //         <span className="fs-2hx fw-bold text-white me-2 lh-1 ls-n2 card-title" onClick={() => {
    //           window.location.href = `/testoverview/${testid}`;
    //         }}>
    //           {title}
    //         </span>
    //         {description?.length > 86 ? (
    //           <span
    //             dangerouslySetInnerHTML={{
    //               __html: DOMPurify.sanitize(description.slice(0, 87) + "..."),
    //             }}
    //             className="text-white opacity-75 pt-1 fw-semibold fs-6">
    //             {/* {description.slice(0, 87) + "..."} */}
    //           </span>
    //         ) : (
    //           <span
    //             dangerouslySetInnerHTML={{
    //               __html: DOMPurify.sanitize(description),
    //             }}

    //             className="text-white opacity-75 pt-1 fw-semibold fs-6">
    //             {/* {description} */}
    //           </span>
    //         )}
    //       </div>

    //       {/* description */}
    //       <div
    //         className="d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100  mb-4"
    //         style={{ marginTop: "-18px !important", height: "20px" }}
    //       >
    //         {description?.length > 89 && (
    //           <p
    //             style={{
    //               color: "white",
    //               cursor: "pointer",
    //               marginTop: "-2px",
    //               textDecoration: "underline",
    //             }}
    //             onClick={handleShow}
    //           >
    //             {" "}
    //             Read more
    //           </p>
    //         )}
    //       </div>
    //     </div>

    //     {/* body */}
    //     <div className={`card-body d-flex  align-items-end pt-0 lowerendcard`} >
    //       {/* multiple div */}
    //       <div className="d-flex align-items-center flex-column mt-3 w-100">
    //         <div
    //           className="d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100  mb-4"
    //           style={{ marginTop: "-18px !important" }}
    //         >
    //           <span className="duration">Duration : {Math.round(parseFloat(duration) * 60)} Minutes</span>
    //           {/* <span>{creator name here}</span> */}
    //         </div>
    //         {/* div 2 */}
    //         <div
    //           className="d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100  mb-4"
    //           style={{ marginTop: "-18px !important" }}
    //         >
    //           {dateandtime && <span>Starts at : {dateandtime}</span>}
    //           {/* <span>{creator name here}</span> */}
    //         </div>
    //         {/* div 3 */}
    //         {/* div 1 */}
    //         <div
    //           className="d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100"
    //           style={{ marginTop: "10px !important" }}
    //         >
    //           {preregistrationdatetimestatus && (
    //             <Alert
    //               style={{ padding: "4px 16px", width: "100%" }}
    //               variant={"primary"}
    //             >
    //               <div>
    //                 <div>
    //                   <div>Registration open</div>
    //                   <div className="datentime">{fromtime && fromtime}</div>{" "}
    //                   <div className="datenjoinee">to</div>{" "}
    //                   <div className="datentime">{totime && totime}</div>{" "}
    //                 </div>{" "}
    //               </div>
    //             </Alert>
    //           )}
    //         </div>
    //         {result_declare == 1 && (
    //           <div
    //             className="d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100  mb-4"
    //             style={{ marginTop: "-18px !important" }}
    //           >
    //             {result_declare == 1 &&
    //               countvalue &&
    //               !preregistrationdatetimestatus ? (
    //               <Alert variant={"primary"}>
    //                 <span>
    //                   You have attempted this test series {countvalue} time{" "}
    //                 </span>
    //                 <Link
    //                   style={{ textDecoration: "underline" }}
    //                   to={"/dashboard/attemptedseries"}
    //                 >
    //                   View Results
    //                 </Link>
    //               </Alert>
    //             ) : (
    //               ""
    //             )}
    //           </div>
    //         )}

    //         {/* div 4 */}

    //         {/* end buttton div */}
    //         <div className="h-40px mx-3 mb-4 w-100 wrap ">
    //           {!preregistrationdatetimestatus && preregistration_status ? (
    //             existinpre ? (
    //               <div style={{ width: "72%", marginBottom: "10px" }}>
    //                 <div
    //                   className="playbutton"
    //                   onClick={() => {
    //                     window.location.href = `/testoverview/${testid}`;
    //                   }}
    //                 >
    //                   <p className="paragraphstart">
    //                     Start test
    //                     <BsFillPlayFill
    //                       size={20}
    //                       style={{ marginBottom: "0px" }}
    //                     />
    //                   </p>
    //                 </div>
    //               </div>
    //             ) : (
    //               <p style={{ color: "red" }}>
    //                 You have not preregistered for this test
    //               </p>
    //             )
    //           ) : (
    //             ""
    //           )}

    //           {!preregistration_status && (
    //             <div style={{ width: "72%", marginBottom: "10px" }}>
    //               <div
    //                 className="playbutton"
    //                 onClick={() => {
    //                   window.location.href = `/testoverview/${testid}`;
    //                 }}
    //               >
    //                 <p className="paragraphstart">
    //                   Start test
    //                   <BsFillPlayFill
    //                     size={20}
    //                     style={{ marginBottom: "0px" }}
    //                   />
    //                 </p>
    //               </div>
    //             </div>
    //           )}
    //           {preregistrationdatetimestatus && !existinpre && (
    //             <Link
    //               to={`/dashboard/preregister/${id}`}
    //               style={{ marginBottom: "10px", textDecoration: "underline" }}
    //             >
    //               <Button variant="primary" style={{ marginBottom: "10px" }}>
    //                 Register now
    //               </Button>
    //             </Link>
    //           )}
    //           {preregistrationdatetimestatus && existinpre && (
    //             <p style={{ color: "white" }}>
    //               You Have preregistered for this test please wait until the
    //               test starts{" "}
    //               <span
    //                 style={{
    //                   textDecoration: "underline",
    //                   cursor: "pointer",
    //                   color: "#009cf5",
    //                 }}
    //                 onClick={handleShow2}
    //               >
    //                 Update submitted form
    //               </span>
    //             </p>
    //           )}
    //         </div>
    //         {show2 && (
    //           <Modalpre
    //             show={show2}
    //             handleClose={handleClose2}
    //             title={title}
    //             id={id}
    //           />
    //         )}
    //         <DashboardModal
    //           show={show}
    //           title={title}
    //           description={description}
    //           handleClose={handleClose}
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </>

    <Card
      className="assigned-test-card"
      sx={{
        maxWidth: {xs: '100%', sm: 350, md: 300, xl: 400},
        borderRadius: "10px",
        boxShadow: "10px 10px 15px 0px rgba(0,0,0,0.75) !important",
        padding: 0,
        border: applicationMode === "light" ? "1px solid #000000" : "1px solid #ffffff",
        my: 1,
      }}
    >
      <CardContent sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <Box
            component="img"
            alt="company_logo"
            sx={{
              height: 50,
              objectFit: "cover",
            }}
            src={
              organization_logo
                ? organization_logo
                : toAbsoluteUrl("/media/avatars/placeholder_img.png")
            }
          />

          <Tooltip title="view test details">
            {preregistrationdatetimestatus || preregistrationdatetimestatus2 ? (
              <Badge
                color="error"
                variant="dot"
                sx={{
                  "& .MuiBadge-dot": {
                    animation: `${blinkingAnimation} 1s infinite`,
                  },
                }}
              >
                <VisibilityIcon
                  className="assigned-test-card-visibility-icon"
                  sx={{ cursor: "pointer", color: "red !important" }}
                  onClick={handleShow}
                />
              </Badge>
            ) : (
              <VisibilityIcon className="assigned-test-card-visibility-icon" sx={{ cursor: "pointer" }} onClick={handleShow} />
            )}
          </Tooltip>
        </Box>

        <Box sx={{ my: 2, width: "100%" }}>
          {title && title?.length > 32 ? (
            <Tooltip title={title}>
              <HeadingsTypography
                variant="h5"
                className="assigned-test-card-title"
                sx={{ cursor: "pointer", width: "100%" }}
              >
                {title.slice(0, 32)}...
              </HeadingsTypography>
            </Tooltip>
          ) : (
            <HeadingsTypography
              variant="h5"
              className="assigned-test-card-title"
              sx={{ cursor: "pointer", width: "100%" }}
            >
              {title}
            </HeadingsTypography>
          )}

          <StyledTypography
            variant="body1"
            className="assigned-test-card-duration"
            sx={{ color: "#62676b", my: 1, fontWeight: 600 }}
          >
            Duration: {Math?.round(parseFloat(duration ?? "") * 60)} minutes
          </StyledTypography>

          {dateandtime && (
            <StyledTypography
              variant="body1"
              className="assigned-test-card-duration"
              sx={{ color: "#62676b", my: 1, fontWeight: 600 }}
            >
              Starts at: {formatDateTime(dateandtime ?? "")}
            </StyledTypography>
          )}

          {/* <Typography
            variant="body2"
            sx={{ color: "#919EAB", my: 1 }}
            dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
          /> */}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 1,
            width: "100%",
          }}
        >
          {/* {istestseries == 1 && (
            <Typography
              variant="body2"
              sx={{
                backgroundImage:
                  "linear-gradient(45deg, rgba(3, 5, 94, 0.6), rgba(3,4,94,1))",
                color: "#fff",
                padding: "3px",
                borderRadius: 1,
              }}
            >
              Test Series
            </Typography>
          )} */}

          {preregistrationdatetimestatus && (
            <StyledTypography
              variant="body2"
              sx={{
                backgroundImage:
                  "linear-gradient(45deg, rgba(209,0,66, 0.6), rgba(209,0,66,1))",
                color: "#fff",
                padding: "3px",
                borderRadius: 1,
              }}
            >
              Pre-registration open
            </StyledTypography>
          )}

          {preregistrationdatetimestatus2 && (
            <StyledTypography
              variant="body2"
              sx={{
                backgroundImage:
                  "linear-gradient(45deg, rgba(183,3,3, 0.6), rgba(183,3,3,1))",
                color: "#fff",
                padding: "3px",
                borderRadius: 1,
              }}
            >
              Pre-registered candidates only
            </StyledTypography>
          )}
        </Box>
      </CardContent>

      <Box sx={{ border: "1px dashed rgba(145 158 171 / 0.2)" }} />

      <CardContent sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 4,
            paddingX: 1,
          }}
        >
          {(JSON.parse(test_settings)?.randomphotos ||
            JSON.parse(test_settings)?.live_proctoring_camera) &&
            (JSON.parse(test_settings)?.randomphotos == "true" ||
              JSON.parse(test_settings)?.live_proctoring_camera == "true") && (
              <Tooltip title="Camera is required for this test">
                <CameraAltIcon
                  className="assigned-test-card-icon"
                  sx={{ cursor: "pointer" }}
                />
              </Tooltip>
            )}

          {(JSON.parse(test_settings)?.allowscreenrecording ||
            JSON.parse(test_settings)?.live_proctoring_screen) &&
            (JSON.parse(test_settings)?.allowscreenrecording == "true" ||
              JSON.parse(test_settings)?.live_proctoring_screen == "true") && (
              <Tooltip title="Screen recording is required for this test">
                <ScreenShareIcon
                  className="assigned-test-card-icon"
                  sx={{ cursor: "pointer" }}
                />
              </Tooltip>
            )}

          {scheduled && (
            <Tooltip title="This test is scheduled">
              <AccessTimeIcon
                className="assigned-test-card-icon"
                sx={{ cursor: "pointer"}}
              />
            </Tooltip>
          )}
        </Box>
      </CardContent>
      <CardActions>
        {!preregistrationdatetimestatus && preregistration_status ? (
          existinpre ? (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                window.location.href = `/testoverview/${testid}`;
              }}
              className="glass-button"
            >
              Start Test
              <ArrowRightIcon
                fontSize="large"
                sx={{ color: "#ffff !important" }}
              />
            </Button>
          ) : (
            <p style={{ color: "red" }}>
              You have not preregistered for this test
            </p>
          )
        ) : (
          ""
        )}

        {!preregistration_status && (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              window.location.href = `/testoverview/${testid}`;
            }}
            className="glass-button"
          >
            <StyledTypography>
              Start Test
            </StyledTypography>
            <ArrowRightIcon
              fontSize="large"
              sx={{ color: "#ffff !important" }}
            />
          </Button>
        )}

        {preregistrationdatetimestatus && !existinpre && (
          <Link
            to={`/dashboard/preregister/${id}`}
            style={{ textDecoration: "underline", width: "100%" }}
          >
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                backgroundImage:
                  "linear-gradient(45deg, rgba(3, 5, 94, 0.6), rgb(3, 4, 71))",
                color: "#fff",
                borderRadius: 1,
                "&:hover": {
                  backgroundImage:
                    "linear-gradient(45deg, rgba(3, 4, 71), rgba(3, 5, 94, 0.6))",
                  color: "#fff",
                  opacity: 0.7,
                  display: "flex",
                  alignItems: "center",
                },
              }}
            >
              Register Now
              <ArrowRightIcon
                fontSize="large"
                sx={{ color: "#ffff !important" }}
              />
            </Button>
          </Link>
        )}

        {preregistrationdatetimestatus && existinpre && (
          <p style={{ color: "white" }}>
            You Have preregistered for this test please wait until the test
            starts{" "}
            <span
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                color: "#009cf5",
              }}
              onClick={handleShow2}
            >
              Update submitted form
            </span>
          </p>
        )}
      </CardActions>
      {show2 && (
        <Modalpre
          show={show2}
          handleClose={handleClose2}
          title={title}
          id={id}
        />
      )}
      <DashboardModal
        show={show}
        title={title ?? ""}
        description={description ?? ""}
        handleClose={handleClose}
        preregistrationdatetimestatus={preregistrationdatetimestatus ?? false}
        fromtime={fromtime ?? ""}
        totime={totime ?? ""}
      />
    </Card>
  );
}
