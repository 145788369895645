import React, { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Camera from "react-html5-camera-photo";
import WebcamCapture from "./MainCamera";
type proptypes = {
  handleClose: () => void;
  show: boolean;
  responsedata: object;
  setresponsedata: object;
  rendered: boolean;
};

export function CameraModal(props: proptypes) {
  const { handleClose, show, responsedata, setresponsedata } = props;
  const [available, setavailable] = useState<string>("");
  React.useEffect(() => {
    if (!navigator.mediaDevices?.enumerateDevices) {
      setavailable("not-available");
    } else {
      // List cameras and microphones.
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          devices.forEach((device) => {
            if (device.deviceId != "" || device.kind == "videoinput") {
              setavailable("");
            } else {
              setavailable("not-available");
            }
          });
        })
        .catch((err) => {
          console.error(`${err.name}: ${err.message}`);
          setavailable("not-available");
        });
    }
  });


  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          handleClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Camera</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {available == 'not-available' && <p style={{ color: 'red' }}>Either you dont have webcam or else your browser is outdated please update it</p>} */}
          {props.rendered === true && (
            <WebcamCapture
              responsedata={responsedata}
              setresponsedata={setresponsedata}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
