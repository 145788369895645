import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./Fullscreenmodal.css";

const ScreenWarn = ({
  setIsModalOpenResize,
  isModalOpenResize,
  allowScreenShareFunc,
  screenShareLoading,
}) => {
  return (
    <Modal
      show={isModalOpenResize}
      onHide={null}
      backdrop="static"
      disableEscapeKeyDown
      disableBackdropClick
    >
      <Modal.Body style={{ border: "1px solid red" }}>
        <div>
          <h2>Warning: Looks like screen recording is paused</h2>
          <p>
            Please make sure screen recording is working and not being stopped
            by any reason while in assessment window
          </p>
          {/* <p>Note:if this behaviour is recorded repeatedly it will be considered cheating in assessment</p> */}
          {/* <p>
            Restart the test with proper permissions to continue or{" "}
            <span
              style={{
                color: "rgb(87, 174, 255)",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={redirectFn}
            >
              click here
            </span>
            , (Your data is synced so there will be no data loss).
          </p> */}
          {
            screenShareLoading ? (<p>loading......</p>) : <p>
              Please Click here to allow screen sharing{" "}
              <span
                style={{
                  color: "rgb(87, 174, 255)",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={allowScreenShareFunc}
              >
                click here
              </span>
              , (Your data is synced so there will be no data loss).
            </p>
          }
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ScreenWarn;
