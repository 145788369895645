import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  camerapermission: true,
  camerapermission2: "",
  locationpermission: true,
};

export const Permissions = createSlice({
  name: "Loader",
  initialState,
  reducers: {
    handlecamerapermission: (state, action) => {
      state.camerapermission = action.payload;
    },
    handlecamerapermission2: (state, action) => {
      state.camerapermission2 = action.payload;
    },
    handlelocationpermission: (state, action) => {
      state.locationpermission = action.payload;
    },
  },
});

export const {
  handlecamerapermission,
  handlelocationpermission,
  handlecamerapermission2,
} = Permissions.actions;
export default Permissions.reducer;
