import { configureStore } from "@reduxjs/toolkit";
import testReducer from "./TestPage/TestSlice";
import authreducer from "./AuthPage/AuthSlice";
import snackbarwithmessagesreducer from "./Snackbarwithmessages/Snackbarwithmessages";
import Loaderreducer from "./Loader/Loader";
import Permissionreducer from "./Checkpermissions/Permission";
import Testseries from "./Dashboard/Testseries";
import CameraPreview from "./CameraPreviewTrigger/CameraPreviewTrigger";
import profiledata from "./Profiledetails/Profiledetails";
import Resultpage from "./ResultPage/Resultpage";
import Notificationredux from "./Notifications/Notificationslice";
import ScreenRecord from "./ScreenRecord/ScreenRecord";

export const store = configureStore({
  reducer: {
    tests: testReducer,
    testseries: Testseries,
    auth: authreducer,
    snackbar: snackbarwithmessagesreducer,
    loader: Loaderreducer,
    permission: Permissionreducer,
    camerapreview: CameraPreview,
    profiledata: profiledata,
    resultpage: Resultpage,
    notificationredux: Notificationredux,
    screenrecord: ScreenRecord,
  },
  devTools: process.env.NODE_ENV !== 'production'
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
