import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { useParams } from "react-router-dom";
import { handlecloseloader, handleopenloader } from "../Loader/Loader";
import { handleopensnackbar } from "../Snackbarwithmessages/Snackbarwithmessages";
import type { RootState } from "../store";

// Define a type for the slice state
interface notificationstate {
    fetchedunreadnotificationdata: { data: [] };
    fetchedallnotificationdata: { data: [] };
}

// Define the initial state using that type
const initialState: notificationstate = {
    fetchedunreadnotificationdata: { data: [] },
    fetchedallnotificationdata: { data: [] },
};

export const fetchunreadnotification = createAsyncThunk(
    "notificationredux/fetchunreadnotification",
    (payload: any, { dispatch }) => {

        return axios
            .get(`/notification/getunreadnotifications`)
            .then(function (response) {

                return response?.data;
            })
            .catch(function (e) {



            });
    }
);


export const fetchallnotifications = createAsyncThunk(
    "notificationredux/fetchallnotifications",
    (payload: any, { dispatch }) => {

        return axios
            .get(`/notification/getallnotifications`)
            .then(function (response) {

                return response?.data;
            })
            .catch(function (e) {



            });
    }
);

export const markasreadnotificationall = createAsyncThunk(
    "notificationredux/markasreadnotificationall",
    (payload: any, { dispatch }) => {

        return axios
            .get(`/notification/readallnotifications`)
            .then(function (response) {

                return response?.data;
            })
            .catch(function (e) {



            });
    }
);
export const markasreadnotificationaone = createAsyncThunk(
    "notificationredux/markasreadnotificationall",
    (payload: any) => {

        return axios
            .post(`/notification/markasread`, payload)
            .then(function (response) {

                return response?.data;
            })
            .catch(function (e) {



            });
    }
);

export const Notificationslice = createSlice({
    name: "notificationredux",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            fetchunreadnotification.fulfilled,
            (state, action: PayloadAction<any>) => {
                state.fetchedunreadnotificationdata = action.payload;
            }
        );
        builder.addCase(
            fetchallnotifications.fulfilled,
            (state, action: PayloadAction<any>) => {
                state.fetchedallnotificationdata = action.payload;
            }
        );
        builder.addCase(
            markasreadnotificationall.fulfilled,
            (state, action: PayloadAction<any>) => {

            }
        );
    },
});

export default Notificationslice.reducer;
