/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { getUserByToken, register } from "../core/_requests";
import { Link, Params, useNavigate, useParams } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { PasswordMeterComponent } from "../../../../_metronic/assets/ts/components";
import { useAuth } from "../core/Auth";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../Redux/hooks";
import { signupfortest } from "../../../../Redux/AuthPage/AuthSlice";
import { PhotoCamera } from "@mui/icons-material";
import Camera from "react-html5-camera-photo";
import { CameraModal } from "./CameraModal";
import { Card } from "react-bootstrap";
import axios from "axios";
import { handleopensnackbar } from "../../../../Redux/Snackbarwithmessages/Snackbarwithmessages";
import {
  handlecloseloader,
  handleopenloader,
} from "../../../../Redux/Loader/Loader";

interface initialValues {
  name: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  pincode: string;
  state: string;
  country: string;
  password: string;
}

export function PreRegistration() {
  const [loading, setLoading] = useState(false);
  const [isdisabled, setisdisabled] = useState<boolean>(true);
  const [registerdata, setregisterdata] = useState<any>({});
  const [responsedata, setresponsedata] = useState<any>({});
  const [responsedata2, setresponsedata2] = useState<any>([]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [rendered, setrendered] = useState(false);
  const [wholedata, setwholedata] = useState<any>([]);
  const handleClose = () => {
    setShow(false);
    setrendered(false);
  };
  const handleShow = () => {
    setShow(true);
    setrendered(true);
  };
  const camerauploadhandler = () => {
    handleShow();
  };

  const onchangeHandler = (e: any, data?: any) => {
    if (data) {
      if (e.target.checked == true) {
        setregisterdata({
          ...registerdata,
          [e.target.name]: {
            ...registerdata[e.target.name],
            [e.target.value]: 1,
          },
        });
      } else if (e.target.checked == false) {
        setregisterdata({
          ...registerdata,
          [e.target.name]: {
            ...registerdata[e.target.name],
            [e.target.value]: 0,
          },
        });
      }
    } else {
      setregisterdata({ ...registerdata, [e.target.name]: e.target.value });
    }
  };
  const { id }: Params<string> = useParams();
  const onSubmithandler = () => {
    dispatch(handleopenloader());
    const payload = {
      test_series_id: `${id}`,
      candidate_data: registerdata,
    };
    axios
      .post("/preregister/registeruser", payload)
      .then((res) => {
        dispatch(handlecloseloader());
        if (res?.data?.success === true) {
          const payloaddata = {
            messages: "successfully preregistered for this test",
            severity: "success",
          };

          dispatch(handleopensnackbar(payloaddata));
          window.location.href = "/home/dashboard";
        } else {
          const payloaddata = {
            messages: res?.data?.msg,
            severity: "error",
          };

          dispatch(handleopensnackbar(payloaddata));
        }
      })
      .catch((err) => {
        dispatch(handlecloseloader());
        console.error(err);
      });
  };

  useEffect(() => {
    if (Object.keys(registerdata).length >= responsedata2.length) {
      setisdisabled(false);
    } else {
      setisdisabled(true);
    }
  }, [responsedata2, registerdata]);



  useEffect(() => {
    dispatch(handleopenloader());

    axios
      .get(`/preregister/fetchformfortest/${id}`)
      .then((res) => {
        dispatch(handlecloseloader());
        if (res?.data?.success === true) {
          setwholedata(res?.data?.result);
          setresponsedata2(res?.data?.formfortest);
        } else {
          navigate(-1);
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch(handlecloseloader());
      });
  }, []);



  return (
    <Card>
      <Card.Body>
        <form
          className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
          noValidate
          id="kt_login_signup_form"
        >
          {/* begin::Heading */}
          <div className="text-center mb-11">
            {/* begin::Title */}
            <h1 className="text-dark fw-bolder mb-3">
              Registration for {wholedata[0]?.test_name} Test
            </h1>
            {/* end::Title */}
          </div>
          {responsedata2?.map((each: any, index: any) => {
            return (
              <div key={index} className="fv-row mb-8">
                <label className="form-label fw-bolder text-dark fs-6">
                  {each?.titlevalue}
                  {/* <span style={{ color: 'red' }}>*</span> */}
                </label>
                {!each?.options &&
                  !each?.cameraisrequired &&
                  each?.typevalue != "textarea" && (
                    <input
                      placeholder={each?.titlevalue}
                      type={each?.typevalue}
                      value={registerdata[each?.namevalue]}
                      onChange={onchangeHandler}
                      name={each?.namevalue}
                      autoComplete="off"
                      className={clsx("form-control bg-transparent")}
                    />
                  )}
                {!each?.options &&
                  !each?.cameraisrequired &&
                  each?.typevalue == "textarea" && (
                    <textarea
                      placeholder={each?.titlevalue}
                      value={registerdata[each?.namevalue]}
                      onChange={onchangeHandler}
                      name={each?.namevalue}
                      autoComplete="off"
                      className={clsx("form-control bg-transparent")}
                    />
                  )}
                {each?.typevalue == "radio" &&
                  each?.options?.map((eachdata: string, index: any) => {
                    return (
                      <>
                        <div key={index} style={{ display: "flex" }}>
                          <input
                            type={each?.typevalue}
                            style={{
                              display: "inline-block",
                              marginRight: "10px",
                            }}
                            id={`${eachdata}`}
                            onChange={(e) => {
                              onchangeHandler(e);
                            }}
                            name={each?.namevalue}
                            value={eachdata}
                          />
                          <label htmlFor={eachdata}>{eachdata}</label>
                        </div>
                      </>
                    );
                  })}
                {each?.typevalue == "checkbox" &&
                  each?.options?.map((eachdata: string, index: any) => {
                    return (
                      <>
                        <div key={index} style={{ display: "flex" }}>
                          <input
                            type={each?.typevalue}
                            style={{
                              display: "inline-block",
                              marginRight: "10px",
                            }}
                            id={`${eachdata}`}
                            onChange={(e) => {
                              onchangeHandler(e, "checkbox");
                            }}
                            name={each?.namevalue}
                            value={eachdata}
                          />
                          <label htmlFor={eachdata}>{eachdata}</label>
                        </div>
                      </>
                    );
                  })}

                {each?.cameraisrequired && (
                  <div className="fv-row mb-5">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                      onClick={camerauploadhandler}
                    >
                      <PhotoCamera />
                      <span style={{ marginLeft: "4px" }}>
                        {" "}
                        Click here to capture a photo
                      </span>
                    </IconButton>
                  </div>
                )}
              </div>
            );
          })}
          {/* begin::Form group fullname */}

          {/* end::Form group */}

          {/* end::Form group */}

          {/* end::Form group */}

          {/* begin::Form group */}

          {/* end::Form group */}

          {/* begin::Form group */}
          <div className="text-center">
            <LoadingButton
              disabled={isdisabled}
              fullWidth
              sx={{ mb: 1 }}
              type="button"
              loading={loading}
              onClick={() => {
                onSubmithandler();
              }}
              id="kt_sign_in_submit"
              className="btn btn-primary"
            >
              <span className="indicator-label">Submit</span>
            </LoadingButton>
            <Button
              onClick={() => {
                window.location.href = "/home/dashboard";
              }}
              type="button"
              id="kt_login_signup_form_cancel_button"
              className="btn btn-lg btn-light-primary w-100 mb-5"
            >
              Go back
            </Button>
          </div>
          <CameraModal
            handleClose={handleClose}
            rendered={rendered}
            show={show}
            responsedata={registerdata}
            setresponsedata={setregisterdata}
          />
          {/* end::Form group */}
        </form>
      </Card.Body>
    </Card>
  );
}
