import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import { useRecordWebcam } from "react-record-webcam";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { CircularProgress, Typography } from "@mui/material";
import { StyledTypography } from "../../../../../utils/StyledTypographyHeadings";

const RecordingComponent = ({ saveFile, isVideoUploading, value }) => {
  const recordWebcam = useRecordWebcam({
    frameRate: 60,
    width: "fit-content",
    height: "fit-content",
  });
  const [isRecording, setIsRecording] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(
    process.env.REACT_APP_VIDEO_RECORD_LIMIT
  );

  useEffect(() => {
    let timer;

    if (isRecording) {
      timer = setInterval(() => {
        setSecondsLeft((prev) => {
          if (prev === 0) {
            stopRecording();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } else {
      clearInterval(timer);
      setSecondsLeft(process.env.REACT_APP_VIDEO_RECORD_LIMIT);
    }

    return () => clearInterval(timer);
  }, [isRecording]);

  useEffect(() => {
    if (recordWebcam.status === "OPEN" && isRecording) {
      recordWebcam.start(); // Start recording after the camera is opened
    }
  }, [recordWebcam.status, isRecording, recordWebcam]);

  const startRecording = async () => {
    try {
      await recordWebcam.open();
      setIsRecording(true);
    } catch (error) {
      console.error("Error opening camera:", error);
    }
  };

  const stopRecording = () => {
    recordWebcam.stop();
    setIsRecording(false);
    setSecondsLeft(process.env.REACT_APP_VIDEO_RECORD_LIMIT); // Reset the timer when recording stops
  };

  const handleSaveRecording = async () => {
    const blob = await recordWebcam.getRecording();
    saveFile(blob); // Call the saveFile function passed as a prop
  };

  return (
    <div>
      <p>Camera status: {recordWebcam.status}</p>
      {recordWebcam.status === "ERROR" ? (
        <p style={{ color: "red" }}>
          Please allow camera and microphone access for recording from the
          browser site settings
        </p>
      ) : null}

      {/* Display the live webcam preview */}
      <div className="mb-4">
        {recordWebcam.status === "INIT" ? <CircularProgress /> : null}
        <video
          ref={recordWebcam.webcamRef}
          autoPlay
          muted
          controlsList="nodownload"
          style={
            isRecording && recordWebcam.status !== "ERROR"
              ? { display: "block", width: "24vw" }
              : { display: "none" }
          }
        />
        <video
          ref={recordWebcam.previewRef}
          style={
            recordWebcam.status === "PREVIEW"
              ? { display: "block", width: "24vw" }
              : { display: "none" }
          }
          muted
          controls
          controlsList="nodownload"
        />
        {isRecording && (
          <div
            style={{
              width: "24vw",
              display: " flex",
              justifyContent: "flex-end",
            }}
          >
            <StyledTypography variant="h5" sx={{ fontWeight: 800 }}>{`00:${String(
              secondsLeft
            ).padStart(2, "0")}`}</StyledTypography>
          </div>
        )}
      </div>

      <Button
        sx={{
          backgroundColor: "#1f219c",
          color: "#fff",
          "&:hover": { backgroundColor: "#1f219c", opacity: 0.7 },
        }}
        onClick={
          isRecording && recordWebcam.status !== "ERROR"
            ? stopRecording
            : startRecording
        }
      >
        <RadioButtonCheckedIcon style={{ marginLeft: "4px" }} />
        <StyledTypography variant="body1">
          {isRecording && recordWebcam.status !== "ERROR"
            ? "Stop Recording"
            : recordWebcam.status === "PREVIEW"
            ? "Re-record again"
            : "Start Recording"}
        </StyledTypography>
      </Button>

      {recordWebcam.status === "PREVIEW" && !value ? (
        <Button
          sx={{
            backgroundColor: "#1f219c",
            color: "#fff",
            ml: 2,
            "&:hover": { backgroundColor: "#1f219c", opacity: 0.7 },
          }}
          className="saveRecordingButton"
          onClick={handleSaveRecording}
        >
          <SaveIcon />
          <StyledTypography variant="body1">
            Save Recording
            {isVideoUploading ? (
              <>
                {/* show loader here using bootstrap */}
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </>
            ) : null}
          </StyledTypography>
        </Button>
      ) : null}
    </div>
  );
};

export default RecordingComponent;
