import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import "./Fullscreenmodal.css";

const FullscreenModal = ({ setIsModalOpenResize, isModalOpenResize }) => {

    const enterFullscreen = () => {
        const element = document.documentElement;
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }
    };

    return (
        <Modal show={isModalOpenResize} onHide={null} backdrop="static" disableEscapeKeyDown disableBackdropClick>
            <Modal.Body style={{ border: '1px solid red' }}>
                <div>
                    <h2>Warning: Exiting Fullscreen Mode</h2>
                    <p>
                        Please note that repeatedly exiting fullscreen mode or staying out of fullscreen mode for an extended duration may be considered as cheating in the examination.
                    </p>
                    <p>Please go fullscreen mode or press <b>F11</b> or <b>Fn+F11</b> key to re-enter fullscreen mode to continue.</p>

                    <Button variant="primary" onClick={enterFullscreen}>
                        Enter Fullscreen
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default FullscreenModal;
