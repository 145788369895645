import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Webcam from "react-webcam";
// import { useFaceDetection } from "react-use-face-detection";
// import { Camera } from "@mediapipe/camera_utils";
import { FaceDetection } from "@mediapipe/face_detection";
import { handlecamerapermission } from "../../../../Redux/Checkpermissions/Permission";
import { useAppDispatch } from "../../../../Redux/hooks";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import {
  HeadingsTypography,
  StyledTypography,
} from "../../../../utils/StyledTypographyHeadings";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "8px",
};

const videoConstraints = {
  width: 260,
  height: 260,
  facingMode: "user",
};

const cameraPrequsities = [
  {
    id: 1,
    title: "Face is visible",
    image: toAbsoluteUrl("/media/facedetected.png"),
  },
  {
    id: 2,
    title: "Proper lighting",
    image: toAbsoluteUrl("/media/facelight.png"),
  },
  {
    id: 3,
    title: "No Mutiple Faces",
    image: toAbsoluteUrl("/media/mutipleusers.png"),
  },
];

const isSafari = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes("safari") && !userAgent.includes("chrome");
};

const CameraForPreRequsitiesModal = ({
  setModalVisible,
  setUserFacesDetected,
}) => {
  const dispatch = useAppDispatch();
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const [faceStatus, setFaceStatus] = useState("Looking for faces...");
  const [faceFlag, setFaceFlag] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const detectionHistory = useRef([]);
  const [open, setOpen] = useState(false);

  // const { webcamRef, boundingBox, facesDetected } = useFaceDetection({
  //   faceDetectionOptions: {
  //     model: "short",
  //   },
  //   faceDetection: new FaceDetection.FaceDetection({
  //     locateFile: (file) =>
  //       `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
  //   }),
  //   camera: ({ mediaSrc, onFrame, width, height }) =>
  //     new Camera(mediaSrc, {
  //       onFrame,
  //       width,
  //       height,
  //     }),
  // });

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsReady(true);
    }, 1000);

    const faceDetection = new FaceDetection({
      locateFile: (file) =>
        `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
    });

    faceDetection.setOptions({
      model: "short", // or "full"
      minDetectionConfidence: 0.5,
    });

    faceDetection.onResults((results) => {
      try {
        const numFaces = results.detections.length;

        detectionHistory.current.push(numFaces);
        if (detectionHistory.current.length > 5) {
          detectionHistory.current.shift();
        }

        const averageNumFaces =
          detectionHistory.current.reduce((a, b) => a + b, 0) /
          detectionHistory.current.length;

        // Single face is detected
        if (averageNumFaces === 1) {
          setFaceFlag(1);
          setFaceStatus("Single face detected");
        }
        // No faces detected
        else if (averageNumFaces === 0) {
          setFaceFlag(0);
          setFaceStatus("No face detected");
        }
        // Multiple faces detected
        else if (averageNumFaces > 1) {
          setFaceFlag(averageNumFaces);
          setFaceStatus(`Multiple faces detected (${averageNumFaces})`);
        }

        // Draw the webcam frame onto the canvas
        if (canvasRef.current) {
          const canvasCtx = canvasRef.current.getContext("2d");
          const videoImage = webcamRef.current.getScreenshot(); // Capture the current frame from the webcam
          const img = new Image();
          img.src = videoImage;
          img.onload = () => {
            canvasCtx.clearRect(
              0,
              0,
              canvasRef.current.width,
              canvasRef.current.height
            );
            canvasCtx.drawImage(
              img,
              0,
              0,
              canvasRef.current.width,
              canvasRef.current.height
            );
          };
        }
      } catch (error) {
        console.error("Error processing face detection results:", error);
      }
    });

    let isProcessing = false;

    const intervalId = setInterval(async () => {
      if (isReady && !isProcessing && webcamRef.current) {
        isProcessing = true;
        try {
          const canvas = webcamRef.current.getCanvas();
          if (canvas) await faceDetection.send({ image: canvas });
        } catch (error) {
          console.error(error);
        } finally {
          isProcessing = false;
        }
      }
    }, 100);

    return () => {
      clearTimeout(timer);
      clearInterval(intervalId);
    };
  }, [isReady]);

  const handleClose = () => {
    setModalVisible(false);
  };

  const camera_permission = (status) => {
    dispatch(handlecamerapermission(status));
  };

  useEffect(() => {
    if (faceFlag < 1 || faceStatus === "Looking for faces...") {
      setUserFacesDetected(0);
    } else if (faceFlag > 1.9) {
      setUserFacesDetected(2);
    } else {
      setUserFacesDetected(1);
    }
  }, [faceFlag]);

  useEffect(() => {
    const video = webcamRef.current.video;
    if (video) {
      video.disablePictureInPicture = true;
    }
  }, [webcamRef]);

  useEffect(() => {
    if (faceStatus === "Looking for faces...") {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [faceStatus]);

  return (
    <Box
      sx={{
        ...style,
        width: { xs: 300, sm: 600, md: 800, xl: 1400 },
        height: "fit-content",
      }}
    >
      <Button
        sx={{
          position: "absolute",
          right: 0,
          top: 10,
          borderRadius: 50,
        }}
        onClick={handleClose}
      >
        <CloseIcon color="error" fontSize="medium" />
      </Button>
      <HeadingsTypography
        id="modal-modal-title"
        variant="h2"
        sx={{ textAlign: "center", my: 2, fontWeight: 700 }}
      >
        Camera Preview
      </HeadingsTypography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          padding: "20px 10px 20px 10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Webcam
            ref={webcamRef}
            audio={false}
            height={260}
            width={260}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            onUserMedia={() => {
              camera_permission(true);
            }}
            onUserMediaError={() => {
              camera_permission(false);
            }}
            className="pre-requsite-webcam"
          />
          <StyledTypography
            variant="body2"
            sx={{
              color: faceFlag < 1 || faceFlag > 1.9 ? "red" : "green",
              fontSize: { xs: 14, xl: 22 },
              mt: 1,
              fontWeight: 800,
            }}
          >
            {faceFlag < 1
              ? `Could not detect your face in the camera.`
              : faceFlag > 1.9
              ? "Multiple faces detected"
              : "All good to go!."}
          </StyledTypography>
          {faceFlag < 1 || faceFlag > 1.9 ? (
            <StyledTypography
              variant="body2"
              sx={{
                color: "red",
                fontSize: { xs: 14, xl: 22 },
                mt: 1,
                fontWeight: 800,
              }}
            >
              Please follow the instructions.
            </StyledTypography>
          ) : (
            <Button
              sx={{
                borderRadius: 2,
                backgroundColor: "#03045e",
                color: "#fff",
                marginTop: 2,
                "&:hover": {
                  backgroundColor: "#262878",
                  color: "#fff",
                },
                width: { xs: 100, xl: 150 },
                height: { xs: 30, xl: 40 },
              }}
              onClick={handleClose}
            >
              <StyledTypography
                sx={{
                  fontSize: { xs: 14, xl: 18 },
                  letterSpacing: "1px",
                  // textTransform: "capitalize",
                }}
              >
                Proceed
              </StyledTypography>
            </Button>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            flexDirection: "column",
            flex: 1,
            mb: 2,
            gap: { xs: 1, xl: 5 },
          }}
        >
          <HeadingsTypography
            variant="h2"
            className={faceFlag < 1 || faceFlag > 1.9 ? "blinking-text" : ""}
            sx={{
              color: "black",
              marginBottom: { xs: 2, xl: 5 },
              letterSpacing: "1px",
              fontWeight: 700,
            }}
          >
            Instructions
          </HeadingsTypography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            {cameraPrequsities &&
              cameraPrequsities.map((each, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      flexDirection: "column",
                      flex: 1,
                    }}
                  >
                    <Box
                      component="img"
                      src={each?.image}
                      alt=""
                      sx={{ width: { xs: "30%", xl: "40%" } }}
                    />
                    <StyledTypography
                      variant="body1"
                      sx={{ mt: 1, color: "black", fontWeight: 600 }}
                    >
                      {each?.title}
                    </StyledTypography>
                  </Box>
                );
              })}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              mt: 2,
              width: "90%",
            }}
          >
            <StyledTypography
              variant="body2"
              sx={{ my: 1, fontSize: { xs: 12, xl: 22 } }}
            >
              &#x2022; Before proceeding, please check your camera is working
              properly or not, and face is properly visible in camera.
            </StyledTypography>
            <StyledTypography
              variant="body2"
              sx={{ my: 1, fontSize: { xs: 12, xl: 22 } }}
            >
              &#x2022; Make sure you are sitting under proper light so that your
              face is clearly visible.
            </StyledTypography>
            <StyledTypography
              variant="body2"
              sx={{ my: 1, fontSize: { xs: 12, xl: 22 } }}
            >
              &#x2022; Not More than one face is allowed while taking the test.
            </StyledTypography>
            <StyledTypography
              variant="body2"
              sx={{ my: 1, fontSize: { xs: 12, xl: 22 } }}
            >
              &#x2022; Please make sure you pass all the above requirements so
              that you can give your test further without any issues.
            </StyledTypography>
            {isSafari() && (
              <StyledTypography
                variant="body2"
                sx={{ my: 1, fontSize: { xs: 14, xl: 22 }, color: "red" }}
              >
                &#x2022; If you are facing issues with the camera in Safari,
                please switch to Chrome browser for a better experience.
              </StyledTypography>
            )}
          </Box>
        </Box>
      </Box>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "hidden",
          },
        }}
      >
        <CircularProgress />
      </Dialog>
    </Box>
  );
};

export default CameraForPreRequsitiesModal;
