import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { toAbsoluteUrl } from "../../../helpers";
import SyncLoader from "react-spinners/SyncLoader";
import DotLoader from "react-spinners/DotLoader";
import HashLoader from "react-spinners/HashLoader";

//do this with redux dispatch as well

export default function Loader() {
  const open = useSelector((state) => state?.loader?.open);

  //   const handleClose = () => {
  //     setOpen(false);
  //   };
  //   const handleToggle = () => {
  //     setOpen(!open);
  //   };

  return (
    <div>
      <Backdrop sx={{ color: "#fff", zIndex: 99999899999999999 }} open={open}>
        <HashLoader
          color="#57aeff" />
      </Backdrop>
    </div>
  );
}
