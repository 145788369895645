import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const AntiCheatTermsModal = ({ setShowModal, showModal, type }) => {
    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        backdrop="static"
        size="xl"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <h1>Anti-cheat terms and conditions agreement</h1>
        </Modal.Header>
        {type === 1 && (
          <Modal.Body>
            <div>
              <h2>
                I hereby make the following commitment to maintain academic
                integrity during exams:
              </h2>
              <ul style={{ listStyle: "disc" }}>
                <li>
                  I understand the importance of academic honesty and the
                  negative consequences of cheating.
                </li>
                <li>
                  {" "}
                  I promise that I will not engage in any form of cheating
                  during my exams.
                </li>
                <li>
                  {" "}
                  If I am found to have cheated, I acknowledge that my exam will
                  be considered null and void.
                </li>
                <li>
                  {" "}
                  I am aware that cheating violates the rules and regulations
                  set forth by my educational institution.
                </li>
                <li>
                  {" "}
                  I understand that cheating can lead to disciplinary action as
                  per my institution's policies.
                </li>
                <li>
                  {" "}
                  I commit to upholding the principles of academic honesty
                  throughout my educational journey.
                </li>
                <li>
                  {" "}
                  I will rely on my own knowledge, skills, and efforts to
                  complete my exams.
                </li>
                <li>
                  {" "}
                  I will not seek or provide unauthorized assistance during the
                  examination process.
                </li>
                <li>
                  {" "}
                  I understand that using any means to escape fullscreen mode
                  during an online exam will be considered cheating.
                </li>
                <li>
                  {" "}
                  I will follow all guidelines and instructions provided by my
                  institution regarding exam conduct.
                </li>
                <li>
                  {" "}
                  I recognize that academic success achieved through cheating is
                  not a true reflection of my abilities.
                </li>
                <li>
                  {" "}
                  I will embrace the challenge of exams as an opportunity to
                  demonstrate my knowledge and understanding.
                </li>
                <li>
                  {" "}
                  I will be honest, ethical, and responsible in all my academic
                  endeavors.
                </li>
              </ul>
            </div>
          </Modal.Body>
        )}
        {type === 2 && (
          <Modal.Body>
            <div>
              <h2>
                I hereby make the following commitment to maintain academic
                integrity during exams:
              </h2>
              <ul style={{ listStyle: "disc" }}>
                <li>
                  By using this test platform, you agree to the following terms
                  and conditions:
                </li>
                <li>
                  Your screen may be monitored during the test for anti-cheating
                  purposes.
                </li>
                <li>
                  Monitoring aims to ensure the integrity and fairness of the
                  test.
                </li>
                <li>
                  Only the test administrators have access to the monitored
                  screens.
                </li>
                <li>
                  Any attempts to cheat or violate test guidelines may result in
                  disqualification.
                </li>
                <li>
                  The collected data will be used solely for test integrity and
                  improvement purposes.
                </li>
                <li>
                  We are committed to protecting your privacy and sensitive
                  information.
                </li>
                <li>
                  By continuing, you consent to the above terms regarding screen
                  monitoring.
                </li>
              </ul>
            </div>
          </Modal.Body>
        )}
        {type === 3 && (
          <Modal.Body>
            <div>
              <h2>
                I hereby make the following commitment to maintain academic
                integrity during exams:
              </h2>
              <ul style={{ listStyle: "disc" }}>
                <li>
                  By using this test platform, you agree to the following terms
                  and conditions:
                </li>
                <li>Your camera may be accessed and used during the test.</li>
                <li>
                  Camera access is solely for identity verification and test
                  integrity.
                </li>
                <li>
                  Video capture will only be active during the duration of the
                  test.
                </li>
                <li>
                  Only authorized test administrators have access to the
                  captured video.
                </li>
                <li>
                  The captured video will not be shared or used for purposes
                  other than test integrity.
                </li>
                <li>
                  Your privacy and confidentiality are of utmost importance to
                  us.
                </li>
                <li>
                  Any misuse of camera access may lead to disqualification from
                  the test.
                </li>
                <li>
                  By proceeding, you consent to the above terms regarding camera
                  capture.
                </li>
              </ul>
            </div>
          </Modal.Body>
        )}
        {type === 4 && (
          <Modal.Body>
            <div>
              <h2>
                I hereby make the following commitment to maintain academic
                integrity during exams:
              </h2>
              <ul style={{ listStyle: "disc" }}>
                <li>
                  By using this test platform, you agree to the following terms
                  and conditions:
                </li>
                <li>Your location may be accessed and used during the test.</li>
                <li>
                  Location access is solely for ensuring the integrity and
                  fairness of the test.
                </li>
                <li>
                  Location data will only be collected during the duration of
                  the test.
                </li>
                <li>
                  Only authorized test administrators have access to the
                  collected location data.
                </li>
                <li>
                  The collected location data will not be shared or used for
                  purposes other than test integrity.
                </li>
                <li>
                  Your privacy and confidentiality are of utmost importance to
                  us.
                </li>
                <li>
                  Any misuse of location data may lead to disqualification from
                  the test.
                </li>
                <li>
                  By proceeding, you consent to the above terms regarding
                  location data collection.
                </li>
              </ul>
            </div>
          </Modal.Body>
        )}
        {type === 5 && (
          <Modal.Body>
            <div>
              <h2>
                I hereby make the following commitment to maintain academic
                integrity during exams:
              </h2>
              <ul style={{ listStyle: "disc" }}>
                <li>
                  By using this test platform, you agree to the following terms
                  and conditions:
                </li>
                <li>
                  Notifications may be sent during the test for important
                  updates or alerts.
                </li>
                <li>
                  Notification access is solely for ensuring timely
                  communication during the test.
                </li>
                <li>
                  Notifications will only be active during the duration of the
                  test and related activities.
                </li>
                <li>
                  Your notification settings will not be altered beyond the
                  scope of the test.
                </li>
                <li>
                  Only authorized test administrators can send notifications
                  related to the test.
                </li>
                <li>
                  Your privacy and confidentiality are of utmost importance to
                  us.
                </li>
                <li>
                  By proceeding, you consent to the above terms regarding
                  notification access.
                </li>
              </ul>
            </div>
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
};

export default AntiCheatTermsModal;
