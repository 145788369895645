import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  previewopen: true,
};

export const CameraPreviewTrigger = createSlice({
  name: "CameraPreview",
  initialState,
  reducers: {
    handlechangepreview: (state) => {
      state.previewopen = !state.previewopen;
    },
  },
});

export const { handlechangepreview } = CameraPreviewTrigger.actions;
export default CameraPreviewTrigger.reducer;
