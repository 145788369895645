/* eslint-disable jsx-a11y/anchor-is-valid */
import axios, { AxiosResponse } from "axios";
import React, { FC, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import {
  CardsWidget17,
  CardsWidget20,
  CardsWidget7,
  ListsWidget6,
  ListsWidget7,
  ListsWidget8,
  ListsWidget9,
  Questioncardwidget,
} from "../../../_metronic/partials/widgets";
import { answeredvalues, fetchdata } from "../../../Redux/TestPage/TestSlice";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { Online, Offline } from "react-detect-offline";
import {
  Alert,
  Box,
  Button,
  Snackbar,
  styled,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
// import SubmitModal from "./SubmitModal";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { handleopensnackbar } from "../../../Redux/Snackbarwithmessages/Snackbarwithmessages";
import {
  handlecloseloader,
  handleopenloader,
} from "../../../Redux/Loader/Loader";
import { CameraModal } from "../../modules/auth/components/CameraModal";
import WebcamCapture from "../../modules/auth/components/MainCamera";
// import Camerafortest from "./Camerfortest/Camerafortest";
import { handlelocationpermission } from "../../../Redux/Checkpermissions/Permission";
import {
  fetchdashboard,
  fetchdashboardstats,
} from "../../../Redux/Dashboard/Testseries";
import { ListsWidget26 } from "../../../_metronic/partials/widgets/_new/lists/ListsWidget26";
import "./dashboardintial.css";
import backgroundImage from "../../../_metronic/assets/images/dashboard-cover-image.png";
import illustrationImage from "../../../_metronic/assets/images/illustrations/3d_user_image.png";
import { fetchMyprofile } from "../../../Redux/Profiledetails/Profiledetails";
import {
  HeadingsTypography,
  StyledTypography,
} from "../../../utils/StyledTypographyHeadings";

var Scroll = require("react-scroll");
var scroller = Scroll.scroller;
type Props = {};

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "18px 16px 18px 16px",
  borderRadius: "16px",
  backgroundColor: "#101820",
  color: "#fff",
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  position: "relative",
  overflow: "hidden",
  flex: 0.5,
  gap: "40px",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "80%",
    gap: "50px",
  },
  [theme.breakpoints.up("md")]: {
    width: "100%",
  },
}));

const TextContainer = styled(Box)({
  zIndex: 2,
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  alignItems: "flex-start",
});

const Illustration = styled("img")({
  flex: 0.5,
  width: "180px",
  height: "180px",
  zIndex: 2,
});

const Overlay = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.6)",
  zIndex: 1,
});

const textStyles = {
  margin: "0px 0px 12px",
  fontFamily:
    '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  opacity: 1,
  maxWidth: "360px",
};

const Testpage = (props: Props) => {
  const [fetchdata, setfetcheddata] = useState<any>({});
  const [count, setcount] = useState([{ id: "" }]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(fetchdashboardstats({}));
  }, []);

  const fetcheddata = useAppSelector(
    (state) => state?.testseries?.fetchdashboardstats
  );

  useEffect(() => {
    setfetcheddata(fetcheddata);
  }, [fetcheddata]);

  return (
    <Box sx={{ width: "100%", height: "100vh" }}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: { xs: "center", md: "space-between" },
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Container>
          <Overlay />
          <TextContainer>
            <HeadingsTypography
              variant="h5"
              sx={{
                ...textStyles,
                letterSpacing: "1px",
                color: "#fff",
                fontSize: { xs: 12, sm: 18, xl: 18 },
              }}
            >
              Hi 👋
            </HeadingsTypography>
            <HeadingsTypography
              variant="h5"
              sx={{
                ...textStyles,
                letterSpacing: "1px",
                color: "#fff",
                fontSize: { xs: 10, sm: 14, xl: 18 },
              }}
            >
              {JSON.parse(localStorage.getItem("userdetails") as string)?.name}
            </HeadingsTypography>
            <StyledTypography
              variant="body2"
              paragraph
              sx={{
                ...textStyles,
                color: "#d4d4d4",
                fontSize: { xs: 12, sm: 14, xl: 16 },
              }}
            >
              Welcome back to MTestHub.
            </StyledTypography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#03045e",
                textTransform: "initial",
                "&:hover": {
                  opacity: 0.8,
                  backgroundColor: "#03045e",
                },
              }}
              onClick={() => {
                navigate("/dashboard/assigned");
              }}
            >
              <StyledTypography
                sx={{
                  color: "#fff",
                  fontSize: { xs: 10, sm: 14, xl: 16 },
                  "&:hover": {
                    color: "#fff",
                  },
                }}
              >
                Check Assigned Test
              </StyledTypography>
            </Button>
          </TextContainer>
          <Illustration src={illustrationImage} alt="Illustration" />
        </Container>

        <Box sx={{ flex: 0.5 }}>{}</Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: { xs: "center", md: "flex-start" },
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
          margin: "20px 0",
          gap: 2,
        }}
      >
        <CardsWidget7
          url="/dashboard/assigned"
          className="cardmain"
          title="Assigned Test"
          description="All tests that have been assigned to you"
          iconColor={"#03045e"}
          stats={fetchdata?.countofassignedtest}
          labelColor=""
          textColor=""
        />
        <CardsWidget7
          url="/dashboard/attemptedseries"
          className="cardmain"
          title="Attempted Test"
          description="All tests that have been attempted by you"
          iconColor={"#5455a4"}
          stats={fetchdata?.countofattemptedtest}
          labelColor=""
          textColor=""
        />
      </Box>
    </Box>
  );
};

const Initialdash: FC = () => {
  const intl = useIntl();

  return (
    <>
      <Testpage />
    </>
  );
};

export { Initialdash };
