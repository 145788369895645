import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { useParams } from "react-router-dom";
import { handlecloseloader, handleopenloader } from "../Loader/Loader";
import { handleopensnackbar } from "../Snackbarwithmessages/Snackbarwithmessages";
import type { RootState } from "../store";

// Define a type for the slice state
interface testState {
  fetcheddata: any;
  fetchedsortno: any;
  fetchedtimer: number;
  answereddata: [];
  overviewdata: any;
  recovereddata: {};
  totaltime: number;
  fetchedsetting: any;
  fetchedexists: any;
  fetchedsuccess: boolean;
  timerComplete: boolean;
}

// Define the initial state using that type
const initialState: testState = {
  fetcheddata: [],
  fetchedsortno: [],
  fetchedsuccess: true,
  fetchedtimer: 0,
  answereddata: [],
  overviewdata: {},
  recovereddata: {},
  totaltime: 0,
  fetchedsetting: {},
  fetchedexists: {},
  timerComplete: false
};
const currentusertoken = JSON.parse(
  localStorage.getItem("userdetails") as string
);
export const fetchdata = createAsyncThunk(
  "testredux/fetchdata",
  (payload: any, { dispatch }) => {
    dispatch(handleopenloader());

    return axios
      .get(`/getexamdata/${payload.testid}`)
      .then(function (response) {
        dispatch(handlecloseloader());

        if (response?.data.success === true) {
          return response?.data;
        } else {
          const datapayload = {
            messages: response?.data?.message || response?.data?.message,
            severity: "error",
          };
          dispatch(handleopensnackbar(datapayload));
        }
      })
      .catch(function (e) {
        dispatch(handlecloseloader());

        const datapayload = {
          messages: e?.response?.data?.data?.error || e?.message,
          severity: "error",
        };
        dispatch(handleopensnackbar(datapayload));
      });
  }
);

export const fetchdatapreview = createAsyncThunk(
  "testredux/fetchdatapreview",
  (payload: any, { dispatch }) => {
    dispatch(handleopenloader());

    return axios
      .get(`/getexamdata/get-test-preview/${payload.testid}`)
      .then(function (response) {
        dispatch(handlecloseloader());

        // alert(response?.data.success);
        if (response?.data.success === true) {
          return response?.data;
        } else {
          // alert("here")
          const datapayload = {
            messages: response?.data?.msg,
            severity: "error",
          };
          dispatch(handleopensnackbar(datapayload));
          return response?.data
        }
      })
      .catch(function (e) {
        dispatch(handlecloseloader());

        const datapayload = {
          messages: e?.response?.data?.data?.error || e?.message,
          severity: "error",
        };
        dispatch(handleopensnackbar(datapayload));
      });
  }
);


export const fetchoverview = createAsyncThunk(
  "testredux/fetchoverview",
  (payload: any, { dispatch }) => {
    dispatch(handleopenloader());
    return axios
      .get(`/getexamdata/testoverview/${payload.testid}`, {
        headers: {
          Authorization: `Bearer ${currentusertoken.token}`,
        },
      })
      .then(function (response) {
        dispatch(handlecloseloader());
        return response?.data;
      })
      .catch(function (e) {
        dispatch(handlecloseloader());

        const payloaddata = {
          messages: e?.response?.data?.msg || e?.msg,
          severity: "error",
        };

        dispatch(handleopensnackbar(payloaddata));
      });
  }
);


export const fetchoverviewpreview = createAsyncThunk(
  "testredux/fetchoverviewpreview",
  (payload: any, { dispatch }) => {
    dispatch(handleopenloader());
    return axios
      .get(`/getexamdata/get-testoverview-preview/${payload.testid}`)
      .then(function (response) {
        dispatch(handlecloseloader());
        return response?.data;
      })
      .catch(function (e) {
        dispatch(handlecloseloader());

        const payloaddata = {
          messages: e?.response?.data?.msg || e?.msg,
          severity: "error",
        };

        dispatch(handleopensnackbar(payloaddata));
      });
  }
);


export const fetchoverviewnoauth = createAsyncThunk(
  "testredux/fetchoverviewnoauth",
  (payload: any, { dispatch }) => {
    dispatch(handleopenloader());
    return axios
      .post(`/getexamdata/get-testoverview-no-auth/${payload.testid}`,{
        cryptvalue: process.env.REACT_APP_CRYPTVALUELOG
      })
      .then(function (response) {
        dispatch(handlecloseloader());
        return response?.data;
      })
      .catch(function (e) {
        dispatch(handlecloseloader());

        const payloaddata = {
          messages: e?.response?.data?.msg || e?.msg,
          severity: "error",
        };

        dispatch(handleopensnackbar(payloaddata));
      });
  }
);



export const testexists = createAsyncThunk(
  "testredux/testexists",
  (payload: any, { dispatch }) => {
    dispatch(handleopenloader());
    return axios
      .get(`/getexamdata/existindb/${payload.testid}`)
      .then(function (response) {
        dispatch(handlecloseloader());
        return response?.data;
      })
      .catch(function (e) {
        dispatch(handlecloseloader());

        const payloaddata = {
          messages: e?.response?.data?.msg || e?.msg,
          severity: "error",
        };

        dispatch(handleopensnackbar(payloaddata));
      });
  }
);

export const TestSlice = createSlice({
  name: "testredux",
  initialState,
  reducers: {
    answeredvalues: (state: testState, action) => {
      state.answereddata = action.payload;
    },
    recovervalues: (state: testState, action) => {

      const a = JSON.parse(action.payload);

    },
    timerComplete: (state: testState, action: any) => {
      state.timerComplete = action.payload;
    }
  },
  extraReducers: (builder) => {
    // builder.addCase(fetchdata.pending, (state) => {
    //     state.fetcheddata=[]
    // });
    builder.addCase(
      fetchdata.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.fetcheddata = action.payload.data;
        state.fetchedsortno = action.payload.sortno;
        state.fetchedsuccess = action.payload.success;
        state.fetchedtimer = action.payload.timelimit;
        state.totaltime = action.payload.totaltime;
      }
    );
    builder.addCase(
      fetchdatapreview.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.fetcheddata = action.payload.data;
        state.fetchedsortno = action.payload.sortno;
        state.fetchedsuccess = action.payload.success;
        state.fetchedtimer = action.payload.timelimit;
        state.totaltime = action.payload.totaltime;
      }
    );
    builder.addCase(
      fetchoverview.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.overviewdata = action.payload;
        state.fetchedsetting = action.payload?.setting;


      }
    );
    builder.addCase(
      fetchoverviewpreview.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.overviewdata = action.payload;
        state.fetchedsetting = action.payload?.setting;


      }
    );
    builder.addCase(
      fetchoverviewnoauth.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.overviewdata = action.payload;
        state.fetchedsetting = action.payload?.setting;
      }
    );
    builder.addCase(
      testexists.fulfilled,
      (state, action: PayloadAction<[]>) => {
        state.fetchedexists = action.payload;

      }
    );
  },
});

export const { answeredvalues, recovervalues, timerComplete } = TestSlice.actions;
export default TestSlice.reducer;
