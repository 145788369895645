const CodeLanguageEnum: any = {
    "45": "nasm",
    "46": "shell",
    "47": "basic",
    "75": "text/x-csrc",
    "76": "text/x-c++src",
    "48": "text/x-csrc",
    "52": "text/x-c++src",
    "49": "text/x-csrc",
    "53": "text/x-c++src",
    "50": "text/x-csrc",
    "54": "text/x-c++src",
    "86": "clojure",
    "51": "text/x-csharp",
    "77": "cobol",
    "55": "commonlisp",
    "56": "d",
    "57": "elixir",
    "58": "erlang",
    "44": "",
    "87": "fsharp",
    "59": "fortran",
    "60": "go",
    "88": "groovy",
    "61": "haskell",
    "62": "clike",
    "63": "javascript",
    "78": "text/x-kotlin",
    "64": "lua",
    "89": "",
    "79": "clike",
    "65": "ocaml",
    "66": "octave",
    "67": "pascal",
    "85": "perl",
    "68": "php",
    "43": "",
    "69": "prolog",
    "70": "python",
    "71": "python",
    "80": "r",
    "72": "ruby",
    "73": "rust",
    "81": "text/x-scala",
    "82": "text/x-sql",
    "83": "swift",
    "74": "javascript",
    "84": "text/x-vb",
}

export default CodeLanguageEnum;