import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import {
  handlecloseloader,
  handleopenloader,
} from "../../../../Redux/Loader/Loader";
import "./Modalforanticheatreport.css";
import Typography from '@mui/material/Typography';
import Pdf from "react-to-pdf";
import { siteConfig } from "../../../../config/siteConfig";




const Modalforanticheatreport = (props) => {
  const {
    show,
    handleClose,
    testtakersessionid,
    title,
    testid,
    remarks,
    score,
  } = props;
  const ref = useRef(null);
  const [data, setdata] = useState({});
  const [ipdata, setipdata] = useState({});
  const [locationdata, setlocationdata] = useState({});
  const [geolocationdata, setgeolocationdata] = useState({});
  const [useragentdata, setuseragentdata] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(handleopenloader());
    axios
      .get(`/dashboard/view-anticheat-report/${testtakersessionid}`)
      .then((res) => {
        setdata(res?.data?.data);

        dispatch(handlecloseloader());
      })
      .catch((err) => {
        dispatch(handlecloseloader());
        console.error(err);
      });
  }, []);


  useEffect(() => {
    axios
      .get(`https://ipapi.co/${data?.device_ip}/json/`)
      .then((res) => {
        setipdata(res?.data);
      })
      .catch((err) => {
        dispatch(handlecloseloader());
        console.error(err);
      });
    axios
      .get(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${geolocationdata?.geolocationlatitude}&longitude=${geolocationdata?.geolocationlongitude}&localityLanguage=en`)
      .then((res) => {
        setlocationdata(res?.data)
      })
      .catch((err) => {
        dispatch(handlecloseloader());
        console.error(err);
      });
    data?.user_location && setgeolocationdata(JSON.parse(data?.user_location))
    data?.deviceinfo && setuseragentdata(JSON.parse(data?.deviceinfo))
    const aheight = ref.current.clientHeight
    const awidth = ref.current.clientWidth
    // alert((aheight / 96).toFixed(2));
    setheightofresult((aheight / 96).toFixed(2))
    setwidthofresult((awidth / 96).toFixed(2))
  }, [data])


  const [heightofresult, setheightofresult] = useState(false);
  const [widthofresult, setwidthofresult] = useState(false);



  const options = {
    orientation: 'potrait',
    unit: 'in',
    format: [heightofresult, widthofresult]
  };



  return (
    <>

      <Modal fullscreen show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
          <div
            style={{ position: "absolute", right: "80px", marginTop: "10px" }}
            className="parentdivheader"
          >
            {
              <p
                style={{ marginRight: "-20px", fontSize: "14px" }}
                className="ptag"
              >
                <span style={{ fontWeight: "bold", marginRight: '4px', fontSize: "14px" }}>
                  submitted by
                </span>
                {/* {data?.test_ended_at.slice(0, -4)} */}
                {data?.submitted_by == 1 ? "user" : "system"}
              </p>
            }
          </div>
        </Modal.Header>
        <Modal.Body style={{ width: "100vw !important" }}>
          <div className="card card-custom">
            <div className="card-header">
              <h3 className="card-title"></h3>
              <div className="card-toolbar">
                <Pdf
                  targetRef={ref}
                  filename={`${title}-anticheat-report-by ${siteConfig?.sitename}.pdf`}
                  options={options}
                >
                  {({ toPdf }) => (
                    <button type="button" onClick={toPdf} className="btn btn-sm btn-light" >
                      Download pdf
                    </button>
                  )}
                </Pdf>

              </div>
            </div>
            <div className="card-body" ref={ref}>


              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="">Test Giver Name</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <Typography variant="h6"  >

                    {JSON.parse(localStorage.getItem("userdetails"))?.name ? JSON.parse(localStorage.getItem("userdetails"))?.name : "No data"}
                  </Typography>
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="">Test Name</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <Typography variant="h6"  >
                    {title}
                  </Typography>
                </div>
              </div>



              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="">Started at</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <Typography variant="h6"  >
                    {data?.created_at}
                  </Typography>
                </div>
              </div>


              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="">Submitted at</span>
                </label>
                <div className="col-lg-8 fv-row">
                  <Typography variant="h6"  >
                    {data?.test_ended_at ? data?.test_ended_at?.slice(0, -4) : "No data"}
                  </Typography>
                </div>
              </div>


              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="">Submitted by</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <Typography variant="h6"  >
                    {data?.submitted_by == 1 ? "User" : "System"}
                  </Typography>
                </div>
              </div>


              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="">Switched tabs</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <Typography variant="h6"  >
                    {data?.switched_tabs ? `${data?.switched_tabs} times` : "No data"}
                  </Typography>
                </div>
              </div>


              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="">Device IP Address</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <Typography variant="h6" >
                    {data?.device_ip}      </Typography>
                  <div>
                    <Typography variant="h6"  >
                      {ipdata?.org} | {ipdata?.city}
                    </Typography>
                  </div>
                </div>

              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="">Device Location Details</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <Typography variant="h6"  >
                    {locationdata?.locality} | {locationdata?.city} | {locationdata?.principalSubdivision} | {locationdata?.countryName} | {locationdata?.continent}
                    <div>
                      Longitude : {geolocationdata?.geolocationlongitude}
                    </div>
                    <div>
                      Latitude : {geolocationdata?.geolocationlatitude}
                    </div>
                  </Typography>
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="">Browser Details</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <Typography variant="h6"  >
                    {/* {useragentdata} */}
                    {useragentdata?.browser} {useragentdata?.version}
                  </Typography>
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="">Device Details</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <Typography variant="h6"  >
                    {/* {useragentdata} */}
                    {useragentdata?.os} {useragentdata?.version}
                  </Typography>
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="">Device Type</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <Typography variant="h6"  >
                    {/* {useragentdata} */}
                    {useragentdata?.isDesktop ? "Desktop" : useragentdata?.isMobile ? "Mobile" : ""} |                       {useragentdata?.isWindows ? "Windows" : useragentdata?.isMac ? "Mac" : useragentdata?.isChromeOS ? "Chrome os" : useragentdata?.isLinux ? "Linux" : useragentdata?.isAndroid ? "Android" : useragentdata?.isiPad ? "IPad" : useragentdata?.isiPhone ? "IPhone" : ""}

                  </Typography>
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                  <span className="">Is Bot</span>
                </label>

                <div className="col-lg-8 fv-row">
                  <Typography variant="h6"  >
                    {/* {useragentdata} */}
                    {useragentdata?.isBot ? "True" : "False"}
                  </Typography>
                </div>
              </div>

            </div>
          </div>

          <Modal.Footer className="modalfooterparent">
            {/* footer */}
          </Modal.Footer>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Modalforanticheatreport;
