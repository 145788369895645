import React, { Suspense, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { I18nProvider } from "../_metronic/i18n/i18nProvider";
import TimerContainer from "../_metronic/layout/components/sidebar/sidebar-menu/allquestionsaside/TimerContainer";
import { LayoutProvider, LayoutSplashScreen } from "../_metronic/layout/core";
import { MasterInit } from "../_metronic/layout/MasterInit";
import { AuthInit } from "./modules/auth";
import "./App.css";
import axios from "axios";
import setupAxios from "../config/axiosConfig";
import Snackbarwithmessage from "../_metronic/layout/components/Snackbar/Snackbarwithmessage";
import Loader from "../_metronic/layout/components/Loader/Loader";
import { useAppDispatch, useAppSelector } from "../Redux/hooks";
import { AiOutlineWarning } from "react-icons/ai";
import { Box, CardContent, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import { getalldatabaseconfig } from "../Redux/Dashboard/Testseries";

const App = () => {
  function getBrowser() {
    if (navigator.userAgent.indexOf("Chrome") != -1) {
      return "Chrome";
    } else if (navigator.userAgent.indexOf("Opera") != -1) {
      return "Opera";
    } else if (navigator.userAgent.indexOf("MSIE") != -1) {
      return "IE";
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      return "Firefox";
    } else {
      return "unknown";
    }
  }

  const [tokenstate, settokenstate] = React.useState("");
  const token = JSON.parse(
    localStorage.getItem("userdetails") as string
  )?.token;
  React.useEffect(() => {
    settokenstate(token);
  }, [token]);

  setupAxios(axios, tokenstate);

  const camera_permission: boolean = useAppSelector(
    (state) => state?.permission?.camerapermission
  );
  const camera_permission2: string = useAppSelector(
    (state) => state?.permission?.camerapermission2
  );

  //here check for setting in backend as well if there exists then
  const fetchedsetting = useAppSelector(
    (state) => state?.tests?.fetchedsetting
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (localStorage.getItem("userdetails")) {
      dispatch(getalldatabaseconfig(dispatch));
    }
  }, [dispatch])


  return (
    <>
    {  camera_permission ? (
        <>
          <Suspense fallback={<LayoutSplashScreen />}>
            <I18nProvider>
              <LayoutProvider>
                <AuthInit>
                  {/* <TimerContainer teststarted={true} /> */}
                  <Outlet />
                  <MasterInit />
                </AuthInit>
              </LayoutProvider>
            </I18nProvider>
          </Suspense>
          <Snackbarwithmessage />
          <Loader />
        </>
      ) : (
        <>
            {(fetchedsetting.randomphotos == "true" || fetchedsetting.live_proctoring_camera == "true") && (
            <div id="forfullscreen" className="rerenderstate">
              <Box sx={{ minWidth: 240, marginTop: "26vh" }}>
                <Card variant="outlined">
                  <React.Fragment>
                    <CardContent>
                      <div
                        style={{
                          width: "auto",
                          marginTop: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <AiOutlineWarning
                          size={70}
                          style={{ marginBottom: "-31px" }}
                        />
                      </div>

                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "58px",
                        }}
                        style={{ color: "red" }}
                        variant="h5"
                        component="div"
                      >
                        Allow camera permission and reload to proceed further,a
                        popup might open allow the permission there if it doesnt
                        appear kindly refer this
                        {getBrowser() === "Chrome" && (
                          <a
                            style={{
                              marginLeft: "6px",
                              color: "blue",
                              cursor: "pointer",
                            }}
                            href={
                              "https://support.google.com/chrome/answer/2693767?hl=en-GB&co=GENIE.Platform%3DDesktop"
                            }
                            target="_blank"
                          >
                            {" "}
                            ?
                          </a>
                        )}
                        {getBrowser() === "Firefox" && (
                          <a
                            style={{ marginLeft: "6px" }}
                            href={
                              "https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions"
                            }
                            target="_blank"
                          >
                            {" "}
                            ?
                          </a>
                        )}
                        {getBrowser() === "IE" && (
                          <a
                            style={{ marginLeft: "6px" }}
                            href={
                              "https://help.remo.co/en/support/solutions/articles/63000259814-how-to-allow-microsoft-edge-access-to-my-camera-and-microphone"
                            }
                            target="_blank"
                          >
                            {" "}
                            ?
                          </a>
                        )}
                        {getBrowser() === "Opera" && (
                          <a
                            style={{ marginLeft: "6px" }}
                            href="https://help.opera.com/en/latest/web-preferences/"
                            target="_blank"
                          >
                            {" "}
                            ?
                          </a>
                        )}
                      </Typography>
                    </CardContent>
                    <Typography
                      sx={{
                        mb: 1.5,
                        ml: 2,
                        display: "flex",
                        justifyContent: "center",
                      }}
                      color="text.secondary"
                    >
                      Note: Please allow the permission of camera for the test
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {/* camera here and next button */}
                    </div>
                  </React.Fragment>
                </Card>
              </Box>
            </div>
          )}
        </>
      )}
    </>
  );
};

export { App };
