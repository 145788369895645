import { Box, Typography } from "@mui/material";
import React from "react";
import { IoMdCheckmark, IoMdClose } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../Redux/hooks";
import AntiCheatTermsModal from "../modals/AntiCheatTermsModal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { handlestartrecording } from "../../../../Redux/ScreenRecord/ScreenRecord";
import { MdScreenShare } from "react-icons/md";
import ScreenWarn from "../modals/ScreenWarn";
import ShareEntireScreenModal from "../modals/ShareEntireScreenModal";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { StyledTypography } from "../../../../utils/StyledTypographyHeadings";
import PermissionMessage from "./Permissions_Allow/PermissionMessage";

type ShareScreenType = {
  setScreenComp: React.Dispatch<React.SetStateAction<boolean>>;
  setShowOtherComp: React.Dispatch<React.SetStateAction<boolean>>;
  setShowTermsComp: React.Dispatch<React.SetStateAction<boolean>>;
  testid?: string;
  tokenid?: string;
  Recorder?: any;
  handleStartRecording: any;
  fullscreentogglehandler: () => void;
}

const ShareScreen = ({
  setScreenComp,
  setShowOtherComp,
  setShowTermsComp,
  testid,
  tokenid,
  Recorder,
  handleStartRecording,
  fullscreentogglehandler
}: ShareScreenType) => {
  const [showScreenTermsModal, setShowScreenTermsModal] = React.useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const fetchedoverviewdata = useAppSelector(
    (state) => state?.tests?.overviewdata
  );


  const handleShowScreenTermsModal = () => {
    setShowScreenTermsModal(true);
  };

  const handleNext = () => {
    if(fetchedoverviewdata?.setting?.islocation == "true"){
      setScreenComp(false);
      setShowOtherComp(true);
    }else{
      console.log("Navigating to test page...");
      if (tokenid) {
        navigate(`/preview-test/${tokenid}`);
      } else if (testid) {
        navigate(`/test/${testid}`);
      }
      if (fetchedoverviewdata?.setting?.fullscreen === "true") {
        fullscreentogglehandler();
      }
      setScreenComp(false);
      setShowTermsComp(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 5,
          padding: 2,
        }}
      >
        <Box
          component="img"
          src={toAbsoluteUrl("/media/screen_share.png")}
          alt="screenshare-required-image"
          sx={{
            width: 500,
            height: 250,
            objectFit: "contain",
          }}
        />

        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "flex-start",
            alignItems: "center",
            ml: 1,
          }}
        >
          <button
            className="prerequisite-buttons"
            onClick={handleStartRecording}
            disabled={Recorder}
            style={{
              opacity: Recorder ? "0.5" : "1",
              cursor: Recorder ? "not-allowed" : "pointer",
            }}
          >
            Click to share your screen
            <MdScreenShare size={18} />
          </button>
          {Recorder ? (
            <IoMdCheckmark size={20} color="green" />
          ) : (
            <IoMdClose size={20} color="red" />
          )}
        </Box>

        <StyledTypography variant="body1" sx={{ fontSize: { xs: 10, sm: 16, xl: 18 } }}>
          By clicking on above button you agree the
          <StyledTypography
            component="span"
            variant="body1"
            sx={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
              marginLeft: 1,
              fontSize: { xs: 10, sm: 16, xl: 18 }
            }}
            onClick={() => {
              handleShowScreenTermsModal();
            }}
          >
            terms and conditions for screen sharing
          </StyledTypography>
        </StyledTypography>

        <PermissionMessage microphone={true}/>

        <button
          className={`styled-btn ${
            !Recorder || Recorder === "" ? "disabled" : ""
          }`}
          onClick={handleNext}
          disabled={!Recorder || Recorder === ""}
        >
          Next
        </button>
      </Box>

      <AntiCheatTermsModal
        type={2}
        showModal={showScreenTermsModal}
        setShowModal={setShowScreenTermsModal}
      />
    </>
  );
};

export default ShareScreen;