import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { useParams } from "react-router-dom";
import { handlecloseloader, handleopenloader } from "../Loader/Loader";
import { handleopensnackbar } from "../Snackbarwithmessages/Snackbarwithmessages";
import type { RootState } from "../store";

// Define a type for the slice state
interface authstate {
  Email: string;
  password: string;
  response: any;
  responsesignup: any;
  responsedirectstart: any;
}

// Define the initial state using that type
const initialState: authstate = {
  Email: "",
  password: "",
  response: "",
  responsesignup: "",
  responsedirectstart: "",
};

export const loginfortest = createAsyncThunk(
  "loginredux/loginfortest",
  async (payload: object, { dispatch }) => {
    dispatch(handleopenloader());
    return axios
      .post(
        `/authuser/loginuser`,
        payload
      )
      .then(function (response) {
        dispatch(handlecloseloader());
        return response;
      })
      .catch(function (e) {
        dispatch(handlecloseloader());

        const payloaddata = {
          messages: e?.response?.data?.msg || e?.msg,
          severity: "error",
        };
        dispatch(handlecloseloader());
        dispatch(handleopensnackbar(payloaddata));
      });
  }
);

export const signupfortest = createAsyncThunk(
  "loginredux/register",
  (payload: any, { dispatch }) => {
    dispatch(handleopenloader());
    return axios
      .post(`/registeruser`, payload)
      .then(function (response) {
        dispatch(handlecloseloader());
        return response;
      })
      .catch(function (e) {
        dispatch(handlecloseloader());

        const payloaddata = {
          messages: e?.response?.data?.msg || e?.msg,
          severity: "error",
        };
        dispatch(handleopensnackbar(payloaddata));
      });
  }
);

export const directstarttest = createAsyncThunk(
  "loginredux/directstarttest",
  (payload: any, { dispatch }) => {
    dispatch(handleopenloader());
    return axios
      .post(
        `/authuser/startform`,
        payload
      )
      .then(function (response) {
        dispatch(handlecloseloader());
        return response;
      })
      .catch(function (e) {
        dispatch(handlecloseloader());

        const payloaddata = {
          messages: e?.response?.data?.msg || e?.msg,
          severity: "error",
        };
        dispatch(handleopensnackbar(payloaddata));
      });
  }
);

export const LoginSlice = createSlice({
  name: "loginredux",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(loginfortest.pending, (state) => { });
    builder.addCase(loginfortest.fulfilled, (state: authstate, action) => {
      state.response = action.payload;
    });
    builder.addCase(loginfortest.rejected, (state) => { });
    builder.addCase(signupfortest.pending, (state) => { });
    builder.addCase(signupfortest.fulfilled, (state, action) => {
      state.responsesignup = action.payload;
    });
    builder.addCase(directstarttest.fulfilled, (state, action) => {
      state.responsedirectstart = action.payload;
    });
    builder.addCase(signupfortest.rejected, (state) => { });
  },
  reducers: {
    logout: (state: authstate) => {
      state.Email = "";
      state.password = "";
      localStorage.removeItem("userdetails");
    },
  },
});

export const { logout } = LoginSlice.actions;
export default LoginSlice.reducer;
