import DOMPurify from "dompurify";
import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { formatDateTime } from "../../../../utils/formatDates";

type propstype = {
  show: boolean;
  handleClose: () => void;
  title: string;
  description: string;
  preregistrationdatetimestatus?: boolean;
  fromtime?: string;
  totime?: string;
};

export default function DashboardModal(props: propstype) {
  const {
    show,
    handleClose,
    title,
    description,
    preregistrationdatetimestatus,
    fromtime,
    totime,
  } = props;

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(description),
          }}
        ></Modal.Body>
        {preregistrationdatetimestatus && (
          <Modal.Footer>
            <Alert
              style={{ padding: "4px 16px", width: "100%" }}
              variant={"primary"}
            >
              <div>
                <div>
                  <div>Registration open</div>
                  <div className="datentime">
                    {fromtime && formatDateTime(fromtime ?? "")}
                  </div>{" "}
                  <div className="datenjoinee">to</div>{" "}
                  <div className="datentime">
                    {totime && formatDateTime(totime ?? "")}
                  </div>{" "}
                </div>{" "}
              </div>
            </Alert>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}
