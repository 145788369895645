import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./Fullscreenmodal.css";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";

const InspectDevToolsModal = ({ isModalOpenResize }) => {
  return (
    <Modal
      show={isModalOpenResize}
      onHide={null}
      backdrop="static"
      disableEscapeKeyDown
      disableBackdropClick
    >
      <Modal.Body style={{ border: "1px solid red" }}>
        <div>
          <h2>Warning: looks like your inspect devtool is open</h2>
          <div>
                <img
                  src={toAbsoluteUrl("/media/DevToolsOpen.png")}
                  height={130}
                  alt="no face img"
                />
              </div>
          <p>
            Please make sure your inspect devtool is closed before proceeding
            for the test. Once this is done, the window will disappear, and you
            can proceed with the test. Kindly turn off the devtools.
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default InspectDevToolsModal;
