/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "./Test.css";
import { Params, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { fetchoverview, testexists } from "../../../Redux/TestPage/TestSlice";
import Googletranslate from "../../../_metronic/layout/components/header/header-menus/Googletranslate";
import { AiFillHome } from "react-icons/ai";
import { handlestartrecording } from "../../../Redux/ScreenRecord/ScreenRecord";
import { IoMdCamera, IoMdCheckmark, IoMdClose } from "react-icons/io";
import { MdScreenShare } from "react-icons/md";
import Termsandconditionmodal from "../Termsandcondition/TermsAndconditionmodal";
import AntiCheatTermsModal from "./modals/AntiCheatTermsModal";
import { handleopensnackbar } from "../../../Redux/Snackbarwithmessages/Snackbarwithmessages";
import Webcam from "react-webcam";
import { Helmet } from "react-helmet-async";
import { siteConfig } from "../../../config/siteConfig";
import { useIntl } from "react-intl";
import axios from "axios";
import ScreenWarn from "./modals/ScreenWarn";
import ShareEntireScreenModal from "./modals/ShareEntireScreenModal";
import CameraForPreRequsitiesModal from "./modals/CameraForPreRequsitiesModal";
import Camerafortest from "./Camerfortest/Camerafortest";
import { Checkbox, FormGroup, Modal, useMediaQuery } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  anitCheatPreRequisitiesCheckData,
  preRequisitiesDataCheckData,
} from "./pre-requisities-anitcheatdata";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { AntiCheatingEventsType, EventType } from "./Testwrapper";
import AgreeTerms from "./PrequsitiesComponents/AgreeTerms";
import ShareCamera from "./PrequsitiesComponents/ShareCamera";
import ShareScreen from "./PrequsitiesComponents/ShareScreen";
import OtherPermissions from "./PrequsitiesComponents/OtherPermissions";
import { HeadingsTypography } from "../../../utils/StyledTypographyHeadings";

export function OGPAdd({ testid, fetchedoverviewdata }: any) {
  const [ogData, setOgData] = React.useState({
    testname: "",
    orgname: "",
    assessmentlink: "",
    orglogo: "",
  });

  React.useEffect(() => {
    if (testid) {
      const assementUrl = `${siteConfig.siteurl}/testoverview/${testid}`;
      const logoUrl = `https://creator.mtesthub.com/public/${fetchedoverviewdata.organization_logo}`;
      setOgData({
        testname: fetchedoverviewdata.testname,
        orgname: fetchedoverviewdata.conductedby,
        assessmentlink: assementUrl,
        orglogo: logoUrl,
      });
    }
  }, [fetchedoverviewdata, testid]);

  return (
    <Helmet>
      <meta
        property="og:title"
        content={`${ogData?.testname} by ${ogData?.orgname}`}
      />
      <meta
        property="og:description"
        content={`Discover your next opportunity with ${ogData?.orgname}. Explore more about this position and how ${siteConfig.sitename} makes the hiring process seamless.`}
      />

      <meta property="og:url" content={`${ogData?.assessmentlink}`} />
      {/* always prerequiste url */}
      <meta property="og:image" content={`${ogData?.orglogo}`} />

      <meta property="og:site_name" content={`${siteConfig?.sitename}`} />
      <meta property="og:type" content="website" />
    </Helmet>
  );
}

export default function Prerequisites() {
  type fetchedoverviewtype = {
    conductedby: string;
    testname: string;
    testtype: string;
    timelimit: string;
    totalquestion: string;
  };
  const [nextvisiblity, setnextvisbility] = React.useState<number>(0);

  const [showTermsComp, setShowTermsComp] = React.useState(true);
  const [showCameraComp, setShowCameraComp] = React.useState(false);
  const [showScreenComp, setShowScreeComp] = React.useState(false);
  const [showOtherComp, setShowOtherComp] = React.useState(false);

  const intl = useIntl();

  const applicationMode = localStorage.getItem("application_mode");

  const MainLogoLight = "/media/logos/mtesthub-new-logo.svg";
  const MainLogoDark = "/media/logos/mtesthub-new-logo-dark.svg";

  // console.log("pre requisites");

  const [fetchedoverview, setfetchedoverview] = React.useState<
    fetchedoverviewtype | any
  >({});
  const { testid } = useParams();
  const dispatch = useAppDispatch();

  const fetchedexistsdata = useAppSelector(
    (state) => state?.tests?.fetchedexists
  );
  const fetchedoverviewdata = useAppSelector(
    (state) => state?.tests?.overviewdata
  );

  fetchedoverviewdata?.setting?.blockrightclick == "true" &&
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });

  if (fetchedoverviewdata?.setting?.blockinspect == "true") {
    document.onkeydown = function (e) {
      // disable F12 key

      if (e.key === "F12") {
        e.preventDefault();
      } else if (e.ctrlKey && e.shiftKey && e.key === "I") {
        // Prevent Ctrl+Shift+I
        return false;
      } else if (e.key === "Fn") {
        return false;
      }
    };
  }

  React.useEffect(() => {
    const payload = {
      testid: testid,
    };
    dispatch(testexists(payload));
  }, []);

  React.useEffect(() => {
    const payload = {
      testid: testid,
    };
    if (fetchedexistsdata?.success === true) {
      dispatch(fetchoverview(payload));
    } else if (fetchedexistsdata?.success === false) {
      window.location.href = "/home/dashboard";
    }
  }, [fetchedexistsdata, dispatch, testid]);

  React.useEffect(() => {
    if (fetchedoverviewdata?.success === false) {
      window.location.href = `/testoverview/${testid}`;
    }
  }, [fetchedoverviewdata, testid]);



  React.useEffect(() => {
    setfetchedoverview(fetchedoverviewdata);
  }, [fetchedoverviewdata]);

  const navigate = useNavigate();

  function askNotificationPermission(type: any) {
    // Check if the browser supports notifications
    if (!("Notification" in window)) {
      console.log("This browser does not support notifications.");
      return;
    }
    Notification.requestPermission().then((permission) => {
      // set the button to shown or hidden, depending on what the user answers
      if (permission !== "granted" && !type) {
        const datapayload = {
          messages:
            "You might miss out important notifications during test sessions, please dont use incognito mode for better experience",
          severity: "error",
        };
        dispatch(handleopensnackbar(datapayload));
        askNotificationPermission("second");
      }
    });
  }

  React.useEffect(() => {
    askNotificationPermission(null);
  }, [askNotificationPermission]);

  const [Recorder, setRecorder] = React.useState<any>("");

  const [isModalOpenScreen, setIsModalOpenScreen] = React.useState(false);

  const [screenShareLoading, setScreenShareLoading] =
    React.useState<boolean>(false);

  const [displayEntireScreenModal, setDisplayEntireScreenModal] =
    React.useState<boolean>(false);

  // const [showCameraModal, setShowCameraModal] = React.useState<boolean>(false);

  // const [userFacesDetected, setUserFacesDetected] = React.useState(0);

  // const [initialCameraPermission, setInitialCameraPermission] =
  //   React.useState(false);

  // const showCameraError = React.useRef(false);

  const openmodalForScreen = () => {
    setIsModalOpenScreen(true);
  };

  const handleStartRecording = async () => {
    try {
      // Get screen stream

      let screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: {
          //@ts-ignore
          displaySurface: "monitor",
          width: { ideal: 960 },
          height: { ideal: 540 },
        },
      });

      // Check if the user has an audio input device
      const devices = await navigator.mediaDevices.enumerateDevices();
      const hasAudioDevice = devices.some(
        (device) => device.kind === "audioinput"
      );

      let audioStream = null;

      // Get audio stream from microphone if available
      if (hasAudioDevice) {
        //@ts-ignore
        audioStream = await navigator?.mediaDevices?.getUserMedia({
          audio: true,
        });
      }

      // Combine the screen and audio streams
      let combinedStream = new MediaStream();
      [screenStream, audioStream].forEach((stream) => {
        //@ts-ignore
        if (stream && stream.getTracks) {
          stream.getTracks().forEach((track) => combinedStream.addTrack(track));
        }
      });

      let recorder: any = new MediaRecorder(combinedStream);

      setRecorder(recorder);

      dispatch(handlestartrecording({ recordinstance: recorder }));

      setScreenShareLoading(true);

      let displaySurface = recorder.stream
        .getVideoTracks()[0]
        .getSettings().displaySurface;

      const label = recorder.stream.getVideoTracks()[0].label;

      if (displaySurface === "monitor" || label === "Primary Monitor") {
        if(fetchedoverviewdata?.setting?.allowscreenrecording == "true"){
          const response = await axios.post(
            `/getexamdata/generate-filename/${testid}`
          );

          // console.log("response", response);
  
          if (response) {
            setIsModalOpenScreen(false);
          }
        }else{
          setIsModalOpenScreen(false);
        }

        return true;
      } else {
        setDisplayEntireScreenModal(true);

        // Stop the recording
        recorder.stop();

        // Release tracks from both streams
        if (screenStream && screenStream.getTracks) {
          screenStream.getTracks().forEach((track) => track.stop());
        }
        if (audioStream && audioStream.getTracks) {
          audioStream.getTracks().forEach((track) => track.stop());
        }

        setRecorder("");
        return false;
      }
    } catch (error) {
      console.error("Error accessing user media:", error);
    } finally {
      setScreenShareLoading(false);
    }
  };

  React.useEffect(() => {
    if (
      Recorder &&
      (fetchedoverviewdata?.setting?.allowscreenrecording == "true" ||
        fetchedoverviewdata?.setting?.live_proctoring_screen == "true")
    ) {
      const mediaStream = Recorder.stream;
      const tracks = mediaStream.getTracks();
      for (const track of tracks) {
        if (track.kind === "video") {
          track.addEventListener("ended", () => {
            console.log("Recording stopped by user");
            openmodalForScreen();
          });
          break;
        }
      }
    }
  }, [
    Recorder,
    fetchedoverviewdata?.setting?.allowscreenrecording,
    fetchedoverviewdata?.setting?.live_proctoring_screen,
  ]);

  const [termsagreed, settermsagreed] = React.useState({
    cameraterms: 0,
    screenterms: 0,
  });

  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);

  // const matches = useMediaQuery("(min-width:768px)");

  const [matches, setMatches] = React.useState<Boolean | null>(null);

  React.useEffect(() => {
    function isMobile() {
      const userAgent = navigator.userAgent.toLowerCase();
      return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    }
  
    if (isMobile()) {
      setMatches(true);
    } else {
      const desktopMediaQuery = window.matchMedia("(min-width: 1024px)");
  
      const handleMediaQueryChange = (e:any) => {
        setMatches(!e.matches);
      };
  
      setMatches(!desktopMediaQuery.matches);
  
      desktopMediaQuery.addEventListener("change", handleMediaQueryChange);
  
      return () => {
        desktopMediaQuery.removeEventListener("change", handleMediaQueryChange);
      };
    }
  }, []);

  const [showModal, setShowModal] = React.useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };
  const [showModal2, setShowModal2] = React.useState(false);

  const handleShowModal2 = () => {
    setShowModal2(true);
  };
  const [showModal3, setShowModal3] = React.useState(false);

  const handleShowModal3 = () => {
    setShowModal3(true);
  };

  const onchangetermshandler = (e: any, type: string) => {
    if (e.target.checked === true) {
      settermsagreed({ ...termsagreed, [type]: 1 });
    } else {
      settermsagreed({ ...termsagreed, [type]: 0 });
    }
  };

  function fullscreentogglehandler() {
    const fullscreenele: any = document.getElementById("forfullscreen");
    // if (fullscreenele.requestFullscreen) {
    fullscreenele.requestFullscreen();
    if (fullscreenele.webkitRequestFullscreen) {
      /* Safari */
      fullscreenele.webkitRequestFullscreen();
    } else if (fullscreenele.msRequestFullscreen) {
      /* IE11 */
      fullscreenele.msRequestFullscreen();
    }
  }

  const manageStyle =
    fetchedoverviewdata?.setting?.allowscreenrecording == "true" ||
    fetchedoverviewdata?.setting?.randomphotos == "true" ||
    fetchedoverviewdata?.isvideoanswer ||
    fetchedoverviewdata?.setting?.live_proctoring_screen == "true" ||
    fetchedoverviewdata?.setting?.live_proctoring_camera == "true";

  return (
    <>
      <OGPAdd testid={testid} fetchedoverviewdata={fetchedoverviewdata} />

      <Box
        component="img"
        src={toAbsoluteUrl(
          applicationMode === "dark" ? MainLogoDark : MainLogoLight
        )}
        alt="mtesthub-logo"
        sx={{
          width: { xs: 150, md: 250 },
          height: 60,
          position: "absolute",
          top: 2,
          left: { xs: 20, sm: 80 },
        }}
      />

      {fetchedoverviewdata?.isvideoanswer ||
        (fetchedoverviewdata?.setting &&
          fetchedoverviewdata?.setting?.anti_cheating == "true") ||
        fetchedoverviewdata?.setting?.live_proctoring_camera ||
        fetchedoverviewdata?.setting?.live_proctoring_screen ? (
        <Box sx={{ width: "100% !important" }} id="forfullscreen">
          <Box
            sx={{
              width: "100% !important",
              // height: "100vh !important",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              paddingY: manageStyle ? 2 : 2,
              paddingX: {xs: 2, md: 10},
              marginTop: { xs: 8, md: 8, xl: 12 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "flex-start",
                padding: 1,
                borderRadius: "10px",
                border: applicationMode === "light" ? "2px solid #0b3aff" : "2px solid #ffffff",
                boxShadow: "13px 16px 3px -5px rgba(11, 58, 255, 0.5)",
              }}
            >
              {!matches ? (
                <>
                  <HeadingsTypography
                    variant="h2"
                    sx={{
                      letterSpacing: "1px",
                      fontFamily: "Poppins, sans-serif",
                      mt: 2,
                      color: "#03045e",
                    }}
                  >
                    {showTermsComp
                      ? "Rules"
                      : showCameraComp
                        ? "Camera Access"
                        : showScreenComp
                          ? "Screen Sharing"
                          : showOtherComp ? "Allow Other Required Permissions" : ""}
                    &nbsp;for {fetchedoverviewdata?.testname} test
                  </HeadingsTypography>

                  {showTermsComp && (
                    <AgreeTerms
                      setShowTermsComp={setShowTermsComp}
                      setCameraComp={setShowCameraComp}
                      setScreenComp={setShowScreeComp}
                      setShowOtherComp={setShowOtherComp}
                      testid={testid}
                      fullscreentogglehandler={fullscreentogglehandler}
                    />
                  )}

                  {showCameraComp && (
                    <ShareCamera
                      setScreenComp={setShowScreeComp}
                      setCameraComp={setShowCameraComp}
                      setShowOtherComp={setShowOtherComp}
                      setShowTermsComp={setShowTermsComp}
                      testid={testid}
                      fullscreentogglehandler={fullscreentogglehandler}
                    />
                  )}

                  {showScreenComp && (
                    <ShareScreen
                      setScreenComp={setShowScreeComp}
                      setShowOtherComp={setShowOtherComp}
                      setShowTermsComp={setShowTermsComp}
                      testid={testid}
                      Recorder={Recorder}
                      handleStartRecording={handleStartRecording}
                      fullscreentogglehandler={fullscreentogglehandler}
                    />
                  )}

                  {
                    showOtherComp && (
                      <OtherPermissions
                        setShowOtherComp={setShowOtherComp}
                        setShowTermsComp={setShowTermsComp}
                        testid={testid}
                        fullscreentogglehandler={fullscreentogglehandler}
                      />
                    )
                  }
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: 2,
                    height: { xs: "40vh", lg: "20vh" },
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 800,
                      letterSpacing: "1px",
                      color: "red",
                      textAlign: "center",
                      // fontSize: { xs: 12, sm: 16 },
                    }}
                  >
                    This test ({fetchedoverviewdata?.testname}) is not
                    attemptable on mobile/tablet devices kindly access it from
                    desktop or laptop{" "}
                  </Typography>

                  <button
                    className="styled-btn"
                    onClick={() => {
                      navigate(`/home/dashboard`);
                    }}
                  >
                    Go To Dashboard
                  </button>
                </Box>
              )}
            </Box>
          </Box>

          <Termsandconditionmodal show={show} handleClose={handleClose} />
          {/* <AntiCheatTermsModal
            type={1}
            showModal={showModal}
            setShowModal={setShowModal}
          />
          <AntiCheatTermsModal
            type={2}
            showModal={showModal2}
            setShowModal={setShowModal2}
          />
          <AntiCheatTermsModal
            type={3}
            showModal={showModal3}
            setShowModal={setShowModal3}
          /> */}


            {(fetchedoverviewdata?.setting?.allowscreenrecording == "true" ||
              fetchedoverviewdata?.setting?.live_proctoring_screen == "true") && (
                <ScreenWarn
                  isModalOpenResize={isModalOpenScreen}
                  setIsModalOpenResize={setIsModalOpenScreen}
                  allowScreenShareFunc={handleStartRecording}
                  screenShareLoading={screenShareLoading}
                />
              )}

            {(fetchedoverviewdata?.setting?.allowscreenrecording == "true" ||
              fetchedoverviewdata?.setting?.live_proctoring_screen == "true") && (
                <ShareEntireScreenModal
                  isModalOpen={displayEntireScreenModal}
                  setIsModalOpen={setDisplayEntireScreenModal}
                  modalsRef={null}
                  handleShareScreen={handleStartRecording}
                />
              )}
        </Box>
      ) : null}
    </>
  );
}
