import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./Fullscreenmodal.css";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useAppDispatch } from "../../../../Redux/hooks";
import { handlechangepreview } from "../../../../Redux/CameraPreviewTrigger/CameraPreviewTrigger";

const CameraWarn = ({
  setIsModalOpenResize,
  isModalOpenResize,
  facesDetected,
}) => {
  const dispatch = useAppDispatch();

  return (
    <Modal
      show={isModalOpenResize}
      onHide={null}
      backdrop="static"
      disableEscapeKeyDown
      disableBackdropClick
    >
      <Modal.Body style={{ border: "1px solid red" }}>
        {facesDetected > 1.9 ? (
          <div>
            <h2>Warning: More than one face is being detected</h2>
            <>
              <div>
                <img
                  src={toAbsoluteUrl("/media/multiple-faces.jpg")}
                  height={130}
                  alt="multiple faces img"
                />
              </div>
              <p>
                Please ensure that only your face is clearly visible in the
                camera. If multiple faces are detected, the system will alert
                you, and you will not be able to proceed with the test.
              </p>
              <p>
                Make sure you are in a private space to avoid any interruptions.
                Once only your face is visible, this window will disappear, and
                you can proceed with the test. Kindly position yourself as shown
                in the image above.
              </p>
            </>
          </div>
        ) : (
          <div>
            <h2>Warning: Your face is not properly visible</h2>
            <>
              <div>
                <img
                  src={toAbsoluteUrl("/media/FaceVisiblity.png")}
                  height={130}
                  alt="no face img"
                />
              </div>
              <p>
                Please ensure that your face is clearly visible in the camera.
                Once this is done, the window will disappear, and you can
                proceed with the test. Kindly position yourself as shown in the
                image above.
              </p>
            </>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CameraWarn;
