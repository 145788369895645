import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import { useAppDispatch } from "../../../../../Redux/hooks";
import { handleopensnackbar } from "../../../../../Redux/Snackbarwithmessages/Snackbarwithmessages";
import axios from "axios";
import {
  handlecloseloader,
  handleopenloader,
} from "../../../../../Redux/Loader/Loader";
import styled from "@emotion/styled";

type Props = {
  open: boolean;
  onClose: () => void;
  questionId: number;
  type: number;
};

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
      borderRadius: '5px',
      border: '1px solid #03045e',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#03055e', // Default border color
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#03055e', // Border color on hover
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#03055e', // Border color on focus
    },
  }));

const ReportModal = (props: Props) => {
  const { questionId, type } = props;
  const [description, setDescription] = useState("");
  const [image, setImage] = useState<String | null>(null);
  const [imagename, setImagename] = useState<File | null>(null);

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDescription(event.target.value);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type.startsWith("image/")) {
      if (file.size > 0.5 * 1024 * 1024) {
        const datapayload = {
          messages: "Image size should be less than 500Kb",
          severity: "error",
        };
        dispatch(handleopensnackbar(datapayload));
        setImage(null);
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setImagename(file);
          console.log(
            "🚀 ~ file: ReportModal.tsx:47 ~ handleImageChange ~ reader.result:",
            reader.result
          );
          setImage(reader.result as string);
        };
      }
    } else {
      setImage(null);
    }
  };

  const dispatch = useAppDispatch();

  const reportQuestion = () => {
    dispatch(handleopenloader());

    axios
      .post("/getexamdata/reportquestion", {
        questionId,
        description,
        image,
        type,
      })
      .then((response) => {
        dispatch(handlecloseloader());

        if (response.data.success) {
          const datapayload = {
            messages: `${
              type === 1 ? "Question" : "Test"
            } reported successfully`,
            severity: "success",
          };

          props.onClose();
          setTimeout(() => {
            dispatch(handleopensnackbar(datapayload));
          }, 1000);
        } else {
          const datapayload = {
            messages: `${type === 1 ? "Question" : "Test"} reporting failed`,
            severity: "error",
          };
          props.onClose();

          setTimeout(() => {
            dispatch(handleopensnackbar(datapayload));
          }, 1000);
        }
      })
      .catch((error) => {
        dispatch(handlecloseloader());

        console.log(error, "error in report question");
      });
  };

  useEffect(() => {
    if (!props.open) {
      setDescription("");
      setImage(null);
      setImagename(null);
    }
  }, [props]);

  return (
    <Dialog open={props.open} fullWidth onClose={props.onClose}>
      <DialogTitle
        variant="h6"
        className="text-black m-2"
        sx={{ fontWeight: 700, letterSpacing: "1px" }}
      >
        Report this {type === 1 ? "question" : "test"}
      </DialogTitle>
      <DialogContent>
        <div className="m-2">
          <Typography variant="body1" className="text-black">
            Give reason for the report?{" "}
            <Typography component="span" sx={{ color: "red !important" }}>*</Typography>
          </Typography>
          <CustomTextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={description}
            onChange={handleDescriptionChange}
            required
            sx={{ my: 2}}
          />
        </div>
        <div className="m-2">
          <input
            accept="image/*"
            id="report-image-upload"
            type="file"
            onChange={handleImageChange}
            style={{ display: "none" }}
          />
          <label htmlFor="report-image-upload">
            <Button
              sx={{
                backgroundColor: "#03045e !important",
                color: "white !important",
                "&:hover": {
                  backgroundColor: "#262878 !important",
                  color: "white !important",
                },
              }}
              variant="contained"
              component="span"
              startIcon={<CloudUpload />}
            >
              {image ? "Change Image" : "Upload Image (optional)"}
            </Button>
          </label>
        </div>
        <div className="m-2">
          {imagename && (
            <Typography variant="body2" color="text.secondary">
              {imagename.name} ({(imagename.size / 1024 / 1024).toFixed(2)} MB)
            </Typography>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          sx={{
            border: "1px solid #03045e",
            color: "#000",
            textTransform: "initial",
            paddingX: 2,
            fontWeight: 700,
            letterSpacing: "1px",
            "&:hover": {
              backgroundColor: "#262878",
              color: "white",
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={reportQuestion}
          disabled={!description}
          sx={{
            border: "1px solid #03045e",
            color: "#000",
            textTransform: "initial",
            paddingX: 2,
            fontWeight: 700,
            letterSpacing: "1px",
            "&:hover": {
              backgroundColor: "#262878",
              color: "white",
            },
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { ReportModal };
