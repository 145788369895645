/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-nocheck
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import { sampledatafullpayloadstate } from "./Questionssampledata";
import * as DOMPurify from "dompurify";
import { Box, Checkbox, FormControlLabel, Radio, Tooltip } from "@mui/material";
import { useIntl } from "react-intl";
import "./questioncard.css";
import CKeditor from "../../../../../app/modules/auth/components/Ckeditor/Ckeditor";
import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css"; // Import the CodeMirror theme (e.g., material)
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/python/python.js";
import "codemirror/mode/php/php.js";
import "codemirror/mode/jsx/jsx.js";
import { programmingLanguages } from "./programingLanguageConstants";
import RecordingComponent from "./RecordComponent";
import { IconButton } from "@mui/material";
import { Report } from "@mui/icons-material";
import { ReportModal } from "../modal/ReportModal";
import CodeLanguageEnum from "./codingLanguages.enums";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { Tabs, Tab, Paper, Typography, TextareaAutosize } from "@mui/material";
import {
  handlecloseloader,
  handleopenloader,
} from "../../../../../Redux/Loader/Loader";
import styled from "@emotion/styled";
import {
  HeadingsTypography,
  StyledTypography,
} from "../../../../../utils/StyledTypographyHeadings";

var Scroll = require("react-scroll");
var Element = Scroll.Element;

type Props = {
  className: string;
  datawhole: {
    id: number;
    srno: string;
    question: string;
    option: string[];
    sortno: any | number;
    question_image?: any;
    question_type: number;
    marks: number;
    programming_lang: number;
  };
  isVideoUploading: boolean;
  changehandler: (e: any, name?: any, data?: any, type?: any) => void;
  changehandlercheckbox: (e: any) => void;
  changehandlerimage: (e: any) => void;
  changehandlervideo: (blobdata: Blob, sortno: number) => void;
  particularvalue: any;
  setparticularvalue: any;
  fileName?: string;
  isUploading?: boolean;
  fileType?: string;
};

const CustomRadio = styled(Radio)(({ theme }) => ({
  color: "#000",
  "&.Mui-checked": {
    color: "#03045e",
  },
}));

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "#000",
  "&.Mui-checked": {
    color: "#03045e",
  },
}));

export const Questioncardwidget = (props: Props) => {
  const [isCompilerRunning, setIsCompilerRunning] = useState(false);
  const [fullpayloadstate, setfullpayloadstate] = useState<any>([]);

  const allowedMimeTypes = process.env.REACT_APP_ALLOWED_MIME_TYPES_FOR_TEST
    ? process.env.REACT_APP_ALLOWED_MIME_TYPES_FOR_TEST.split(",")
    : []

    const fileInputRef = useRef(null);

    const isImageFile = (fileType) => {
      return fileType.startsWith("image/");
    };

    const handleButtonClick = () => {
      fileInputRef.current.click();
    };

  React.useEffect(() => {
    sampledatafullpayloadstate.push(fullpayloadstate);
  }, [fullpayloadstate]);

  //
  // after every 20 seconds save this to api fullpayloadstate with name and other details of exam and user
  const intl = useIntl();
  const [newQuestion, setNewQuestion] = useState<string>("");
  // console.log(props.datawhole.question_image)

  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    // fill in the blanks question type
    if (props.datawhole.question_type === 6) {
      const question = props.datawhole.question;
      const sortNo = props.datawhole.sortno;

      let newQuestion = question;
      let index = 0;

      while (newQuestion.includes("_ _ _ _ _")) {
        const inputHTML = `<input type="text" class="${sortNo}_questioninput question-input-fillups" data-index="${index}" />`;
        newQuestion = newQuestion.replace("_ _ _ _ _", inputHTML);
        index++;
      }

      setNewQuestion(newQuestion);

      // instead of setTimeout use document content loaded

      dispatch(handleopenloader);
      setTimeout(() => {
        const domElements = document.getElementsByClassName(
          `${sortNo}_questioninput`
        );

        const handleChange = (event: any) => {
          const inputOptions = document.getElementsByClassName(
            `${sortNo}_questioninput`
          );

          const inputElement = event.target; // Get the input element that triggered the change
          const elementIndex = parseInt(inputElement.dataset.index); // Get the index of the input element
          const newValue = event.target.value;

          if (inputOptions.length) {
            const elementString = sortNo.toString();

            // @ts-ignore
            props.setparticularvalue((prev: any) => {
              if (Object.keys(prev).includes(elementString)) {
                const existingValues = prev[elementString] || []; // Get existing values for the elementString
                const updatedValues = [...existingValues]; // Make a copy of existingValues

                if (newValue) {
                  // Update or add the value
                  updatedValues[elementIndex] = newValue;
                } else {
                  // Remove the value if the input is cleared
                  updatedValues[elementIndex] = "";
                }

                return {
                  ...prev,
                  [elementString]: updatedValues,
                };
              } else {
                return {
                  ...prev,
                  [elementString]: newValue ? [newValue] : [""],
                };
              }
            });
          }
        };

        // @ts-ignore
        for (const domElement of domElements) {
          domElement.addEventListener("blur", handleChange);
        }

        dispatch(handlecloseloader());
      }, 3000);
    }
  }, [props.datawhole.question_type]);

  const saveFile = async (blob: Blob) => {
    props.changehandlervideo(blob, props.datawhole.sortno);
  };

  const [stdin, setStdin] = useState("");

  const stdInChangeHandler = (e: any) => {
    setStdin(e.target.value);
  };

  const runcodeHandler = (code: any, language: number, questionId: number) => {
    setIsCompilerRunning(true);
    const compileUrl = `${process.env.REACT_APP_COMPILER_URL}/submissions/?base64_encoded=false&wait=true`;

    axios
      .post(
        compileUrl,
        {
          source_code: code?.source_code,
          language_id: language,
          stdin: stdin,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": `${process.env.REACT_APP_AUTHORIZATION}`,
          },
        }
      )
      .then((response) => response.data)
      .then((data) => {
        if (data.token) {
          setTimeout(() => {
            getCodeOutput(questionId, data.token);
          }, 800);
        } else {
          console.error(data);
        }
      })
      .catch((err) => {
        console.error(err, "error compiling code");
      });
  };

  const [compileError, setCompileError] = useState(null);

  const getCodeOutput = (questionId: number, token: string) => {
    const baseUrl = `${process.env.REACT_APP_COMPILER_URL}`;

    axios
      .get(
        `${baseUrl}/submissions/${token}?base64_encoded=false&fields=source_code,language_id,stdin,stdout,time,memory,stderr,status,expected_output`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": `${process.env.REACT_APP_AUTHORIZATION}`,
          },
        }
      )
      .then((response) => response.data)
      .then((data) => {
        setCompileError(null);
        if (data?.status?.id === 3) {
          setIsCompilerRunning(false);
          props.changehandler("", questionId, data, "codeapi");
        } else if ([1, 2].includes(data?.status?.id)) {
          if (token) {
            setTimeout(() => {
              getCodeOutput(questionId, token); //recursion, after adding wait=true this wont be required
            }, 2000);
          }
        } else {
          setIsCompilerRunning(false);
          props.changehandler("", questionId, data, "codeapi");
          setCompileError(data.status.description);
        }
        setValue(1);
      })
      .catch((err) => {
        console.error(err, "error fetching compiled code");
      });
  };

  const [openmodal, setopenmodal] = useState(false);
  const openReportModal = () => {
    setopenmodal(true);
  };
  const closeReportModal = () => {
    setopenmodal(false);
  };

  return (
    <Element
      name={`${props.datawhole.srno}-id`}
      className="element"
      id={`${props.datawhole.srno}-id`}
    >
      <Card
        id={`${props.datawhole.srno}- id`}
        sx={{
          minWidth: 275,
          marginTop: 2,
          border: "1px solid #0b3aff",
          borderRadius: "8px",
        }}
      >
        <CardContent>
          <HeadingsTypography
            variant="h6"
            sx={{ color: "#000" }}
            gutterBottom
            style={{ display: "flex" }}
          >
            {intl.formatMessage({ id: "TEST.QUESTION" })} {props.datawhole.srno}
            .
            <HeadingsTypography
              variant="h6"
              sx={{ position: "absolute", right: "50px", color: "#000" }}
            >
              {props.datawhole?.marks}{" "}
              {props.datawhole?.marks
                ? Number(props.datawhole?.marks) <= 1
                  ? "Mark"
                  : "Marks"
                : null}
            </HeadingsTypography>
          </HeadingsTypography>

          {props.datawhole.question_type != 6 ? (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(props?.datawhole?.question),
              }}
              className="question-card-title"
            >
              {/* {props?.datawhole?.question} */}
            </div>
          ) : (
            <div
              id={props.datawhole.sortno + "abcdef"}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(newQuestion),
              }}
              className="question-card-title"
            >
              {/* {props?.datawhole?.question} */}
            </div>
          )}
          <HeadingsTypography variant="h5">
            {/* {props?.datawhole?.question} */}
          </HeadingsTypography>
          {/* <Typography
            variant="subtitle2"
            style={{ marginTop: "8px" }}
            component="div"
          >
            Answer:
          </Typography> */}
          <StyledTypography sx={{ color: "#0b3aff" }}>
            {props.datawhole?.option && props.datawhole.question_type == 1 ? (
              props?.datawhole?.option.map((each: any, index: any) => {
                return (
                  <>
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <FormControlLabel
                        checked={
                          props.particularvalue[props.datawhole.sortno] ==
                            each.srno
                            ? true
                            : false
                        }
                        style={{
                          display: "inline-block",
                          marginRight: "0px",
                        }}
                        id={`${each.srno} `}
                        onChange={(e) => {
                          props.changehandler(e);
                        }}
                        name={props.datawhole.sortno}
                        value={each.srno}
                        control={<CustomRadio />}
                        label=""
                      />
                      <div
                        className="optionparentdiv"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(each?.option),
                        }}
                      />
                    </Box>
                  </>
                );
              })
            ) : props.datawhole.question_type == 2 &&
              props.datawhole?.option ? (
              props?.datawhole?.option.map((each: any, index: any) => {
                return (
                  <>
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <FormControlLabel
                        checked={
                          props?.particularvalue[props?.datawhole?.sortno]?.[
                            each?.srno
                          ] == 1
                            ? true
                            : false
                        }
                        style={{
                          display: "inline-block",
                          marginRight: "0px",
                        }}
                        id={`${each.srno} `}
                        onChange={(e) => {
                          props.changehandlercheckbox(e);
                        }}
                        name={props.datawhole.sortno}
                        value={each.srno}
                        control={<CustomCheckbox />}
                        label=""
                      />
                      <div
                        className="optionparentdiv"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(each?.option),
                        }}
                      />
                    </Box>
                  </>
                );
              })
            ) : props.datawhole.question_type == 3 ? (
              <>
                <input
                  type="text"
                  // defaultValue={}
                  value={props.particularvalue[props?.datawhole?.sortno]}
                  name={props?.datawhole?.sortno}
                  onChange={(e) => {
                    props.changehandler(e);
                  }}
                  style={{
                    width: "100%",
                    minHeight: "60px",
                    maxHeight: "40vh",
                    fontSize: "14px",
                  }}
                />
              </>
            ) : props.datawhole.question_type == 5 ? (
              // <>
              //   <input
              //     type="file"
              //     accept={allowedMimeTypes.join(",")} // Dynamically set the allowed mime types from the env variable
              //     name={props?.datawhole?.sortno}
              //     onChange={props.changehandlerimage}
              //     style={{
              //       maxWidth: "66vw",
              //       minHeight: "60px",
              //       maxHeight: "40vh",
              //       fontSize: "14px",
              //     }}
              //     disabled={props.isUploading} 
              //   />

              //   {props.fileName && (
              //     <div>
              //       <p>Uploaded File: {props.fileName ?? ""}</p>
              //     </div>
              //   )}
              //   {props.particularvalue[props?.datawhole?.sortno] && (
              //     <div style={{ maxWidth: "66vw" }}>
              //       <img
              //         style={{ maxWidth: "66vw" }}
              //         src={props.particularvalue[props?.datawhole?.sortno]}
              //         alt=""
              //       />
              //     </div>
              //   )}
              // </>
              <div>
                {/* File Input - Hidden File Input Button */}
                <input
                  type="file"
                  ref={fileInputRef}
                  accept={allowedMimeTypes.join(",")} // Dynamically set the allowed mime types from the env variable
                  name={props?.datawhole?.sortno}
                  onChange={props.changehandlerimage}
                  style={{
                    display: "none", // Hide the default file input
                  }}
                  disabled={props.isUploading}
                />

                {/* Styled Button that triggers file selection */}
                <label htmlFor={props?.datawhole?.sortno}>
                  <Button
                    component="span"
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#1f219c",
                      color: "white",
                      width: "auto",
                      padding: "8px 20px",
                      textTransform: "none",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    disabled={props.isUploading}
                    onClick={handleButtonClick} 
                  >
                    {props.isUploading ? (
                      <CircularProgress size={24} style={{ color: "white" }} />
                    ) :
                    <StyledTypography variant="h6" sx={{ color: "#ffff" }}>Upload File</StyledTypography>
                    }
                  </Button>
                </label>

                {/* Show the file name after upload */}
                {props.fileName && (
                  <Box sx={{ my: 2 }}>
                    <StyledTypography variant="h6">Uploaded File: {props.fileName ?? ""}</StyledTypography>
                  </Box>
                )}

                {/* Show Image Preview if file is selected */}
                {props.particularvalue[props?.datawhole?.sortno] && isImageFile(props.fileType) && (
                  <div style={{ maxWidth: "66vw" }}>
                    <img
                      style={{ maxWidth: "66vw", height: "auto" }}
                      src={props.particularvalue[props?.datawhole?.sortno]}
                      alt="Uploaded File Preview"
                    />
                  </div>
                )}
              </div>
            ) : props.datawhole.question_type == 4 ? (
              <>
                <CKeditor
                  value={props.particularvalue[props?.datawhole?.sortno]}
                  name={props?.datawhole?.sortno}
                  onchange={props.changehandler}
                  disablestate={false}
                />
              </>
            ) : props.datawhole.question_type == 7 ||
              props.datawhole.question_type == 9 ? (
              <>
                <div>
                  <RecordingComponent
                    value={props.particularvalue[props.datawhole.sortno]}
                    isVideoUploading={props?.isVideoUploading}
                    saveFile={saveFile}
                  />
                </div>
              </>
            ) : (
              props.datawhole.question_type == 8 && (
                <>
                  <CodeMirror
                    value={
                      props.particularvalue[props.datawhole.sortno]?.source_code
                    }
                    options={{
                      mode: `${CodeLanguageEnum[props?.datawhole?.programming_lang]
                        }`,
                      theme: "material",
                      lineNumbers: true,
                      autofocus: true,
                    }}
                    onBeforeChange={(editor, data, value) => {
                      props.changehandler(
                        editor,
                        props?.datawhole?.sortno,
                        value,
                        "codemirror"
                      );
                    }}
                  />

                  <div
                    className="mt-2 mb-4"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Button
                      className="btn2"
                      disabled={isCompilerRunning}
                      sx={{
                        padding: "10px !important",
                        // fontSize: "12px !important",
                        textTransform: "capitalize !important",
                      }}
                      onClick={() => {
                        runcodeHandler(
                          props.particularvalue[props.datawhole.sortno],
                          props?.datawhole?.programming_lang,
                          props?.datawhole?.sortno
                        );
                      }}
                    >
                      {" "}
                      {isCompilerRunning ? (
                        <CircularProgress size={20} sx={{ color: "#ffff" }} />
                      ) : (
                        <StyledTypography variant="h6" sx={{ color: "#ffff" }}>Save and Run</StyledTypography>
                      )}
                    </Button>

                    {compileError ? (
                      <span style={{ color: "red", marginLeft: "6px" }}>
                        {compileError}
                      </span>
                    ) : null}
                  </div>

                  <div>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                      >
                        <Tab label="Input" />
                        <Tab label="Output" />
                      </Tabs>
                      <Paper elevation={3} style={{ padding: "16px" }}>
                        {value === 0 && (
                          <TextareaAutosize
                            aria-label="input textarea"
                            onChange={stdInChangeHandler}
                            value={stdin}
                            placeholder="Enter input(s) if any..."
                            style={{
                              width: "100%",
                              height: "100px",
                              border: "none",
                            }}
                          />
                        )}
                        {value === 1 && (
                          <div
                            className="mt-2"
                            style={{
                              width: "100%",
                              minHeight: "100px",
                              maxHeight: "300px",
                            }}
                          >
                            {props.particularvalue[props.datawhole.sortno]
                              ?.time ? (
                              props.particularvalue[props.datawhole.sortno]
                                ?.stdout ? (
                                <pre
                                  style={{
                                    whiteSpace: "pre-wrap",
                                    wordWrap: "break-word",
                                    marginBottom: "0",
                                    fontFamily: "monospace",
                                  }}
                                >
                                  {
                                    props.particularvalue[
                                      props.datawhole.sortno
                                    ]?.stdout
                                  }
                                </pre>
                              ) : (
                                props.particularvalue[props.datawhole.sortno]
                                  ?.stderr && (
                                  <pre
                                    style={{
                                      whiteSpace: "pre-wrap",
                                      wordWrap: "break-word",
                                      marginBottom: "0",
                                      fontFamily: "monospace",
                                      color: "red",
                                    }}
                                  >
                                    {
                                      props.particularvalue[
                                        props.datawhole.sortno
                                      ]?.stderr
                                    }
                                  </pre>
                                )
                              )
                            ) : null}
                          </div>
                        )}
                      </Paper>
                      <div className="mt-4">
                        {props.particularvalue[props.datawhole.sortno]?.time ? (
                          <p style={{ marginBottom: "2px" }}>
                            Execution time:{" "}
                            <b>
                              {
                                props.particularvalue[props.datawhole.sortno]
                                  ?.time
                              }{" "}
                            </b>{" "}
                            seconds
                          </p>
                        ) : null}
                        {props.particularvalue[props.datawhole.sortno]
                          ?.memory ? (
                          <p>
                            Execution memory:{" "}
                            <b>
                              {" "}
                              {
                                props.particularvalue[props.datawhole.sortno]
                                  ?.memory
                              }
                            </b>{" "}
                            bytes
                          </p>
                        ) : null}
                      </div>
                    </Box>
                  </div>
                </>
              )
            )}
          </StyledTypography>

          <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Tooltip title="Report this question if you find anything wrong or inappropriate with it">
              <IconButton
                aria-label="report question"
                sx={{ fontSize: 12, marginLeft: "-10px", marginTop: "2px" }}
                onClick={openReportModal}
              >
                <Report fontSize="medium" style={{ marginRight: "2px" }} />{" "}
                Report Question
              </IconButton>
            </Tooltip>
          </div>
        </CardContent>
      </Card>
      {openmodal && (
        <ReportModal
          type={1}
          questionId={props?.datawhole?.id}
          open={openmodal}
          onClose={closeReportModal}
        />
      )}
    </Element>
  );
};
