import React from "react";
import { Button } from "react-bootstrap";
import Webcam from "react-webcam";
import { CameraOptions, useFaceDetection } from "react-use-face-detection";
import FaceDetection from "@mediapipe/face_detection";
import { Camera } from "@mediapipe/camera_utils";
import { Typography } from "@mui/material";

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};

const WebcamCapture = (props) => {
  // for face detection if any or if multiples
  const { webcamRef, boundingBox, isLoading, detected, facesDetected } =
    useFaceDetection({
      faceDetectionOptions: {
        model: "short",
      },
      faceDetection: new FaceDetection.FaceDetection({
        locateFile: (file) =>
          `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
      }),
      camera: ({ mediaSrc, onFrame, width, height }) =>
        new Camera(mediaSrc, {
          onFrame,
          width,
          height,
        }),
    });
  //end of it

  const { responsedata, setresponsedata } = props;

  return (
    <>
      {/* <p>{`Loading: ${isLoading}`}</p>
      <p>{`Face Detected: ${detected}`}</p>
      <p>{`Number of faces detected: ${facesDetected}`}</p> */}

      <div>
        {responsedata.userimage && (
          <>
            <p> Preview:</p>
            <img src={responsedata.userimage} alt="preview here" />
          </>
        )}
      </div>

      <div style={{ width: "100%", height: "400px", position: "relative" }}>
        {boundingBox.map((box, index) => (
          <div
            key={`${index + 1}`}
            style={{
              border: "4px solid red",
              position: "absolute",
              top: `${box.yCenter * 100}%`,
              left: `${box.xCenter * 100}%`,
              width: `${box.width * 100}%`,
              height: `${box.height * 70}%`,
              zIndex: 1,
            }}
          />
        ))}

        <Webcam
          ref={webcamRef}
          audio={false}
          height={300}
          screenshotFormat="image/jpeg"
          width={460}
          videoConstraints={videoConstraints}
        >
          {({ getScreenshot }) => (
            <>
              {facesDetected === 1 && (
                <Button
                  name="userimage"
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                  onClick={() => {
                    const imageSrc = getScreenshot();
                    setresponsedata({ ...responsedata, userimage: imageSrc });
                  }}
                >
                  Capture photo
                </Button>
              )}
              {facesDetected < 1 || facesDetected > 1 ? (
                <Typography
                  sx={{
                    mb: 1.5,
                    display: "flex",
                    justifyContent: "center",
                    color: "red",
                  }}
                  color="text.secondary"
                >
                  Note: make sure face is visible clearly and only you are in
                  front of camera
                </Typography>
              ) : (
                ""
              )}
            </>
          )}
        </Webcam>
      </div>
    </>
  );
};

export default WebcamCapture;
