import { IconButton } from "@mui/material";
import axios from "axios";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useAppDispatch } from "../../../../../../Redux/hooks";
import {
  handlecloseloader,
  handleopenloader,
} from "../../../../../../Redux/Loader/Loader";
import { handleopensnackbar } from "../../../../../../Redux/Snackbarwithmessages/Snackbarwithmessages";

function Modalpre(props: any) {
  const { show, handleClose, title, id } = props;

  const [fetcheddata, setfetcheddata] = useState<any>();
  useEffect(() => {

    axios
      .get(`/dashboard/viewpreregisteredform/${id}`)
      .then((res) => {
        setfetcheddata(res?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const inputchangehandler = (e: any, data?: string) => {
    if (data == "checkbox") {
      if (e.target.checked == true) {

        setfetcheddata({
          ...fetcheddata,
          userpreregisterform: {
            ...fetcheddata.userpreregisterform,
            [e.target.name]: {
              ...fetcheddata.userpreregisterform[e.target.name],
              [e.target.value]: 1,
            },
          },
        });
      } else if (e.target.checked == false) {
        setfetcheddata({
          ...fetcheddata,
          userpreregisterform: {
            ...fetcheddata.userpreregisterform,
            [e.target.name]: {
              ...fetcheddata.userpreregisterform[e.target.name],
              [e.target.value]: 0,
            },
          },
        });
      }
    } else {
      setfetcheddata({
        ...fetcheddata,
        userpreregisterform: {
          ...fetcheddata.userpreregisterform,
          [e.target.name]: e.target.value,
        },
      });
    }
  };
  const dispatch = useAppDispatch();

  const submithandler = () => {
    const payload = {
      candidatedata: fetcheddata.userpreregisterform,
    };
    dispatch(handleopenloader());
    axios
      .post(`/preregister/updatepreregister/${id}`, payload)
      .then((res) => {

        const payloaddata = {
          messages: "successfully updated form",
          severity: "success",
        };

        dispatch(handleopensnackbar(payloaddata));
        dispatch(handlecloseloader());
      })
      .catch((err) => {

        const payloaddata = {
          messages: "error while updating",
          severity: "error",
        };

        dispatch(handleopensnackbar(payloaddata));
        dispatch(handlecloseloader());
      });
  };


  return (
    <>
      <Modal show={show} fullscreen onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {fetcheddata?.preregisterform?.map((each: any) => {
            return (
              <div className="fv-row mb-8">
                <label className="form-label fw-bolder text-dark fs-6">
                  {each?.titlevalue}
                  {/* <span style={{ color: 'red' }}>*</span> */}
                </label>
                {!each?.options &&
                  !each?.cameraisrequired &&
                  each?.typevalue != "textarea" && (
                    <input
                      placeholder={each?.titlevalue}
                      type={each?.typevalue}
                      value={fetcheddata?.userpreregisterform[each?.namevalue]}
                      name={each?.namevalue}
                      onChange={inputchangehandler}
                      autoComplete="off"
                      className={clsx("form-control bg-transparent")}
                    />
                  )}
                {!each?.options &&
                  !each?.cameraisrequired &&
                  each?.typevalue == "textarea" && (
                    <textarea
                      placeholder={each?.titlevalue}
                      value={fetcheddata?.userpreregisterform[each?.namevalue]}
                      name={each?.namevalue}
                      onChange={(e) => {
                        inputchangehandler(e);
                      }}
                      autoComplete="off"
                      className={clsx("form-control bg-transparent")}
                    />
                  )}
                {each.typevalue == "checkbox" &&
                  each?.options?.map((eachdata: string) => {

                    return (
                      <>
                        <div style={{ display: "flex" }}>
                          <input
                            onChange={(e) => {
                              inputchangehandler(e, "checkbox");
                            }}
                            type={each?.typevalue}
                            style={{
                              display: "inline-block",
                              marginRight: "10px",
                            }}
                            id={`${eachdata}`}
                            name={each?.namevalue}
                            defaultChecked={
                              fetcheddata?.userpreregisterform[each?.namevalue][
                                eachdata
                              ] == 1
                                ? true
                                : false
                            }
                            value={eachdata}
                          />
                          <label htmlFor={eachdata}>{eachdata}</label>
                        </div>
                      </>
                    );
                  })}
                {each.typevalue == "radio" &&
                  each?.options?.map((eachdata: string) => {

                    return (
                      <>
                        <div style={{ display: "flex" }}>
                          <input
                            onChange={inputchangehandler}
                            type={each?.typevalue}
                            style={{
                              display: "inline-block",
                              marginRight: "10px",
                            }}
                            id={`${eachdata}`}
                            name={each?.namevalue}
                            checked={
                              fetcheddata?.userpreregisterform[
                                each?.namevalue
                              ] == eachdata
                                ? true
                                : false
                            }
                            value={eachdata}
                          />
                          <label htmlFor={eachdata}>{eachdata}</label>
                        </div>
                      </>
                    );
                  })}

                {each?.cameraisrequired && (
                  <div className="fv-row mb-5">
                    <label className="form-label fw-bolder text-dark fs-6">
                      Uploaded Image{" "}
                    </label>
                    <img
                      style={{ marginLeft: "20px" }}
                      height={240}
                      src={fetcheddata?.userpreregisterform?.userimage}
                      alt="uploaded image here"
                    />
                  </div>
                )}
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={submithandler}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Modalpre;
