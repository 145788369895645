import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import {
  handlecloseloader,
  handleopenloader,
} from "../../../../Redux/Loader/Loader";
import { Questioncardwidgetforallans } from "../../../../_metronic/partials/widgets/_new/questioncard/Questioncardwidgetforallans";
import "./Modalfortest.css";

type Props = {
  show: boolean;
  handleClose: () => void;
  testtakersessionid: number;
  title: String;
  testid: String;
  remarks: number;
  score: number;
  question_sort_no: any;
  view_remarks_description: boolean;
};

const Modalfortestreview = (props: Props) => {
  const {
    show,
    handleClose,
    testtakersessionid,
    title,
    testid,
    remarks,
    score,
    question_sort_no,
    view_remarks_description
  } = props;

  const [data, setdata] = useState<any>({});
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(handleopenloader());
    axios
      .get(`/dashboard/viewsubmission/${testtakersessionid}`)
      .then((res) => {
        setdata(res?.data);
        dispatch(handlecloseloader());
      })
      .catch((err) => {
        dispatch(handlecloseloader());
        console.error(err);
      });
  }, []);



  return (
    <>
      <Modal fullscreen show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
          <div
            style={{ position: "absolute", right: "80px", marginTop: "10px" }}
            className="parentdivheader"
          >
            <p
              style={{ marginRight: "-20px", fontSize: "14px" }}
              className="ptag"
            >
              <span style={{ fontWeight: "bold" }}>
                Total Marks:{" "}
              </span>
              {score}
            </p>

            {view_remarks_description&& remarks ? (
              remarks === 1 ? (
                <p className="bottomfootertext">
                  <span style={{ fontWeight: "bold" }}>Remarks:</span>
                  <span style={{ color: "green" }}> Passed </span>
                </p>
              ) : (
                <p className="bottomfootertext">
                  <span style={{ fontWeight: "bold" }}>Remarks:</span>
                  <span style={{ color: "red" }}> Failed </span>
                </p>
              )
            ) : null}
          </div>
        </Modal.Header>
        <Modal.Body style={{ width: "100vw !important" }}>
          {data?.result?.map((each: any) => {
            return (
              <>
                <Questioncardwidgetforallans
                  correct_question_sort_no={question_sort_no}
                  sortno={each?.sort_no}
                  question={each?.question}
                  srno={each?.srno}
                  option={each?.option}
                  user_answered={each?.user_answered_value}
                  correct_answer={each?.correct_answer}
                  iscorrect={each?.iscorrect}
                  question_type={each?.question_type}
                  question_image={each?.question_image}
                  answer_explanation={each?.answer_explanation}
                  marks={each?.marks}
                  mark_obtained={each?.mark_obtained}
                  ai_interview_result={each?.ai_interview_result}
                />
              </>
            );
          })}

          <Modal.Footer className="modalfooterparent">
            <p style={{ marginRight: "-20px", fontSize: "14px" }}>
              <span style={{ fontWeight: "bold" }}>
                Total Marks:{" "}
              </span>
              {score}
            </p>

            {view_remarks_description && remarks ? (
              remarks === 1 ? (
                <p className="bottomfootertext">
                  <span style={{ fontWeight: "bold" }}>Remarks:</span>
                  <span style={{ color: "green" }}> Passed </span>
                </p>
              ) : (
                <p className="bottomfootertext">
                  <span style={{ fontWeight: "bold" }}>Remarks:</span>
                  <span style={{ color: "red" }}> Failed </span>
                </p>
              )
            ) : null}
          </Modal.Footer>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Modalfortestreview;
