/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios, { AxiosError, AxiosResponse } from "axios";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import { Questioncardwidget } from "../../../_metronic/partials/widgets";
import {
  answeredvalues,
  fetchdata,
  fetchdatapreview,
  fetchoverview,
} from "../../../Redux/TestPage/TestSlice";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { Online, Offline } from "react-detect-offline";
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Box, Button, CircularProgress, Skeleton } from "@mui/material";
import "./Test.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SubmitModal from "./SubmitModal";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { handleopensnackbar } from "../../../Redux/Snackbarwithmessages/Snackbarwithmessages";
import {
  handlecloseloader,
  handleopenloader,
} from "../../../Redux/Loader/Loader";
import { CameraModal } from "../../modules/auth/components/CameraModal";
import WebcamCapture from "../../modules/auth/components/MainCamera";
import Camerafortest from "./Camerfortest/Camerafortest";
import { handlelocationpermission } from "../../../Redux/Checkpermissions/Permission";
import Googletranslate from "../../../_metronic/layout/components/header/header-menus/Googletranslate";
import { Player } from "video-react";
import { handlestartrecording, handlestoprecording } from "../../../Redux/ScreenRecord/ScreenRecord";
import FullscreenModal from "./modals/FullscreenModal";
import WindowSwitchModal from "./modals/WindowSwitchModal";
import Peer from "peerjs";
import { io, Socket } from "socket.io-client";
import useConsoleLog from "../../../hooks/useConsoleLog";
import KickedUserModal from "./modals/KickedUserModal";
import CryptoJS from 'crypto-js';
import ScreenWarn from "./modals/ScreenWarn";
import CameraWarn from "./modals/CameraWarn";
import { OGPAdd } from "./Prerequisites";
import { useDevToolsStatus } from "./useDevToolsStatus";
import InspectDevToolsModal from "./modals/InspectDevToolsModal";
import ShareEntireScreenModal from "./modals/ShareEntireScreenModal";
import { cleanObject } from "./clearnAnswerObjects";
import Slide from '@mui/material/Slide';
import useNetworkStatus from "../../../utils/CustomHooks/useNetworkStatus";
import InternetTurnOffModal from "./modals/InternetTurnOffModal";
import formatFileSize from "../../../utils/fileFormatSize";

var Scroll = require("react-scroll");
var scroller = Scroll.scroller;
type Props = {};

interface LogsPayload {
  data: any;
  cryptvalue: string | undefined;
  type?: string;
}

export enum EventType {
  Inspect = "inspect",
  Faces = "faces",
  Screen = "screen",
  Camera = "camera",
  FullScreen = "full_screen",
  TabSwitch = "tab_switch",
}

export type AntiCheatingEventsType = {
  eventType?: EventType;
  test_assessment_id: string;
  multiple_faces?: boolean;
  no_faces?: boolean;
  end?: boolean;
  start?: boolean;
}

const options = {
  icon: toAbsoluteUrl("/media/logos/mTestHubIconLogo.png"),
};

export const encryptData = (data: any) => {
  // @ts-ignore
  return CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_CRYPTVALUE).toString();
};
export const decryptData = (encryptedData: any) => {
  // @ts-ignore
  const bytes = CryptoJS.AES.decrypt(encryptedData, process.env.REACT_APP_CRYPTVALUE);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

const calculateChecksum = (data: any) => {
  return CryptoJS.SHA256(data).toString(CryptoJS.enc.Hex);
};

const cache = new Map();

// export const antiCheatingEvents = async (data: AntiCheatingEventsType) => {
//   const cacheKey = JSON.stringify(data);

//   if (cache.has(cacheKey)) {
//     return cache.get(cacheKey);
//   }

//   try {
//     await axios.post(`getexamdata/anticheatingevents`, data);
//     const result = { submission: 'completed' };
//     cache.set(cacheKey, result);
//   } catch (error) {
//     console.error('Error occured while sending anticheating events:', error);
//   }
// }


export const antiCheatingEvents = async (data: AntiCheatingEventsType) => {
  const cacheKey = JSON.stringify(data);

  // Define a helper function to remove cached items based on conditions
  const removeFromCache = (eventType: any, flag: any) => {
    for (let [key, value] of Array.from(cache.entries())) {
      const cachedData = JSON.parse(key);
      if (cachedData.eventType === eventType && cachedData[flag]) {
        cache.delete(key);
      }
    }
  };

  // Check conditions for `start` or `end` in `data`
  if (data.end) {
    removeFromCache(data.eventType, 'start');
  } else if (data.start) {
    removeFromCache(data.eventType, 'end');
  }

  if (cache.has(cacheKey)) {
    return cache.get(cacheKey);
  }

  try {
    // Perform the API call
    await axios.post(`getexamdata/anticheatingevents`, data);
    const result = { submission: 'completed' };
    cache.set(cacheKey, result);
  } catch (error) {
    console.error('Error occurred while sending anti-cheating events:', error);
  }
};

const Testpage = (props: Props) => {
  const { id } = useParams();
  const { tokenid } = useParams();

  const [particularvalue, setparticularvalue] = React.useState<any>({});
  const [savetimer, setsavetimer] = React.useState<number>(0);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [autorecoverydata, setautorecoverydata] = useState<any>([]);
  const [dataforrecover, setdataforrecover] = useState<any>([]);
  // const [geolocationdata, setgeolocationdata] = useState<any>({});
  const geolocationdata = React.useRef<any>({
    geolocationlongitude: 0,
    geolocationlatitude: 0,
    accuracy: 0,
  })

  const [peerCallInstance, setPeerCallInstance] = useState<any>(null);
  const [socketinstance, setsocketinstance] = useState<any>(null);
  const [socketinstance2, setsocketinstance2] = useState<any>(null);
  const [peerInstance, setPeerInstance] = useState<any>(null);
  const [userId, setUserid] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [chunkSize, setChunkSize] = useState(0);



  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenKick, setIsModalOpenKick] = useState(false);

  const [Recorder, setRecorder] = React.useState<any>("");

  const [screenShareLoading, setScreenShareLoading] = React.useState<boolean>(false);

  const [facesDetected, setFacesDetected] = React.useState<any>(0);

  const [openmodalForInspectDevTools, setIsModalOpenInspectDevTools] = useState(false);

  const [isModalOpenresize, setIsModalOpenresize] = useState(false);

  const [displayEntireScreenModal, setDisplayEntireScreenModal] = React.useState<boolean>(false);

  const isDevToolsOpen = useDevToolsStatus();

  const location = useLocation();

  const sequenceNumber = useRef<number>(1);

  const isSubmittingRef = useRef<boolean>(false);

  const [userStoppedScreenSharing, setUserStoppedScreenSharing] = useState(false);

  const [alertSnackBarOpen, setAlertSnackBarOpen] = React.useState(false);

  const timerComplete = useAppSelector((state) => state?.tests?.timerComplete);

  const [peerId, setPeerId] = useState("");

  let countRef = useRef(0);

  const { effectiveType, isOnline } = useNetworkStatus();

  const facesEndRef = useRef(0);

  const modalsRef = useRef<any>({
    camera_modal: 0,
    full_screen_modal: 0,
    inspect_modal: 0,
    screen_share_modal: 0,
    kick_user_modal: 0,
    switch_tab_modal: 0,
    share_entire_screen_modal: 0,
  });

  const uploadQueueRef = useRef<any[]>([]);
  const isProcessingRef = useRef(false);
  const totalDataSizeRef = useRef(0);

  const [fileName, setFileName] = useState<Record<string | number, string>>({});
  const [isUploading, setIsUploading] = useState(false);
  const [fileType, setFileType] = useState("");
  const [alertType, setAlertType] = useState<"error" | "info" | "success" | "warning" | null>(null);
  const [alertMessage, setAlertMessage] = useState("");


  const allowedMimeTypes = process.env.REACT_APP_ALLOWED_MIME_TYPES_FOR_TEST
    ? process.env.REACT_APP_ALLOWED_MIME_TYPES_FOR_TEST.split(",") // Split the string into an array
    : [];


  function incremenentScreeAndCameraCount(fieldName: string) {


    if (tokenid) {
      return null;
    }
    const API_URL = `/getexamdata/increment-count/${id}`;

    axios.post(API_URL, { field: fieldName })
      .then(response => {
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  const sendLog = (data: any, for_proctor?: boolean, test_taker_email?: string) => {
    if (tokenid) {
      return null;
    }

    let payload: LogsPayload = {
      data,
      cryptvalue: process.env.REACT_APP_CRYPTVALUELOG,
    };

    if (for_proctor) {
      payload = {
        ...payload,
        type: "live_proctor_logs",
      };
    }
    axios.post("/logger/insertlog", { ...payload }).then((res) => { });
  };


  const openmodalForWindowSwitch = () => {
    setIsModalOpen(true);
    modalsRef.current.switch_tab_modal = 1;
  };

  const [isModalOpenCamera, setIsModalOpenCamera] = useState(false);
  const openmodalForCamera = async (type?: string) => {
    setIsModalOpenCamera(true);
    modalsRef.current.camera_modal = 1;
    let data: AntiCheatingEventsType = {
      eventType: EventType.Faces,
      test_assessment_id: id ?? "",
      start: true,
    };
    console.log("facesDetected", facesDetected);
    if (facesDetected === 0) {
      data = {
        ...data,
        no_faces: true,
      }
    } else if (facesDetected > 1) {
      data = {
        ...data,
        multiple_faces: true,
      }
    }
    try {
      if ((data?.no_faces || data?.multiple_faces) && facesEndRef.current === 0) {
        await antiCheatingEvents(data);
        facesEndRef.current = 1;
      }
    } catch (error) {
      console.log("Error occured while sending anticheating events for camera:", error);
    }
    if (type === "closed") {
      // incremenentScreeAndCameraCount("camera")
    }

  };

  const closemodalForCamera = async () => {
    setIsModalOpenCamera(false);
    modalsRef.current.camera_modal = 0;
    const data = {
      eventType: EventType.Faces,
      test_assessment_id: id ?? "",
      end: true,
    };
    try {
      await antiCheatingEvents(data);
      facesEndRef.current = 0;
    } catch (error) {
      console.log("Error occured while sending anticheating events for camera:", error);
    }
  };

  const [isModalOpenScreen, setIsModalOpenScreen] = useState(false);

  const openmodalForScreen = async () => {
    setIsModalOpenScreen(true);
    modalsRef.current.screen_share_modal = 1;
    // incremenentScreeAndCameraCount("screen")
    const data = {
      eventType: EventType.Screen,
      test_assessment_id: id ?? "",
      end: true,
    };
    try {
      await antiCheatingEvents(data);
    } catch (error) {
      console.log("Error occured while sending anticheating events for screen:", error);
    }
  };

  const currentusertoken = JSON.parse(
    localStorage.getItem("userdetails") as string
  );
  const sortnofetched = useAppSelector((state) => state.tests.fetchedsortno);
  if (sortnofetched && sortnofetched.length > 0) {
    const data = encryptData(sortnofetched);
    sessionStorage.setItem("sortnofetched_session", data);
  }
  const fetchedoverviewdata = useAppSelector(
    (state) => state?.tests?.overviewdata
  );

  const recorderInstance: any = useAppSelector(
    (state) => state.screenrecord.recorder
  );

  const fetchedsetting = useAppSelector(
    (state) => state?.tests?.fetchedsetting
  );

  const handleStartRecording = async () => {
    try {
      // Get screen stream
      let screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: {
          //@ts-ignore
          displaySurface: "monitor",
          width: { ideal: 960 },
          height: { ideal: 540 },
        },
      });

      // Check if the user has an audio input device
      const devices = await navigator.mediaDevices.enumerateDevices();
      const hasAudioDevice = devices.some(
        (device) => device.kind === "audioinput"
      );

      let audioStream = null;

      // Get audio stream from microphone if available
      if (hasAudioDevice) {
        //@ts-ignore
        audioStream = await navigator?.mediaDevices?.getUserMedia({
          audio: true,
        });
      }

      // Combine the screen and audio streams
      let combinedStream = new MediaStream();
      [screenStream, audioStream].forEach((stream) => {
        //@ts-ignore
        if (stream && stream.getTracks) {
          stream.getTracks().forEach((track) => combinedStream.addTrack(track));
        }
      });

      let recorder: any = new MediaRecorder(combinedStream);

      console.log("recorder combined Stream inside test", recorder);
      setRecorder(recorder);

      dispatch(handlestartrecording({ recordinstance: recorder }));

      setScreenShareLoading(true);

      let displaySurface = recorder?.stream?.getVideoTracks()[0]?.getSettings()?.displaySurface;

      const label = recorder.stream.getVideoTracks()[0].label;

      if (displaySurface === "monitor" || label === "Primary Monitor") {

        if (fetchedsetting?.allowscreenrecording == "true") {
          const response = await axios.post(`/getexamdata/generate-filename/${id}`);

          if (response) {
            setIsModalOpenScreen(false);
            modalsRef.current.screen_share_modal = 0;
            sendLog(
              `${localstoragedata.email
              } user again started screen recording in test - ${new Date()}`,
              false,
            );
            const data = {
              eventType: EventType.Screen,
              test_assessment_id: id ?? "",
              start: true,
            };
            try {
              await antiCheatingEvents(data);
            } catch (error) {
              console.log("Error occured while sending anticheating events for screen:", error);
            }
            return true;
          } else {
            const datapayload = {
              messages: "Some error occured while sharing, try to share again!",
              severity: "error",
            };
            dispatch(handleopensnackbar(datapayload));
          }
        } else {
          setIsModalOpenScreen(false);
          modalsRef.current.screen_share_modal = 0;
          sendLog(
            `${localstoragedata.email
            } user again started screen recording in test - ${new Date()}`,
            false,
          );
          const data = {
            eventType: EventType.Screen,
            test_assessment_id: id ?? "",
            start: true,
          };
          try {
            await antiCheatingEvents(data);
          } catch (error) {
            console.log("Error occured while sending anticheating events for screen:", error);
          }
          return true;
        }
      } else {
        setDisplayEntireScreenModal(true);
        modalsRef.current.share_entire_screen_modal = 1;

        // Stop the recording
        recorder.stop();

        // Release tracks from both streams
        if (screenStream && screenStream.getTracks) {
          screenStream.getTracks().forEach((track) => track.stop());
        }
        if (audioStream && audioStream.getTracks) {
          audioStream.getTracks().forEach((track) => track.stop());
        }

        setRecorder("");
        return false;
      }
    } catch (error) {
      console.error("Error accessing user media:", error);
    } finally {
      setScreenShareLoading(false);
    }
  };


  useEffect(() => {
    if (tokenid) {
      const payload = {
        testid: tokenid,
      };
      dispatch(fetchdatapreview(payload));
    } else {

      const payload = {
        testid: id,
      };

      dispatch(fetchoverview(payload));
    }

    localStorage.removeItem("assement_info");

  }, [dispatch, id, tokenid]);


  useEffect(() => {
    if (!fetchedoverviewdata.success && !tokenid) {
      window.location.href = `/testoverview/${id}`
    } else if (!fetchedoverviewdata.success && tokenid) {
      window.location.href = `/preview-testpage/${tokenid}`
    }
  }, [fetchedoverviewdata]);


  useEffect(() => {
    if (tokenid) {
      const payload = {
        testid: tokenid,
      };
      dispatch(fetchdatapreview(payload));
    }
    else {

      const payload = {
        testid: id,
      };
      dispatch(fetchdata(payload));
    }

  }, []);

  useEffect(() => {
    if (autorecoverydata != undefined || autorecoverydata !== "") {
      autorecoverydata?.map((each: any) => {
        if (each?.user_answers) {
          setdataforrecover(JSON.parse(each?.user_answers));
        }
      });
    }
  }, [autorecoverydata]);

  useEffect(() => {
    setparticularvalue(dataforrecover);
  }, [dataforrecover]);

  useEffect(() => {
    if (typeof particularvalue === 'object' && Object?.keys(particularvalue)?.length > 0) {
      const data = encryptData(particularvalue);
      sessionStorage.setItem("user_answers", data);
    }
  }, [particularvalue])


  const fetcheddata = useAppSelector((state) => state?.tests?.fetcheddata);
  const fetchedsuccess = useAppSelector(
    (state) => state?.tests?.fetchedsuccess
  );

  const [fetcheddatawhole, setfetcheddatawhole] = useState<any>([]);
  useEffect(() => {
    // alert(fetchedsuccess)
    if (fetchedsuccess == false) {
      window.location.href = "/home/dashboard";
      const datapayload = {
        messages: "You are not invited for this test",
        severity: "error",
      };
      dispatch(handleopensnackbar(datapayload));
    } else {
      setfetcheddatawhole(fetcheddata);
    }
  }, [fetcheddata]);

  const changehandler = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    name?: any,
    data?: any,
    type?: any
  ) => {
    //
    if (type == "codeapi") {
      setparticularvalue({ ...particularvalue, [name]: data });

    }
    else if (type == "codemirror") {
      setparticularvalue({ ...particularvalue, [name]: { source_code: data } });
    }
    else if (type == "ckeditor") {
      setparticularvalue({ ...particularvalue, [name]: data });
    } else {
      setparticularvalue({
        ...particularvalue,
        [e.target.name]: e.target.value,
      });
    }
  };
  const changehandlercheckbox = (e: any) => {
    if (e.target.checked == true) {
      setparticularvalue({
        ...particularvalue,
        [e.target.name]: {
          ...particularvalue[e.target.name],
          [e.target.value]: 1,
        },
      });
    } else if (e.target.checked == false) {
      setparticularvalue({
        ...particularvalue,
        [e.target.name]: {
          ...particularvalue[e.target.name],
          [e.target.value]: 0,
        },
      });
    }
  };

  // const changehandlerimage = async (e: any) => {
  //   const file = e.target.files[0];

  //   if (file) {
  //     const fileSizeInMB = file.size / (1024 * 1024); // Convert file size to MB

  //     // Define the allowed file types
  //     const allowedFileTypes = [
  //       "image/jpeg",
  //       "image/jpg",
  //       "image/png",
  //       "image/gif",
  //     ];

  //     // Check if the file type is allowed and within 2MB
  //     if (allowedFileTypes.includes(file.type) && fileSizeInMB <= 2) {
  //       const reader = new FileReader();

  //       reader.onload = (event) => {
  //         const base64String = event?.target?.result;

  //         if (base64String) {
  //           setparticularvalue({
  //             ...particularvalue,
  //             [e.target.name]: base64String,
  //           });
  //         }
  //       };

  //       reader.readAsDataURL(file);
  //     } else {
  //       e.target.value = null;
  //       const datapayload = {
  //         messages: "Please upload images only which should be less than 2MB",
  //         severity: "error",
  //       };
  //       dispatch(handleopensnackbar(datapayload));
  //     }
  //   }
  // };
  // const changehandlerimage = async (e: any) => {
  //   const file = e.target.files[0];

  //   if (file) {
  //     const fileSizeInMB = file.size / (1024 * 1024);
  //     setFileType(file.type);

  //     // Check if the file type is allowed and its size is within the 2MB limit
  //     if (allowedMimeTypes.includes(file.type) && fileSizeInMB <= 2) {
  //       // Disable button and show loader
  //       setIsUploading(true);
  //       const reader = new FileReader();

  //       reader.onload = (event) => {
  //         const base64String = event?.target?.result;

  //         console.log("base")

  //         if (base64String) {
  //           // Update the state with the base64 string if file is valid
  //           setparticularvalue({
  //             ...particularvalue,
  //             [e.target.name]: base64String,
  //           });

  //           setFileName(file.name); // Set the file name after successful upload
  //         }
  //       };

  //       reader.readAsDataURL(file);

  //       setIsUploading(false);
  //       setAlertType("success");
  //       setAlertMessage("File Uploaded Successfully");
  //       setAlertSnackBarOpen(true);
  //     } else {
  //       // Clear the file input if invalid file type or exceeds size
  //       e.target.value = null;

  //       let errorMessage = "Please upload a valid file.";

  //       // Additional error messages based on the reason for rejection
  //       if (!allowedMimeTypes.includes(file.type)) {
  //         errorMessage = "Please upload a valid image, PDF, DOCX, CSV, or Excel file.";
  //       } else if (fileSizeInMB > 2) {
  //         errorMessage = "The file size exceeds the 2MB limit. Please upload a smaller file.";
  //       }

  //       setAlertType("error");
  //       setAlertMessage(errorMessage);
  //       setAlertSnackBarOpen(true);
  //     }
  //   }
  // };
  const changehandlerimage = async (event: any) => {
    const file = event.target.files[0];
    
    setIsUploading(true);

    // Validate file size (e.g., 2 MB)
    const maxSize = parseInt(process.env.REACT_APP_ALLOWED_MAX_FILE_SIZE || '2097152');
    if (file.size > maxSize) {
      setAlertType("error");
      setAlertMessage("File is too large. Maximum allowed size is 2MB.");
      setAlertSnackBarOpen(true);
      setIsUploading(false);
      return;
    }

    // Validate mimeType (only allow image files)
    if (!allowedMimeTypes.includes(file.type)) {
      setAlertType("error");
      setAlertMessage("Please upload a valid image, PDF, DOCX, CSV, or Excel file.");
      setAlertSnackBarOpen(true);
      setIsUploading(false);
      return;
    }

    const localstoragedata = JSON.parse(
      localStorage.getItem("userdetails") as string
    );

    const formData = new FormData();
    formData.append("file", file);
  
    try {
      const response = await axios.post(
        `/getexamdata/upload-file/${id}?useremail=${localstoragedata?.email}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        // console.log("File uploaded successfully:", response.data);
        setparticularvalue({
          ...particularvalue,
          [event.target.name]: response.data.url,
        });
        setFileName((prevFileNames:any) => ({
          ...prevFileNames,
          [event.target.name]: file.name,
        }));
        // setFileName(file.name);
        setAlertType("success");
        setAlertMessage("File uploaded successfully.");
        setAlertSnackBarOpen(true);
        setIsUploading(false);
      } else {
        setAlertType("error");
        setAlertMessage("File uploaded failed.");
        setAlertSnackBarOpen(true);
        setIsUploading(false);
        console.log("File upload failed:", response.status);
      }


    } catch (error) {
      setAlertType("error");
      setAlertMessage("File uploaded failed.");
      setAlertSnackBarOpen(true);
      setIsUploading(false);
      console.error("Error uploading file:", error);
    }
  };

  const [isVideoUploading, setIsVideoUploading] = useState(false);
  const changehandlervideo = async (blobdata: Blob, sortno: number) => {
    setIsVideoUploading(true);
    let data = new FormData();
    data.append("media_blob", blobdata);

    if (tokenid) {
      return null;
    }

    //over here save api and then store in database and the returned uuid here
    axios
      .post(`/getexamdata/savemedia/${id}`, data, {
        headers: {
          Authorization: `Bearer ${currentusertoken.token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res: any) => {
        // dispatch(handlecloseloader());

        if (res?.data?.media_uuid) {
          setIsVideoUploading(false);
          setparticularvalue({
            ...particularvalue,
            [sortno]: res?.data?.media_uuid,
          });
        }
      })
      .catch((err) => {
        // dispatch(handlecloseloader());
        console.error(err);

        const datapayload = {
          messages: err?.response?.data?.data?.error || err?.message,
          severity: "error",
        };
        dispatch(handleopensnackbar(datapayload));
      });
  };

  // for autosave after a time or whenever a answer is being written

  const [syncvalue, setsyncvalue] = React.useState(false);

  const [allvideoupload, setallvideoupload] = useState(false);

  useEffect(() => {
    if (fetchedsetting && location.pathname.includes("test") && sortnofetched && sortnofetched.length > 0) {
      if ((fetchedsetting?.blockrightclick == "true" || fetchedsetting?.blockinspect == "true") && isDevToolsOpen) {
        setIsModalOpenInspectDevTools(true);
        modalsRef.current.inspect_modal = 1;
        (async () => {
          const data = {
            eventType: EventType.Inspect,
            test_assessment_id: id ?? '',
            start: true,
          }
          try {
            await antiCheatingEvents(data);
          } catch (error) {
            console.log("Error occured while sending anticheating events for inspect:", error);
          }
        })();
      } else {
        setIsModalOpenInspectDevTools(false);
        modalsRef.current.inspect_modal = 0;
        (async () => {
          const data = {
            eventType: EventType.Inspect,
            test_assessment_id: id ?? '',
            end: true,
          }
          try {
            await antiCheatingEvents(data);
          } catch (error) {
            console.log("Error occured while sending anticheating events for inspect:", error);
          }
        })();
      }
    }
  }, [isDevToolsOpen, fetchedsetting, sortnofetched])


  function saveAllRecording() {
    const saveButtons = document.querySelectorAll(".saveRecordingButton");
    let clickedButtons = 0;

    if (saveButtons.length === 0) {
      console.warn("No elements with class 'saveRecordingButton' found.");
      return onclickhandler();
    }

    setIsVideoUploading(true);
    saveButtons.forEach((button) => {
      if (!isVideoUploading) {
        //@ts-ignore
        button.click();
        clickedButtons++;
        console.log(
          "🚀 ~ file: Testwrapper.tsx:290 ~ saveAllRecording ~ button:",
          clickedButtons,
          saveButtons.length
        );
        if (clickedButtons === saveButtons.length) {
          setallvideoupload(true);
        }
      }
    });
  }



  function onclickhandler() {
    handleShow();
  }

  async function retryRequest(url: string, formData: FormData, retries: number = 5, delay: number = 1000): Promise<any> {
    
    function formDataToJSON(formData: FormData) {
      const jsonObject: any = {};
      formData?.forEach((value, key) => {
        jsonObject[key] = value;
      });
      return JSON.stringify(jsonObject, null, 2);
    }

    async function attemptRetry(attempt: number, currentDelay: number): Promise<any> {
      try {
        // Attempt to send the data to the server
        const response = await axios.post(url, formData);
        return response;
      } catch (error) {
        const formDataLog = formDataToJSON(formData);
        if (attempt < retries - 1) {
          console.log(`Attempt ${attempt + 1} failed. Retrying in ${currentDelay}ms...`);
          sendLog(`Client Side ->> Attempt ${attempt + 1} failed for API 'save/api' for user ${localstoragedata?.email} and for Form Data: ${formDataLog || "No Form Data"}. Retrying in ${currentDelay}ms...`, false);
          await new Promise((resolve) => setTimeout(resolve, currentDelay));
          // Recursively call the function with incremented attempt and updated delay
          return attemptRetry(attempt + 1, currentDelay * 2);
        } else {
          // If all retry attempts fail, throw the error
          throw error;
        }
      }
    }
  
    // Start the first attempt with initial delay
    return attemptRetry(0, delay);
  }

  async function submithandler(issubmittedvalue?: number, event?: any) {
    if (tokenid) {
      return null;
    }

    if (issubmittedvalue) {
      setIsSubmitting(true);
      dispatch(handleopenloader());
      
      if ((fetchedsetting?.randomphotos == "true" ||
        fetchedoverviewdata?.isvideoanswer ||
        fetchedsetting?.live_proctoring_camera == "true")) {
        const dataForCamera = {
          eventType: EventType.Camera,
          test_assessment_id: id ?? "",
          end: true,
        };

        try {
          await antiCheatingEvents(dataForCamera);
        } catch (error) {
          console.log("Error occurred while sending anti-cheating events for camera or screen:", error);
        }
      }


      if (recorderInstance && (fetchedsetting?.allowscreenrecording == "true" ||
        fetchedsetting?.live_proctoring_screen == "true") && !tokenid) {
        const dataForScreen = {
          eventType: EventType.Screen,
          test_assessment_id: id ?? "",
          end: true,
        };

        try {
          await antiCheatingEvents(dataForScreen);
        } catch (error) {
          console.log("Error occurred while sending anti-cheating events for camera or screen:", error);
        }
      }
    }

    const sortnofetched_session = sessionStorage.getItem("sortnofetched_session") as string;
    const user_answers_session = sessionStorage.getItem("user_answers") as string ?? null;
    const data = decryptData(sortnofetched_session);

    let user_answers;
    if (user_answers_session) {
      user_answers = decryptData(user_answers_session);
    }

    const cleanedParticularValue = cleanObject(particularvalue);
    const cleanedUserAnswers = user_answers ? cleanObject(user_answers) : null;

    const payload: any = {
      issubmitted: issubmittedvalue,
      answers: typeof cleanedParticularValue === 'object' && Object?.keys(cleanedParticularValue)?.length > 0
        ? cleanedParticularValue
        : cleanedUserAnswers
          ? cleanedUserAnswers
          : cleanedParticularValue,
      geolocationlatitude: geolocationdata?.current?.geolocationlatitude,
      geolocationlongitude: geolocationdata?.current?.geolocationlongitude,
      accuracy: geolocationdata?.current?.accuracy,
      allotedquestions: sortnofetched && sortnofetched.length > 0 ? sortnofetched : data,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
    };

    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));

    if (
      recorderInstance &&
      fetchedsetting?.allowscreenrecording === "true" &&
      event &&
      event.data
    ) {
      const checksum = calculateChecksum(event.data);
      formData.append("screenRecording", event.data);
      // console.log("screenRecording", event.data);
      formData.append("screenRecordingChecksum", JSON.stringify(checksum));
      formData.append("sequenceNumber", JSON.stringify(sequenceNumber.current));
      sequenceNumber.current++;
    }

    formData.append("modals_payload", JSON.stringify({
      camera_modal: modalsRef.current.camera_modal === 1 ? "open" : "closed",
      full_screen_modal: modalsRef.current.full_screen_modal === 1 ? "open" : "closed",
      inspect_modal: modalsRef.current.inspect_modal === 1 ? "open" : "closed",
      screen_share_modal: modalsRef.current.screen_share_modal === 1 ? "open" : "closed",
      kick_user_modal: modalsRef.current.kick_user_modal === 1 ? "open" : "closed",
      switch_tab_modal: modalsRef.current.switch_tab_modal === 1 ? "open" : "closed",
      entire_screen_modal: modalsRef.current.share_entire_screen_modal === 1 ? "open" : "closed",
    }));

    try {
      const response = await retryRequest(`getexamdata/saveapi/${id}`, formData);
      // const response = await axios.post(`getexamdata/saveapi/${id}`, formData);
      dispatch(handlecloseloader());

      if (response?.data?.issubmitted === 1) {
        if (socketinstance) {
          socketinstance.emit("user-disconnect", {
            assessment_id_secret: id,
            msg: "user has submitted or moved away from assessment",
            userremoved: 1,
            is_reload: true,
            userid: userId,
            organization_id: fetchedoverviewdata?.organization_id ?? 0,
          });
        }

        if (
          fetchedsetting.allowscreenrecording === "true" ||
          fetchedsetting?.randomphotos === "true" ||
          ((fetchedoverviewdata?.ai_interview == 1 ||
            fetchedoverviewdata?.isvideoanswer) &&
            Object.values(particularvalue).length)
        ) {
          dispatch(handlestoprecording());
          window.location.href = `/dashboard/attemptedtest/step3/${id}`;
        } else {
          window.location.href = `/dashboard/attemptedseries?testid=${id}&isfeedback=1`;
        }
      }
      setsyncvalue(false);
    } catch (e) {
      dispatch(handlecloseloader());
      const error = e as AxiosError;

      const datapayload = {
        messages: error?.response?.data?.data?.error || error?.message,
        severity: "error",
      };

      console.log("Error occurred for save API", error);
      dispatch(handleopensnackbar(datapayload));
    }
  }

  useEffect(() => {
    if (fetchedoverviewdata?.isvideoanswer && (typeof particularvalue === 'object' && Object?.keys(particularvalue)?.length > 0) && !tokenid) {
      submithandler();
    }
  }, [fetchedoverviewdata?.isvideoanswer, tokenid, particularvalue])


  useEffect(() => {
    if (sortnofetched && sortnofetched.length > 0) {
      let data: AntiCheatingEventsType = {
        test_assessment_id: id ?? "",
        start: true,
      }

      if (
        (fetchedsetting?.randomphotos == "true" ||
          fetchedoverviewdata?.isvideoanswer ||
          fetchedsetting?.live_proctoring_camera == "true")
      ) {
        try {
          data = {
            ...data,
            eventType: EventType.Camera,
          };
          (async () => {
            try {
              await antiCheatingEvents(data);
            } catch (error) {
              console.log("Error occured while sending anticheating events for camera:", error);
            }
          })();
        } catch (error) {
          console.error(
            "Error occured while sending anticheating events for camera:",
            error
          );
        }
      }

      if (
        fetchedsetting?.allowscreenrecording == "true" ||
        fetchedsetting?.live_proctoring_screen == "true"
      ) {
        try {
          data = {
            ...data,
            eventType: EventType.Screen,
          };
          (async () => {
            try {
              await antiCheatingEvents(data);
            } catch (error) {
              console.log("Error occured while sending anticheating events for screen:", error);
            }
          })();
        } catch (error) {
          console.error(
            "Error occured while sending anticheating events for screen recording:",
            error
          );
        }
      }
    }
  }, [sortnofetched])


  useEffect(() => {
    if (
      recorderInstance &&
      (fetchedsetting?.allowscreenrecording == "true" ||
        fetchedsetting?.live_proctoring_screen == "true") &&
      !tokenid
    ) {
      try {
        const _localstoragedata = JSON.parse(
          localStorage.getItem("userdetails") as string
        );

        let _userId: any;

        const socket: Socket = io(process.env.REACT_APP_API_URL as string, {
          query: {
            token: _localstoragedata?.token,
            user_type: "test_taker_user",
          },
        });

        setsocketinstance2(socket);

        (async function () {
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_API_URL}/authuser/getuserid/${_localstoragedata?.email}`
            );

            _userId = response.data.user_id || 0;
          } catch (error) {
            console.error("Error fetching user ID:", error);
          }
        })();

        recorderInstance.onstop = async () => {
          sendLog(
            `${localstoragedata.email
            } user stopped screen recording - ${new Date()}`,
            false
          );

          const peerId = JSON.parse(sessionStorage.getItem("userPeerID") as string);

          if (peerId) {
            socket.emit("remove-peer-id", {
              peerID: peerId,
              assessment_id_secret: id,
              userid: _userId ?? 0,
            });
          }

          socket.emit("user-disconnect", {
            msg: "user stopped screen recording stop",
            userremoved: 1,
            userid: _userId ?? 0,
            is_reload: false,
            assessment_id_secret: id,
            organization_id: fetchedoverviewdata?.organization_id ?? 0,
          });

          setsocketinstance(null);
          setUserStoppedScreenSharing(true);
          dispatch(handlestoprecording())
          openmodalForScreen();
          // socket.disconnect();
        };

        recorderInstance.start(30000);

        const mediaStream = recorderInstance.stream;
        const tracks = mediaStream.getTracks();

        for (const track of tracks) {
          if (track.kind === "video") {
            track.addEventListener("ended", () => {
              console.log("Recording stopped by user");
              recorderInstance.stop();
            });
            break;
          }
        }
      } catch (err) {
        sendLog(
          `${localstoragedata.email
          } user stopped screen recording - ${new Date()}`,
          false
        );

        openmodalForScreen();
      }
    }
  }, [
    recorderInstance,
    fetchedsetting?.allowscreenrecording,
    fetchedsetting?.live_proctoring_screen,
    tokenid
  ]);

  useEffect(() => {
    if (recorderInstance) {
      const localstoragedata = JSON.parse(
        localStorage.getItem("userdetails") as string
      );

      // console.log("🚀🚀 recorderInstance", recorderInstance);
      const data = `Client Side ->> Recorder instance initialized ${recorderInstance} for user ${localstoragedata?.email}`;
      sendLog(data, false);
    }

  }, [recorderInstance])
  


  // let totalDataSize = 0;
  // const MIN_DATA_SIZE = 1000000; // 1 MB

  const MAX_RETRIES = 3;
  const UPLOAD_INTERVAL = 1000; // Delay between retries in ms
  const MIN_DATA_SIZE = 1024 * 1024;


  // useEffect(() => {
  //   if (recorderInstance && (fetchedsetting?.allowscreenrecording == "true" ||
  //     fetchedsetting?.live_proctoring_screen == "true") && !tokenid) {
  //     recorderInstance.ondataavailable = (event: any) => {
  //       const newDataSize = event.data.size;
  //       setChunkSize(chunkSize + event.data.size);
  //       totalDataSize += newDataSize;

  //       if (totalDataSize >= MIN_DATA_SIZE) {
  //         submithandler(0, event);
  //         totalDataSize = 0;
  //       }
  //     };
  //   }
  // }, [recorderInstance, fetchedsetting?.allowscreenrecording, fetchedsetting?.live_proctoring_screen, tokenid]);
  const uploadChunk = async (event: any, retries = 0): Promise<boolean> => {
    try {
      await submithandler(0, event);
      console.log("Chunk uploaded successfully");
      return true;
    } catch (error) {
      if (retries < MAX_RETRIES) {
        console.log(`Retrying upload... Attempt ${retries + 1}`);
        const data = `Client Side ->> Screen recording chunk upload failed with chunk size ${formatFileSize(event.data.size ?? 0)}, attemp no. ${retries + 1}  for user ${localstoragedata?.email}`;
        sendLog(data, false);
        await new Promise((resolve) => setTimeout(resolve, UPLOAD_INTERVAL));
        return uploadChunk(event, retries + 1);
      } else {
        console.error("Failed to upload chunk after maximum retries");
        return false;
      }
    }
  };

  // Function to process the upload queue
  const processQueue = async () => {
    if (isProcessingRef.current) return; // Prevent multiple instances from running
    isProcessingRef.current = true;

    while (uploadQueueRef.current.length > 0) {
      const { event } = uploadQueueRef.current.shift(); // Remove the first chunk from the queue
      await uploadChunk(event); // Upload the chunk with retries
    }

    isProcessingRef.current = false; // Reset processing flag when done
  };

  // Effect to handle the ondataavailable event and add chunks to the queue
  useEffect(() => {
    if (
      recorderInstance &&
      (fetchedsetting?.allowscreenrecording === "true" || fetchedsetting?.live_proctoring_screen === "true") &&
      !tokenid
    ) {
      recorderInstance.ondataavailable = (event: any) => {
        const newDataSize = event.data.size;
        totalDataSizeRef.current += newDataSize;

        const data = `Client Side ->> Screen recording chunk data created ${event?.data} and chunk size is ${formatFileSize(newDataSize?? 0)} for user ${localstoragedata?.email}`;
        
        console.log(data);
        sendLog(data, false);

        // Only add to queue if accumulated data size is above threshold
        if (totalDataSizeRef.current >= MIN_DATA_SIZE) {
          uploadQueueRef.current.push({ event }); // Add to queue
          totalDataSizeRef.current = 0; // Reset accumulated size
          processQueue(); // Trigger queue processing
        }
      };
    }
  }, [recorderInstance, fetchedsetting?.allowscreenrecording, fetchedsetting?.live_proctoring_screen, tokenid]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (fetchedsuccess) {
        if (sortnofetched.length > 0 && !isSubmitting) {
          submithandler();
        }
      }
    }, 20000); // 20000 milliseconds = 20 seconds

    return () => {
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, [fetchedsuccess, sortnofetched, savetimer, isSubmitting, submithandler]);

  useEffect(() => {
    if ((sortnofetched && sortnofetched.length > 0) && !isSubmitting) {
      submithandler();
    }
  }, [sortnofetched, isSubmitting]);

  // useEffect(() => {
  //   if (
  //     !isSubmitting &&
  //     ((fetchedsetting?.allowscreenrecording === "true" && !recorderInstance) || (fetchedsetting?.live_proctoring_screen === "true" && !recorderInstance))
  //   ) {
  //     const datapayload = {
  //       messages: "Screen recording is required for this test",
  //       severity: "info",
  //     };
  //     dispatch(handleopensnackbar(datapayload));
  //     window.location.href = "/dashboard/assigned";
  //   }
  // }, [
  //   recorderInstance,
  //   navigate,
  //   isSubmitting,
  //   fetchedsetting?.allowscreenrecording,
  //   fetchedsetting?.live_proctoring_screen
  // ]);

  useEffect(() => {
    dispatch(answeredvalues(particularvalue));
  }, [particularvalue, dispatch]);

  //anticheating

  fetchedsetting?.blockrightclick == "true" &&
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });


  const blockCopyAndPasteHandler = (e: any) => {
    if (fetchedsetting?.copynpaste == "true") {
      setAlertType("error");
      setAlertMessage("Copy & Paste is not allowed for this test!");
      setAlertSnackBarOpen(true);
      e.preventDefault();
      e.stopPropagation();
    }
  }

  if (fetchedsetting?.blockinspect == "true") {
    document.onkeydown = function (e) {
      // disable F12 key

      if (e.key === "F12") {
        e.preventDefault();
      } else if (e.ctrlKey && e.shiftKey && e.key === "I") {
        // Prevent Ctrl+Shift+I
        return false;
      } else if (e.key === "Fn") {
        return false;
      }
    };
  }


  const localstoragedata = JSON.parse(
    localStorage.getItem("userdetails") as string
  );


  const myRef = useRef<any>(null);
  //submit modal
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);

    scroller.scrollTo(`1-id`, {
      duration: 0,
      delay: 100,
      smooth: true,
      offset: -120, // Scrolls to element - 120 pixels down the page
    });
  };
  const handleShow = () => setShow(true);

  function sendposition(position: any) {
    geolocationdata.current = {
      geolocationlatitude: position.coords.latitude,
      geolocationlongitude: position.coords.longitude,
      accuracy: position.coords.accuracy,
    }
  }

  function getLocation() {
    const options = {
      enableHighAccuracy: true,
    };

    if (navigator?.geolocation) {
      navigator?.geolocation?.getCurrentPosition(sendposition, error, options);
    }
  }
  useEffect(() => {
    if (fetchedsetting?.islocation == "true") {
      getLocation();
    }
  }, [fetchedsetting]);

  function error(err: any) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }


  //api call for updating tab switch events
  useEffect(() => {
    if (fetchedsetting?.anti_cheating == "true" && fetchedsetting?.no_switch_tabs_allowed == "true") {
      window.onblur = async function () {
        if (fetchedsetting?.no_switch_tabs_allowed == "true") {
          const data = {
            eventType: EventType.TabSwitch,
            test_assessment_id: id ?? "",
            start: true,
          };
          try {
            await antiCheatingEvents(data);
          } catch (error) {
            console.log("Error occured while sending anticheating events for tabswitch:", error);
          }
          openmodalForWindowSwitch();
        }
        if (tokenid) {
          return null;
        }
      };
      window.onfocus = async function () {
        if (tokenid) {
          return null;
        }
        const data = {
          eventType: EventType.TabSwitch,
          test_assessment_id: id ?? "",
          end: true,
        };
        try {
          await antiCheatingEvents(data);
        } catch (error) {
          console.log("Error occured while sending anticheating events for tabswitch:", error);
        }
      };
    }
  }, [fetchedsetting?.anti_cheating]);

  //api call for updating mouse leave events
  useEffect(() => {
    if (fetchedsetting?.anti_cheating == "true") {
      document.addEventListener("mouseleave", function (event) {
        if (
          event.clientY <= 0 ||
          event.clientX <= 0 ||
          event.clientX >= window.innerWidth ||
          event.clientY >= window.innerHeight
        ) {
          if (tokenid) {
            return null;
          }
          axios
            .post(`getexamdata/mouseout/${id}`)
            .then((res) => { })
            .catch((err) => console.error(err));
        }
      });
    }
  }, [fetchedsetting?.anti_cheating]);


  // const [isFullscreen, setIsFullscreen] = useState(false);

  //api call for updating fullscreen events
  useEffect(() => {
    if (fetchedsetting?.anti_cheating == "true" && fetchedsetting?.fullscreen == "true") {
      const handleResize = async () => {
        const innerHeight = window.innerHeight;
        if (!localStorage.getItem("assement_info")) {
          const encrypted = encryptData(innerHeight);
          localStorage.setItem("assement_info", encrypted);
        }

        const decryptedHeight = decryptData(localStorage.getItem("assement_info")) || 0;
        if (innerHeight > decryptedHeight) {
          const encrypted = encryptData(innerHeight);
          localStorage.setItem("assement_info", encrypted);
        }

        // eslint-disable-next-line no-restricted-globals
        const isFullscreen = innerHeight === decryptData(localStorage.getItem("assement_info"));

        if (!isFullscreen) {
          // setIsFullscreen(false);
          setIsModalOpenresize(true);
          modalsRef.current.full_screen_modal = 1;
          const data = {
            eventType: EventType.FullScreen,
            test_assessment_id: id ?? "",
            start: true,
          };
          try {
            await antiCheatingEvents(data);
          } catch (error) {
            console.log("Error occured while sending anticheating events for fullscreen:", error);
          }
        } else if (isFullscreen) {
          // setIsFullscreen(true);
          setIsModalOpenresize(false);
          modalsRef.current.full_screen_modal = 0;
          const data = {
            eventType: EventType.FullScreen,
            test_assessment_id: id ?? "",
            end: true,
          };
          try {
            await antiCheatingEvents(data);
          } catch (error) {
            console.log("Error occured while sending anticheating events for fullscreen:", error);
          }
        }

        if (tokenid) {
          return null;
        }

      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [fetchedsetting, id]);

  const intl = useIntl();

  //peerjs

  const [webcamRef, setwebcamRef] = useState(null);
  

  const connectPeer = useCallback(async () => {
    if (tokenid) {
      return null;
    }
    if (recorderInstance) {
      const localstoragedata = JSON.parse(
        localStorage.getItem("userdetails") as string
      );
      const token = localstoragedata?.token;
      let peerID: any = null;

      if (peerInstance) {
        console.log("Destroying old peer instance...");
        peerInstance.destroy();
      }

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/authuser/getuserid/${localstoragedata.email}`
        )
        .then((res) => {
          let userid = res.data.user_id || 0;
          setUserid(userid);

          if (socketinstance) {
            socketinstance.disconnect();
          }

          if (socketinstance2) {
            socketinstance2.disconnect();
          }

          const socket: Socket = io(process.env.REACT_APP_API_URL as string, {
            query: {
              token: token,
              user_type: "test_taker_user"
            },
          });
          setsocketinstance(socket);

          const mypeer = new Peer({
            host: "peer.mtesthub.com",
            port: 443,
            secure: true,
            path: "/mtesthub",
            config: {
              iceServers: [
                { urls: 'stun:stun.l.google.com:19302' },
                { urls: 'stun:stun1.l.google.com:19302' },
                { urls: 'stun:stun2.l.google.com:19302' },
                { urls: 'stun:stun3.l.google.com:19302' },
                { urls: 'stun:stun4.l.google.com:19302' },
                {
                  urls: 'turn:82.112.250.249:3478',
                  username: 'mtestUTurn',
                  credential: 'jhruryhu391225#1vc@oP'
                },
              ]
            }
          });

          setPeerInstance(mypeer);

          mypeer.on("open", (peerid) => {
            peerID = peerid;
            sessionStorage.setItem("userPeerID", JSON.stringify(peerid))
            console.log("My peer ID is:", peerid);
            socket.emit("client join", {
              assessment_id_secret: id,
              ttu_id: userid,
              peerid,
            });

            if (fetchedoverviewdata?.organization_id) {
              socket.emit("organization_event", {
                assessment_id_secret: id,
                organization_id: fetchedoverviewdata?.organization_id
              });
            }
          });

          // When receiving a call from another peer
          mypeer.on("call", (call) => {
            // Set the received call instance
            console.log("->>> Call event received");

            setPeerCallInstance(call);

            // Get the stream (presumably from recorderInstance.stream)
            let combinedStream: any = null;
            const stream = recorderInstance.stream;


            if (stream && stream.getTracks() && webcamRef) {
              combinedStream = new MediaStream([
                //@ts-ignore
                ...webcamRef?.current?.stream?.getTracks(),
                ...stream.getTracks(),
              ]);
            } else if (stream && stream.getTracks()) {
              combinedStream = new MediaStream([...stream.getTracks()]);
            } else if (webcamRef) {
              combinedStream = new MediaStream([
                //@ts-ignore
                ...webcamRef?.current?.stream?.getTracks(),
              ]);
            }

            if (combinedStream) {
              console.log(
                "🚀 ~ file: trackerIndex.js:600 ~ socket.on ~ combinedStream:",
                combinedStream
              );
              call.answer(combinedStream);

              if (userStoppedScreenSharing && combinedStream) {
                setScreenShareLoading(false);
              }

              // Optional: Clear interval when the call ends
              call.on('close', () => {
              });
            }
          });


          socket.on("isuserconnected", ({ assessment_id_secret }) => {

            console.log("asked for user connected");
            //@ts-ignore
            socket.emit("send-user-connected", {
              peerID,
              ttu_id: userid,
              assessment_id_secret,
            });
          });

          if (userStoppedScreenSharing) {
            socket.emit("update-client-side-count-for-room", {
              ttu_id: userid,
              assessment_id_secret: id,
            });
          }

          socket.on("reconnecting-user", ({ employeeEmail, assessment_id_secret }) => {
            if (employeeEmail === (localstoragedata.email)) {
              socket.emit("send-user-reconnected", {
                peerID,
                ttu_id: userid,
                assessment_id_secret,
              });
            }
          });


          socket.on("kick-user", ({ employeeEmail }) => {
            if (employeeEmail === (localstoragedata.email)) {

              const notification = new Notification("Your test session has been suspended, due to activity requiring further investigation", options);

              notification.onclick = function () {
                if (document.hidden) {
                  window.focus();
                }
              };

              setIsModalOpenKick(true);
              modalsRef.current.kick_user_modal = 1;
              setIsSubmitting(true);
              setTimeout(() => {
                window.location.href = `/dashboard/attemptedseries?testid=${id}&isfeedback=1`;
              }, 22000);
            }
          })

          socket.on("msg-user", ({ employeeEmail, msg }) => {
            if (employeeEmail === (localstoragedata.email) && msg) {

              const notification = new Notification(msg, options);

              notification.onclick = function () {
                if (document.hidden) {
                  window.focus();
                }
              };

              const datapayload = {
                messages: msg,
                severity: "info",
                autoClose: false,
              };
              dispatch(handleopensnackbar(datapayload));

            }
          });

          socket.on("broadcast-message", ({ message }) => {
            const notification = new Notification(message, options);

            notification.onclick = function () {
              if (document.hidden) {
                window.focus();
              }
            };

            const datapayload = {
              messages: message,
              severity: "info",
              autoClose: false,
            };
            dispatch(handleopensnackbar(datapayload));
          });

        });
    }
  }, [recorderInstance, id, webcamRef]);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
    };

    const handleUnload = () => {
      console.log(`removing user ${userId} from the test room`);

      sessionStorage.removeItem("userPeerID");

      socketinstance.emit("user-disconnect", {
        assessment_id_secret: id,
        userremoved: 1,
        is_reload: true,
        userid: userId || 0,
        msg: "User disconnected due to closing or reload",
        organization_id: fetchedoverviewdata?.organization_id ?? 0,
      });
    };

    // Add the unload event listener (for actual disconnection)
    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, [socketinstance, id, userId]);

  useEffect(() => {
    if (
      recorderInstance &&
      (fetchedsetting?.live_proctoring_screen == "true") &&
      !peerCallInstance &&
      !socketinstance &&
      !userId &&
      !userStoppedScreenSharing
    ) {
      console.log('🙋‍♂️ inside this');
      if ((fetchedsetting?.live_proctoring_camera == "true")) {
        if (webcamRef) {
          connectPeer();
        }
      } else {
        connectPeer();
      }
    }

    return () => {
      try {
        if (
          recorderInstance &&
          fetchedsetting?.live_proctoring_screen == "true" &&
          peerCallInstance
        ) {
          // peerCallInstance?.close();
        }
      } catch (error) {
        console.log(error);
      }
    };
  }, [
    connectPeer,
    peerCallInstance,
    recorderInstance,
    socketinstance,
    fetchedsetting?.live_proctoring_screen,
    userId,
    webcamRef
  ]);

  // console.log("userStoppedScreenSharing", userStoppedScreenSharing);
  // console.log("userId", userId);
  // console.log("socketinstance", socketinstance);
  // console.log("peerCallInstance", peerCallInstance);

  useEffect(() => {
    if (
      recorderInstance &&
      (fetchedsetting?.live_proctoring_screen == "true") &&
      peerCallInstance &&
      !socketinstance &&
      userId &&
      userStoppedScreenSharing
    ) {
      console.log('🙋‍♂️ inside this 2');
      if ((fetchedsetting?.live_proctoring_camera == "true")) {
        if (webcamRef) {
          connectPeer();
        }
      } else {
        connectPeer();
      }
    }
  }, [
    connectPeer,
    peerCallInstance,
    recorderInstance,
    socketinstance,
    fetchedsetting?.live_proctoring_screen,
    userId,
    webcamRef
  ]);


  useEffect(() => {
    if (timerComplete) {
      submithandler(1);
    }

  }, [timerComplete])

  useEffect(() => {
    if (fetchedsetting?.randomphotos == "true" ||
      fetchedsetting?.live_proctoring_camera == "true" && !tokenid) {
      if (isModalOpenCamera && !isSubmitting && facesDetected === 0) {
        const dataForCamera = {
          eventType: EventType.Camera,
          test_assessment_id: id ?? "",
          end: true,
        };
        (async () => {
          try {
            await antiCheatingEvents(dataForCamera);
          } catch (error) {
            console.log("Error occured while sending anticheating events for camera:", error);
          }
        })();
      } else if (!isModalOpenCamera && !isSubmitting && facesDetected > 0) {
        const dataForCamera = {
          eventType: EventType.Camera,
          test_assessment_id: id ?? "",
          start: true,
        };

        (async () => {
          try {
            await antiCheatingEvents(dataForCamera);
          } catch (error) {
            console.log("Error occured while sending anticheating events for camera:", error);
          }
        })();
      }
    }
  }, [fetchedsetting, tokenid, isModalOpenCamera, isSubmitting, facesDetected])



  const memoizedCameraWarn = React.useMemo(() => (
    <CameraWarn
      isModalOpenResize={isModalOpenCamera}
      setIsModalOpenResize={setIsModalOpenCamera}
      facesDetected={facesDetected}
    />
  ), [isModalOpenCamera, facesDetected, setIsModalOpenCamera]);

  // console.log("facesDetected", facesDetected);

  return (
    <div onCopy={blockCopyAndPasteHandler} onPaste={blockCopyAndPasteHandler}>
      {/* begin::Row */}
      {tokenid ? (
        <>
          <div ref={myRef} className="row g-5 g-xl-10 mb-5 mb-xl-10">
            {/* begin::Col */}

            <div style={{ width: "100%" }}>
              {/* add tests question here */}
              {sortnofetched && sortnofetched.length > 0
                ? fetcheddatawhole?.map((each: any) => {
                  return (
                    <Questioncardwidget
                      isVideoUploading={false}
                      changehandlervideo={() => {
                        console.log("changehandlervideo");
                      }}
                      changehandler={changehandler}
                      changehandlerimage={changehandlerimage}
                      changehandlercheckbox={changehandlercheckbox}
                      particularvalue={particularvalue}
                      setparticularvalue={setparticularvalue}
                      className="h-md-100"
                      datawhole={each}
                      fileName={fileName[each.sortno] ?? ''}
                      isUploading={isUploading}
                      fileType={fileType}
                    />
                  );
                })
                : [...Array(5)].map((_, i) => (
                  <Box
                    key={`skeleton-${i}`}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      sx={{ width: "100%", height: 250, marginBottom: 5 }}
                    />
                  </Box>
                ))}
            </div>
            {/* end::Col */}
          </div>
        </>
      ) : (
        <>
          <OGPAdd testid={id} fetchedoverviewdata={fetchedoverviewdata} />

          <div ref={myRef} className="row g-5 g-xl-10 mb-5 mb-xl-10">
            {/* begin::Col */}

            <div style={{ width: "100%" }}>
              {/* add tests question here */}
              {sortnofetched && sortnofetched.length > 0
                ? fetcheddatawhole?.map((each: any, index: any) => {
                  return (
                    <div key={index}>
                      {
                        <Questioncardwidget
                          changehandlervideo={changehandlervideo}
                          isVideoUploading={isVideoUploading}
                          changehandler={changehandler}
                          changehandlerimage={changehandlerimage}
                          changehandlercheckbox={changehandlercheckbox}
                          particularvalue={particularvalue}
                          setparticularvalue={setparticularvalue}
                          className="h-md-100"
                          datawhole={each}
                          fileName={fileName[each.sortno] ?? ''}
                          isUploading={isUploading}
                          fileType={fileType}
                        />
                      }
                    </div>
                  );
                })
                : [...Array(5)].map((_, i) => (
                  <Box
                    key={`skeleton-${i * 111}`}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      sx={{ width: "100%", height: 250, marginBottom: 5 }}
                    />
                  </Box>
                ))}
            </div>
            {/* end::Col */}

            {/* 
            isvideouploading -- if is video uploading then show loader and keep submit button disabled
            saveRecordingButton  -- you can use this classname directly without any validation only avaialble for which is not save and recorded
            classname
            */}

            {sortnofetched && sortnofetched.length > 0 && (
              <div className="submitdiv">
                {isVideoUploading && <CircularProgress />}
                <Button
                  disabled={isVideoUploading}
                  className="btn2"
                  sx={{
                    padding: "12px !important",
                    fontSize: "12px !important",
                  }}
                  id="submittestbutton"
                  onClick={saveAllRecording}
                >
                  {intl.formatMessage({ id: "TEST.SUBMIT" })}
                </Button>

                {/* </Online> */}
              </div>
            )}
          </div>
          {/* <Online> */}
          <SubmitModal
            handleClose={handleClose}
            show={show}
            submithandler={async () => {
              submithandler(1);
            }}
          />

          {/* end::Row */}
        </>
      )}
      {/* begin::Row */}
      <div className="row gx-5 gx-xl-10">
        {/* begin::Col */}
        <div className="col-xxl-6 mb-5 mb-xl-10"></div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {(fetchedsetting?.randomphotos == "true" ||
        fetchedsetting?.live_proctoring_camera == "true") &&
        sortnofetched &&
        sortnofetched.length > 0 && (
          <Camerafortest
            tokenid={tokenid}
            setwebcamRef={setwebcamRef}
            sendLog={sendLog}
            openmodalForCamera={openmodalForCamera}
            closemodalForCamera={closemodalForCamera}
            setFacesDetected={setFacesDetected}
          />
        )}

      {fetchedsetting?.anti_cheating == "true" &&
        fetchedsetting?.fullscreen == "true" ? (
        <FullscreenModal
          setIsModalOpenResize={setIsModalOpenresize}
          isModalOpenResize={isModalOpenresize}
        />
      ) : null}

      {fetchedsetting?.anti_cheating == "true" &&
        fetchedsetting?.no_switch_tabs_allowed == "true" ? (
        <WindowSwitchModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          modalsRef={modalsRef}
        />
      ) : null}
      {
        <KickedUserModal
          isModalOpenKick={isModalOpenKick}
          setIsModalOpenKick={setIsModalOpenKick}
          org_name={fetchedoverviewdata?.conductedby || "your organization"}
        />
      }
      {(fetchedsetting?.randomphotos == "true" ||
        fetchedsetting?.live_proctoring_camera == "true") &&
        memoizedCameraWarn}
      {(fetchedsetting?.allowscreenrecording == "true" ||
        fetchedsetting?.live_proctoring_screen == "true") && (
          <ShareEntireScreenModal
            isModalOpen={displayEntireScreenModal}
            setIsModalOpen={setDisplayEntireScreenModal}
            modalsRef={modalsRef}
            handleShareScreen={handleStartRecording}
          />
        )}

      {(fetchedsetting?.allowscreenrecording == "true" ||
        fetchedsetting?.live_proctoring_screen == "true") && (
          <ScreenWarn
            isModalOpenResize={isModalOpenScreen}
            setIsModalOpenResize={setIsModalOpenScreen}
            allowScreenShareFunc={handleStartRecording}
            screenShareLoading={screenShareLoading}
          />
        )}

      {(fetchedsetting?.blockinspect == "true" ||
        fetchedsetting?.blockrightclick == "true") && (
          <InspectDevToolsModal isModalOpenResize={openmodalForInspectDevTools} />
        )}

      {!isOnline && (
        <InternetTurnOffModal isModalOpenResize={!isOnline} />
      )}

      {
        alertSnackBarOpen && alertType && <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={alertSnackBarOpen}
          autoHideDuration={5000}
          onClose={() => setAlertSnackBarOpen(false)}
          TransitionComponent={(props) => <Slide {...props} direction="right" />}
        >
          <Alert
            onClose={() => setAlertSnackBarOpen(false)}
            severity={alertType}
            variant="filled"
            sx={{ width: "100%", fontSize: 14 }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
      }


    </div>
  );
};


const Testwrapper: FC = () => {
  const intl = useIntl();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      <Testpage />
    </>
  );
};

export { Testwrapper };

