import clsx from 'clsx'
import { ToolbarType, useLayout } from '../../core'
import { Toolbar } from './Toolbar'
import { PageTitleWrapper } from './page-title'
import { useLocation } from 'react-router-dom'

const ToolbarWrapper = () => {
  const location = useLocation()
  const { config, classes } = useLayout()
  if (!config.app?.toolbar?.display) {
    return null
  }

  const isPageTitleVisible = showPageTitle(
    config.app?.toolbar?.layout,
    config.app?.pageTitle?.display
  )


  return (
    (!location.pathname.includes("myprofile") && !location.pathname.includes("test") && !location.pathname.includes("home") && !location.pathname.includes("faq")) ?
      <div
        id='kt_app_toolbar'
        className={clsx('app-toolbar', classes.toolbar.join(' '), config?.app?.toolbar?.class)}
        style={{ borderTop: "1px solid #e8e4e4", boxShadow: "none", marginBottom: '12px', height: '55px' }}
      >

        <div
          id='kt_app_toolbar_container'
          className={clsx(
            'app-container',
            classes.toolbarContainer.join(' '),
            config.app?.toolbar?.containerClass,
            config.app?.toolbar?.minimize?.enabled ? 'app-toolbar-minimize' : '',
            {
              'container-fluid': config.app?.toolbar?.container === 'fluid',
              'container-xxl': config.app?.toolbar?.container === 'fixed',
            }
          )}
        >
          <PageTitleWrapper />
          < Toolbar />
        </div>

      </div>
      : <div style={{ marginBottom: '20px' }}>
      </div>
  )
}

const showPageTitle = (appToolbarLayout?: ToolbarType, appPageTitleDisplay?: boolean): boolean => {
  const viewsWithPageTitles = ['classic', 'reports', 'saas']
  if (!appToolbarLayout || !appPageTitleDisplay) {
    return false
  }

  return appPageTitleDisplay && viewsWithPageTitles.some((t) => t === appToolbarLayout)
}

export { ToolbarWrapper }
