import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import "./Fullscreenmodal.css";


const WindowSwitchModal = ({ isModalOpen, setIsModalOpen, modalsRef }) => {


    const handleModalClose = () => {
        setIsModalOpen(false);
        modalsRef.current.switch_tab_modal = 0;
    };

    return (
        <Modal show={isModalOpen} onHide={null} backdrop="static" disableEscapeKeyDown disableBackdropClick>
            <Modal.Body style={{ border: '1px solid red' }} >

                <div>
                    <h2>Warning: Switching windows/tabs </h2>
                    <p>
                        Please note that repeatedly switching windows/tabs mode or staying out on different window instead of test page may be considered as cheating in the examination.
                    </p>
                    <p>Please stay on the test page until the completion of the test</p>
                    <button className='btn btn-danger' onClick={handleModalClose}>Close</button>
                </div>
            </Modal.Body>
        </Modal >
    );
};

export default WindowSwitchModal;
