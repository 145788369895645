import React, { FC, createContext, useContext } from "react";
import { useAppSelector } from "../../Redux/hooks";
import { WithChildren } from "../helpers";

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig";

type Props = {
  selectedLang: "en" | "hi";
};

const initialState: any = {
  selectedLang: "en",
};

export function getConfig(languageSelected: any): any {
  const ls: Props = languageSelected;
  if (ls.selectedLang != "en") {
    return { selectedLang: ls.selectedLang };
  } else {
    return initialState;
  }
}

// Side effect
export function setLanguage(lang: string) {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }));
  window.location.reload();
}

const I18nContext = createContext<Props>(initialState);

const useLang = () => {
  return useContext(I18nContext).selectedLang;
};

const MetronicI18nProvider: FC<WithChildren> = ({ children }) => {
  const fetchedoverviewdata = useAppSelector(
    (state) => state?.tests?.overviewdata
  );
  let language;
  if (fetchedoverviewdata.language === 2) {
    language = "hi";
  } else if (fetchedoverviewdata.language === 1) {
    language = "en";
  } else {
    language = "en";
  }
  // alert(language)

  const lang = getConfig({ selectedLang: `${language}` });
  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>;
};

export { MetronicI18nProvider, useLang };
