import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { useParams } from "react-router-dom";
import { handlecloseloader, handleopenloader } from "../Loader/Loader";
import { handleopensnackbar } from "../Snackbarwithmessages/Snackbarwithmessages";
import type { RootState } from "../store";

// Define a type for the slice state
interface testState {
  fetcheddataresult: [];
}

// Define the initial state using that type
const initialState: testState = {
  fetcheddataresult: [],
};

export const fetchresultpage = createAsyncThunk(
  "resultredux/fetchresultpage",
  (payload: any, { dispatch }) => {
    dispatch(handleopenloader());
    return axios
      .get(`/marksheet/getmycertificates`)
      .then(function (response) {
        dispatch(handlecloseloader());
        return response?.data;
      })
      .catch(function (e) {
        dispatch(handlecloseloader());

        const payloaddata = {
          messages: e?.response?.data?.msg || e?.msg,
          severity: "error",
        };

        dispatch(handleopensnackbar(payloaddata));
      });
  }
);

export const Resultpageslice = createSlice({
  name: "resultredux",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchresultpage.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.fetcheddataresult = action.payload;
      }
    );
  },
});

export default Resultpageslice.reducer;
