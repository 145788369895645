import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "./Test.css";
import { Params, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  fetchoverview,
  fetchoverviewpreview,
  testexists,
} from "../../../Redux/TestPage/TestSlice";
import Googletranslate from "../../../_metronic/layout/components/header/header-menus/Googletranslate";
import { AiFillHome } from "react-icons/ai";
import { handlestartrecording } from "../../../Redux/ScreenRecord/ScreenRecord";
import { IoMdCamera, IoMdCheckmark, IoMdClose } from "react-icons/io";
import { MdScreenShare } from "react-icons/md";
import Termsandconditionmodal from "../Termsandcondition/TermsAndconditionmodal";
import { handleopensnackbar } from "../../../Redux/Snackbarwithmessages/Snackbarwithmessages";
import Webcam from "react-webcam";
import AntiCheatTermsModal from "../testpage/modals/AntiCheatTermsModal";
import { useIntl } from "react-intl";
import {
  anitCheatPreRequisitiesCheckData,
  preRequisitiesDataCheckData,
} from "../testpage/pre-requisities-anitcheatdata";
import { Checkbox, FormControlLabel, FormGroup, useMediaQuery } from "@mui/material";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import AgreeTerms from "../testpage/PrequsitiesComponents/AgreeTerms";
import ShareCamera from "../testpage/PrequsitiesComponents/ShareCamera";
import ShareScreen from "../testpage/PrequsitiesComponents/ShareScreen";
import OtherPermissions from "../testpage/PrequsitiesComponents/OtherPermissions";
import { HeadingsTypography } from "../../../utils/StyledTypographyHeadings";

export default function PrerequisitePreview() {
  type fetchedoverviewtype = {
    conductedby: string;
    testname: string;
    testtype: string;
    timelimit: string;
    totalquestion: string;
  };
  const [nextvisiblity, setnextvisbility] = React.useState<number>(0);

  const [fetchedoverview, setfetchedoverview] = React.useState<
    fetchedoverviewtype | any
  >({});
  const { tokenid } = useParams();

  const [showTermsComp, setShowTermsComp] = React.useState(true);
  const [showCameraComp, setShowCameraComp] = React.useState(false);
  const [showScreenComp, setShowScreeComp] = React.useState(false);
  const [showOtherComp, setShowOtherComp] = React.useState(false);

  const intl = useIntl();

  const applicationMode = localStorage.getItem("application_mode");

  const MainLogoLight = "/media/logos/mtesthub-new-logo.svg";
  const MainLogoDark = "/media/logos/mtesthub-new-logo-dark.svg";

  const dispatch = useAppDispatch();
  const fetchedexistsdata = useAppSelector(
    (state) => state?.tests?.fetchedexists
  );
  const fetchedoverviewdata = useAppSelector(
    (state) => state?.tests?.overviewdata
  );

  React.useEffect(() => {
    const payload = {
      testid: tokenid,
    };

    dispatch(fetchoverviewpreview(payload));
  }, []);

  React.useEffect(() => {
    setfetchedoverview(fetchedoverviewdata);
  }, [fetchedoverviewdata]);

  const navigate = useNavigate();

  function askNotificationPermission(type: any) {
    // Check if the browser supports notifications
    if (!("Notification" in window)) {
      console.log("This browser does not support notifications.");
      return;
    }
    Notification.requestPermission().then((permission) => {
      // set the button to shown or hidden, depending on what the user answers
      if (permission !== "granted" && !type) {
        const datapayload = {
          messages:
            "You might miss out important notifications during test sessions, please dont use incognito mode for better experience",
          severity: "error",
        };
        dispatch(handleopensnackbar(datapayload));
        askNotificationPermission("second");
      }
    });
  }

  React.useEffect(() => {
    askNotificationPermission(null);
  }, [askNotificationPermission]);

  const [Recorder, setRecorder] = React.useState<any>("");

  const handleStartRecording = async () => {
    try {
      // Get screen stream
      let screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: {
          //@ts-ignore
          displaySurface: "monitor",
          width: { ideal: 960 },
          height: { ideal: 540 },
        },
      });

      // Check if the user has an audio input device
      const devices = await navigator.mediaDevices.enumerateDevices();
      const hasAudioDevice = devices.some(
        (device) => device.kind === "audioinput"
      );

      let audioStream = null;

      // Get audio stream from microphone if available
      if (hasAudioDevice) {
        //@ts-ignore
        audioStream = await navigator?.mediaDevices?.getUserMedia({
          audio: true,
        });
      }

      // Combine the screen and audio streams
      let combinedStream = new MediaStream();
      [screenStream, audioStream].forEach((stream) => {
        //@ts-ignore
        if (stream && stream.getTracks) {
          stream.getTracks().forEach((track) => combinedStream.addTrack(track));
        }
      });

      let recorder = new MediaRecorder(combinedStream);

      console.log(recorder);

      setRecorder(recorder);

      dispatch(handlestartrecording({ recordinstance: recorder }));
    } catch (error) {
      console.error("Error accessing user media:", error);
    }
  };

  const [termsagreed, settermsagreed] = React.useState({
    cameraterms: 0,
    screenterms: 0,
  });

  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  
  const [matches, setMatches] = React.useState<Boolean | null>(null);

  React.useEffect(() => {
    function isMobile() {
      const userAgent = navigator.userAgent.toLowerCase();
      return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    }
  
    if (isMobile()) {
      setMatches(true);
    } else {
      const desktopMediaQuery = window.matchMedia("(min-width: 1024px)");
  
      const handleMediaQueryChange = (e:any) => {
        setMatches(!e.matches);
      };
  
      setMatches(!desktopMediaQuery.matches);
  
      desktopMediaQuery.addEventListener("change", handleMediaQueryChange);
  
      return () => {
        desktopMediaQuery.removeEventListener("change", handleMediaQueryChange);
      };
    }
  }, []);

  const [showModal, setShowModal] = React.useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };
  const [showModal2, setShowModal2] = React.useState(false);

  const handleShowModal2 = () => {
    setShowModal2(true);
  };
  const [showModal3, setShowModal3] = React.useState(false);

  const handleShowModal3 = () => {
    setShowModal3(true);
  };

  const onchangetermshandler = (e: any, type: string) => {
    if (e.target.checked === true) {
      settermsagreed({ ...termsagreed, [type]: 1 });
    } else {
      settermsagreed({ ...termsagreed, [type]: 0 });
    }
  };

  function fullscreentogglehandler() {
    const fullscreenele: any = document.getElementById("forfullscreen");
    // if (fullscreenele.requestFullscreen) {
    fullscreenele.requestFullscreen();
    if (fullscreenele.webkitRequestFullscreen) {
      /* Safari */
      fullscreenele.webkitRequestFullscreen();
    } else if (fullscreenele.msRequestFullscreen) {
      /* IE11 */
      fullscreenele.msRequestFullscreen();
    }
  }

  React.useEffect(() => {
    if (fetchedoverviewdata?.success === false) {
      window.location.href = `/testoverview/${tokenid}`;
    }
  }, [fetchedoverviewdata, tokenid]);


  const manageStyle =
    fetchedoverviewdata?.setting?.allowscreenrecording == "true" ||
    fetchedoverviewdata?.setting?.randomphotos == "true" ||
    fetchedoverviewdata?.isvideoanswer ||
    fetchedoverviewdata?.setting?.live_proctoring_screen == "true" ||
    fetchedoverviewdata?.setting?.live_proctoring_camera == "true";

    return (
        <>
          <Box
        component="img"
        src={toAbsoluteUrl(applicationMode === "dark" ? MainLogoDark : MainLogoLight)}
        alt="mtesthub-logo"
        sx={{
          width: {xs: 150, md: 250},
          height: 60,
          position: "absolute",
          top: 2,
          left: {xs: 20, sm: 80},
        }}
      />
      

          {fetchedoverviewdata?.isvideoanswer ||
          (fetchedoverviewdata?.setting &&
            fetchedoverviewdata?.setting?.anti_cheating == "true") ||
          fetchedoverviewdata?.setting?.live_proctoring_camera ||
          fetchedoverviewdata?.setting?.live_proctoring_screen ? (
            <Box sx={{ width: "100% !important" }} id="forfullscreen">
              <Box
                sx={{
                  width: "100% !important",
                  // height: "100vh !important",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  paddingY: manageStyle ? 2 : 2,
                  paddingX: {xs: 2, md: 10},
                  marginTop: { xs: 8, md: 8, xl: 12 },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    padding: 1,
                    borderRadius: "10px",
                    border: applicationMode === "light" ? "2px solid #0b3aff" : "2px solid #ffffff",
                    boxShadow: "13px 16px 3px -5px rgba(11, 58, 255, 0.5)",
                  }}
                >
                  {!matches ? (
                    <>
                      <>
                        <HeadingsTypography
                          variant="h2"
                          sx={{
                            letterSpacing: "1px",
                            fontFamily: "Poppins, sans-serif",
                            mt: 2,
                          }}
                        >
                          {showTermsComp
                            ? "Rules"
                            : showCameraComp
                              ? "Camera Access"
                              : showScreenComp
                                ? "Screen Sharing"
                                : showOtherComp ? "Allow Other Required Permissions" : ""}
                          &nbsp;for {fetchedoverviewdata?.testname} test
                        </HeadingsTypography>

                        {showTermsComp && (
                          <AgreeTerms
                            setShowTermsComp={setShowTermsComp}
                            setCameraComp={setShowCameraComp}
                            setScreenComp={setShowScreeComp}
                            setShowOtherComp={setShowOtherComp}
                            tokenid={tokenid}
                            fullscreentogglehandler={fullscreentogglehandler}
                          />
                        )}

                        {showCameraComp && (
                          <ShareCamera
                            setScreenComp={setShowScreeComp}
                            setCameraComp={setShowCameraComp}
                            setShowOtherComp={setShowOtherComp}
                            setShowTermsComp={setShowTermsComp}
                            tokenid={tokenid}
                            fullscreentogglehandler={fullscreentogglehandler}
                          />
                        )}

                        {showScreenComp && (
                          <ShareScreen
                            setScreenComp={setShowScreeComp}
                            setShowOtherComp={setShowOtherComp}
                            setShowTermsComp={setShowTermsComp}
                            tokenid={tokenid}
                            Recorder={Recorder}
                            handleStartRecording={handleStartRecording}
                            fullscreentogglehandler={fullscreentogglehandler}
                          />
                        )}

                        {
                          showOtherComp && (
                            <OtherPermissions
                              setShowOtherComp={setShowOtherComp}
                              setShowTermsComp={setShowTermsComp}
                              tokenid={tokenid}
                              fullscreentogglehandler={fullscreentogglehandler}
                            />
                          )
                        }
                      </>
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "column",
                        marginTop: 2,
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          fontWeight: 800,
                          letterSpacing: "1px",
                          color: "red",
                          fontSize: {xs: 10, sm: 16}
                        }}
                      >
                        This test ({fetchedoverviewdata?.testname}) is not
                        attemptable on mobile/tablet devices kindly access it from
                        desktop or laptop{" "}
                      </Typography>
    
                      <button
                        className="styled-btn"
                        onClick={() => {
                          navigate(`/home/dashboard`);
                        }}
                      >
                        Go To Dashboard
                      </button>
                    </Box>
                  )}
                </Box>
              </Box>
    
              <Termsandconditionmodal show={show} handleClose={handleClose} />
              {/* <AntiCheatTermsModal
                type={1}
                showModal={showModal}
                setShowModal={setShowModal}
              />
              <AntiCheatTermsModal
                type={2}
                showModal={showModal2}
                setShowModal={setShowModal2}
              />
              <AntiCheatTermsModal
                type={3}
                showModal={showModal3}
                setShowModal={setShowModal3}
              /> */}
            </Box>
          ) : null}
        </>
      );
}
