import React, { Component } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useAppSelector } from "../../../../../Redux/hooks";


function Ckeditor(props) {
  const { value, name, onchange, disablestate } = props;
  
  const fetchedsetting = useAppSelector(
    (state) => state?.tests?.fetchedsetting
  );


  return (
    <div className="App">
      <CKEditor
        editor={ClassicEditor}
        data={value ? `${value}` : ""}
        config={{
          toolbar: [
            "heading",
            "|",
            "bold",
            "italic",
            "blockQuote",
            "numberedList",
            "bulletedList",
            "|",
            "undo",
            "redo",
          ],
        }}
        name={name}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          fetchedsetting?.copynpaste == "true" && editor.editing.view.document.on('clipboardInput', (event, data) => {
            event.preventDefault();
            alert('Pasting is disabled within the editor.');
          });

          fetchedsetting?.copynpaste == "true" && editor.editing.view.document.on('copy', (event, data) => {
            event.preventDefault();
            alert('Copying is disabled within the editor.');
          });
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          // 
          onchange(event, name, data, "ckeditor");
        }}
        // onBlur={(event, editor) => {
        //     
        // }}
        // onFocus={(event, editor) => {
        //     
        // }}
        disabled={disablestate}
      />
    </div>
  );
}

export default Ckeditor;
