import { Typography } from "@mui/material";
import axios from "axios";
import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import "./Modalfortest.css"

type Props = {
  show: boolean;
  handleClose: () => void;
  title: String;
  remarks: number;
  score: number;
  remarks_description: string;
};

const Modalforremarks = (props: Props) => {
  const { show, handleClose, title, remarks, score, remarks_description } =
    props;

  // alert(remarks);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ width: "100vw !important" }}>
          <Typography variant="h6" dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(remarks_description),
          }} style={{ padding: "40px" }}>


          </Typography>

          <Modal.Footer className="modalfooterparent">
            <p style={{ marginRight: "-20px", fontSize: "14px" }}>
              <span style={{ fontWeight: "bold" }}>
                Total Marks:{" "}
              </span>
              {score}
            </p>

            {remarks === 1 ? (
              <p className="bottomfootertext">
                <span style={{ fontWeight: "bold" }}>Remarks:</span>
                <span style={{ color: "green" }}> Passed </span>
              </p>
            ) : (
              <p className="bottomfootertext">
                <span style={{ fontWeight: "bold" }}>Remarks:</span>
                <span style={{ color: "red" }}> Failed </span>
              </p>
            )}
          </Modal.Footer>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Modalforremarks;
