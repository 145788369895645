import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import "./Fullscreenmodal.css";


const KickedUserModal = ({ setIsModalOpenKick, isModalOpenKick, org_name }) => {



    return (
        <Modal show={isModalOpenKick} onHide={null} backdrop="static" disableEscapeKeyDown disableBackdropClick>
            <Modal.Body style={{ border: '1px solid red' }}>

                <div>
                    <h2>Test Session Suspended</h2>
                    <p>
                        Your test session has been suspended due to activity requiring further investigation, in accordance with our testing integrity guidelines.

                        We understand this may feel unfair, and it's important to us that you have an opportunity to respond. You have the right to inquire or appeal this decision. Please contact <strong>{org_name}</strong>
                    </p>
                    <p>We appreciate your understanding and cooperation.</p>
                    <p>Best regards</p>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default KickedUserModal;
