import { Button } from "@mui/material";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useIntl } from "react-intl";
import { StyledTypography } from "../../../utils/StyledTypographyHeadings";

type Prop = {
  show: boolean;
  handleClose: () => void;
  submithandler: () => void;
};

export default function SubmitModal(props: Prop) {
  const { show, handleClose, submithandler } = props;
  const intl = useIntl();
  return (
    <>
      <Modal show={show}>
        <Modal.Body> <StyledTypography variant="body1">{intl.formatMessage({ id: "TEST.SUBMITMSG" })}</StyledTypography></Modal.Body>
        <Modal.Footer style={{display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 8}}>
          <Button
            onClick={handleClose}
            sx={{
              border: "1px solid #03045e",
              padding: "5px !important",
              fontSize: "12px !important",
              color: "#000",
              textTransform: "initial",
              width: 100,
              "&:hover": {
                opacity: 0.5,
              },
            }}
          >
           <StyledTypography>
              {intl.formatMessage({ id: "TEST.REVIEW" })}
           </StyledTypography>
          </Button>
          <Button
            id="submitbuttonmodal"
            onClick={submithandler}
            sx={{
              backgroundColor: "#03045e",
              padding: "5px !important",
              fontSize: "12px !important",
              textTransform: "initial",
              color: "#ffffff",
              width: 100,
              "&:hover": {
                backgroundColor: "#03045e",
                opacity: 0.7,
              },
            }}
          >
            <StyledTypography>
              {intl.formatMessage({ id: "TEST.SUBMITNOW" })}
            </StyledTypography>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
