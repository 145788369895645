/* eslint-disable jsx-a11y/anchor-is-valid */
import axios, { AxiosResponse } from "axios";
import React, { FC, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import {
  CardsWidget17,
  CardsWidget20,
  CardsWidget7,
  Questioncardwidget,
} from "../../../_metronic/partials/widgets";
import { answeredvalues, fetchdata } from "../../../Redux/TestPage/TestSlice";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { Button } from "react-bootstrap";
import { Online, Offline } from "react-detect-offline";
import { Alert, Snackbar } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
// import SubmitModal from "./SubmitModal";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { handleopensnackbar } from "../../../Redux/Snackbarwithmessages/Snackbarwithmessages";
import {
  handlecloseloader,
  handleopenloader,
} from "../../../Redux/Loader/Loader";
import { CameraModal } from "../../modules/auth/components/CameraModal";
import WebcamCapture from "../../modules/auth/components/MainCamera";
// import Camerafortest from "./Camerfortest/Camerafortest";
import { handlelocationpermission } from "../../../Redux/Checkpermissions/Permission";
import Dashboardcarousel from "./carousel-dashboard/Dashboard-carousel";
import "./Dashboard.css";
import Dashboardsearch from "./dashboard-searchbox/Dashboardsearch";
import { fetchassignedtestseries, fetchdashboard, fetchsubmittedtest } from "../../../Redux/Dashboard/Testseries";
import DashboardFilter from "./DashboardFilter/DashboardFIlter";
var Scroll = require("react-scroll");
var scroller = Scroll.scroller;
type Props = {};

const Testpage = (props: Props) => {
  const [fetchdata, setfetcheddata] = useState<any>({
    result: [],
    success: "",
    result2: [],
  });
  const [count, setcount] = useState([{ id: "" }]);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    const payload = {
      type: "all",
    };
    dispatch(fetchassignedtestseries(payload));
  }, []);

  const fetcheddata = useAppSelector(
    (state) => state.testseries.fetcheddataprivatedashboard
  );

  useEffect(() => {
    setfetcheddata(fetcheddata);
    fetchdata?.result2?.map((each: any) => {
      setcount(each?.id);
    });
  }, [fetcheddata]);

  let value = 0;

  return (
    <>
      {/* begin::Row */}
      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        {/* begin::Col */}
        <div>
          {/* add tests question here */}
          {/* <CardsWidget17 className="abc" /> */}
          {/* <div style={{ marginBottom: '20px' }}>
            <Dashboardcarousel />
          </div> */}
          {/* <Dashboardsearch /> */}
          {/* <div className="filterbuttonparentdiv">
            <DashboardFilter />
          </div> */}
        </div>
        <div className="seriesContainer">
          {fetchdata?.result?.map((each: any, index: any) => {
            console.log("🚀 organization_name", each?.organization_name)
            return (
              <>

                <div key={index} style={{ marginRight: "10px", marginBottom: "10px" }}>
                  <CardsWidget20
                    className="abc"
                    description={each?.test_description}
                    color="rgb(58, 51, 53)"
                    title={each?.test_name}
                    createdat={each?.created_at}
                    duration={each?.duration}
                    scheduled={each?.schedule_datetime}
                    img={""}
                    testid={each?.assesment_id}
                    test_settings={each?.test_settings}
                    istestseries={each?.multiple_time}
                    result_declare={each?.result_declare}
                    count={count}
                    id={each?.test_serires_id}
                    fetchdata={fetchdata?.result2}
                    preregistration_status={each?.ispreregistration}
                    preregistration_to={each?.preregistrationdatetimeto}
                    preregisteration_from={each?.preregistrationdatetimefrom}
                    fetchdataprewhole={fetchdata?.preresult}
                    organization_logo={each?.organization_logo}
                    organization_name={each?.organization_name}
                  />
                </div>
              </>
            );
          })}
          {fetchdata?.result?.length <= 0 ||
            !fetchdata ||
            (fetchdata?.success == false && (
              <>
                <div style={{ marginTop: "-40px" }}>
                  <p style={{ color: "red" }}>No test series found</p>
                </div>
              </>
            ))}
        </div>
        {/* end::Col */}
        <div className="submitdiv">{/* </Online> */}</div>
      </div>
      {/* <Online> */}
      {/* <SubmitModal handleClose={handleClose} show={show} submithandler={() => { submithandler(1) }} /> */}
      {/* <Offline>
        <Snackbar
          open={true}
          autoHideDuration={6000}
        // onClose={handleClose}
        // action={action}
        >
          <Alert variant="filled" severity="error">
            You are currently offline please turn on your internet else your
            exam won't be submitted
          </Alert>
        </Snackbar>
      </Offline> */}

      {/* end::Row */}

      {/* begin::Row */}
      <div className="row gx-5 gx-xl-10">
        {/* begin::Col */}
        <div className="col-xxl-6 mb-5 mb-xl-10">
          {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
      {/* {fetchedsetting?.randomphotos && <Camerafortest isready={isready} />} */}
    </>
  );
};

const PrivateTestDashboard: FC = () => {
  const intl = useIntl();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "Assigned Test" })}
      </PageTitle>

      <Testpage />
    </>
  );
};

export { PrivateTestDashboard };
