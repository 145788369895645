/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Params, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  FormControl,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FormControlLabel } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../Redux/hooks";
import "./Login.css";
import { loginfortest } from "../../../../Redux/AuthPage/AuthSlice";
import OtpInput from "react-otp-input";
import axios, { Axios } from "axios";
import { handleopensnackbar } from "../../../../Redux/Snackbarwithmessages/Snackbarwithmessages";
import {
  handlecloseloader,
  handleopenloader,
} from "../../../../Redux/Loader/Loader";
import { GoogleLoginButton } from "./GoogleLogin/GoogleLoginButton";
import GoogleOnetap from "./GoogleLogin/GoogleOnetap";
import { siteConfig } from "../../../../config/siteConfig";
import Stack from "@mui/material/Stack";
import styled from "@emotion/styled";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { StyledTypography } from "../../../../utils/StyledTypographyHeadings";

const CustomRadio = styled(Radio)({
  color: "#03045e",
  "&.Mui-checked": {
    color: "#03045e",
  },
});

export function Loginfortest() {
  const { testid }: any = useParams();
  const location = useLocation();
  if (location.pathname.includes("dashboard")) {
    localStorage.setItem("redirect_to", location.pathname);
  }

  useEffect(() => {
    if (location.pathname.includes("dashboard")) {
      setTimeout(() => {
        window.location.href = "/auth/1";
      }, 200);
    }
  }, []);

  type logintype = {
    email: string | null;
    otp: null | number;
    userimage: null | any;
  };
  type usertype = {
    phone: number | null;
    name: string | null;
    about_you_type: number | null;
    preparing_for_exam: number | null;
    college_school_company_name: number | null;
  };

  const dispatch = useAppDispatch();
  const [logindata, setlogindata] = useState<logintype>({
    email: null,
    otp: null,
    userimage: null,
  });
  const [userdata, setuserdata] = useState<any>({
    phone: null,
    name: null,
    about_you_type: null,
    preparing_for_exam: null,
    college_school_company_name: null,
  });
  const [userdata1, setuserdata1] = useState<any>({
    phone: null,
    name: null,
    about_you_type: 1,
    preparing_for_exam: null,
    college_school_company_name: null,
  });

  const [passwordType, setPasswordType] = useState<boolean>(true);
  const [loading, setloading] = useState<boolean>(false);
  const [userNotExistsError, setUserNotExistsError] = useState<boolean>(false);
  const [otpvalue, setotpvalue] = useState("");
  const navigate = useNavigate();
  const response: any = useAppSelector((state) => state.auth.response);

  const showPassword: () => void = () => {
    setPasswordType(!passwordType);
  };

  const loginfortestfunction = () => {
    if (!res?.userimageexists) {
      const payload = {
        email: logindata.email,
        otp: otpvalue,
        testid: testid,
      };
      dispatch(loginfortest(payload));
    } else {
      const payload = {
        email: logindata.email,
        otp: otpvalue,
        testid: testid,
      };
      dispatch(loginfortest(payload));
    }
  };

  const [res, setres] = useState<any>();
  const [userexists, setuserexists] = useState<boolean>(false);
  const generateOtp = (e: any, type?: string) => {
    e.preventDefault();
    dispatch(handleopenloader());
    if (type === "resendotp") {
      const payload = {
        email: logindata?.email,
      };
      axios
        .post(`/authuser/generateotp`, payload)
        .then((res) => {
          dispatch(handlecloseloader());

          if (res?.data?.success == "true") {
            const snackbarpayload = {
              messages: "new otp send",
              severity: "success",
            };
            dispatch(handleopensnackbar(snackbarpayload));
            setUserNotExistsError(false);
          }
        })
        .catch((err) => {
          dispatch(handlecloseloader());
          const snackbarpayload = {
            messages: err?.response?.data?.msg || err?.msg,
            severity: "error",
          };

          dispatch(handleopensnackbar(snackbarpayload));
        });
    } else {
      const payload = {
        email: logindata?.email,
      };
      axios
        .post(`/authuser/generateotp`, payload)
        .then((res) => {
          dispatch(handlecloseloader());

          if (res?.data?.success == "true") {
            setres(res?.data);
            if (res?.data?.userexists) {
              setuserexists(true);
              setUserNotExistsError(false);
            }
            nextpage();
          }
        })
        .catch((err) => {
          dispatch(handlecloseloader());
          const snackbarpayload = {
            messages: err?.response?.data?.msg || err?.msg,
            severity: "error",
          };

          if (err?.response && err?.response?.status === 404) {
            setUserNotExistsError(true);
          }
          dispatch(handleopensnackbar(snackbarpayload));
        });
    }
  };

  useEffect(() => {
    if (testid) {
      const queryParams = new URLSearchParams(location.search);
      if (queryParams) {
        axios
          .post(`/authuser/verifyuserexists`, { enc: queryParams.get("enc") })
          .then((res) => {
            if (res?.data?.userexists === true) {
              console.log(
                "🚀 ~ file: Loginfortest.tsx:163 ~ axios.post ~ res?.data?:",
                res?.data
              );
              // set localstorage the userdetails recieved from the backend
              // and then reload after setting
              localStorage.setItem(
                "userdetails",
                JSON.stringify(res?.data?.userdetails)
              );
              setTimeout(() => {
                window.location.reload();
              }, 500);
            } else {
              navigate(`/auth/${testid}/1`);
            }
          });

        console.log(
          "🚀 ~ file: TestOverview.tsx:45 ~   queryParams.get();:",
          queryParams.get("enc")
        );
      } else {
        if (!localStorage.getItem("userdetails")) {
          navigate(`/auth/${testid}/1`);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (res) {
      if (res.userimageexists) {
        if (response?.data) {
          localStorage.setItem("userdetails", JSON.stringify(response?.data));
          if (testid) {
            // alert(testid);
            window.location.href = `/testoverview/${testid}`;
          } else {
            window.location.href = `/home/dashboard`;
          }
          if (response?.data?.application_mode) {
            localStorage.setItem(
              "application_mode",
              JSON.stringify(response?.data?.application_mode).slice(1, -1)
            );
          }
        }
      } else if (!res.userimageexists) {
        if (response?.data) {
          localStorage.setItem("userdetails", JSON.stringify(response?.data));

          if (response?.data?.application_mode) {
            localStorage.setItem(
              "application_mode",
              JSON.stringify(response?.data?.application_mode).slice(1, -1)
            );
          }
        }

        const pathname = localStorage.getItem("redirect_to");

        if (localStorage.getItem("userdetails")) {
          if (!userexists) {
            if (testid) {
              navigate(`/auth/${testid}/3`);
            } else {
              navigate("/auth/3");
            }
          } else if (testid) {
            window.location.href = `/testoverview/${testid}`;
          } else if (pathname) {
            localStorage.removeItem("redirect_to");
            window.location.href = `${pathname}`;
          } else {
            window.location.href = "/home/dashboard";
          }
        }
        //after taking in auth 3 data if success for submit button

        // if (testid) {
        //   window.location.href = `/testoverview/${testid}`;
        // }
      }
    }

    // else {

    //   if (localStorage.getItem("userdetails")) {
    //     if (!userexists) {
    //       if (testid) {
    //         navigate(`/auth/${testid}/3`);
    //       } else {
    //         navigate("/auth/3");
    //       }
    //     } else if (testid) {
    //       // alert(testid);
    //       window.location.href = `/testoverview/${testid}`;
    //     } else {
    //       window.location.href = "/home/dashboard";
    //     }
    //   }
    //   //after taking in auth 3 data if success for submit button

    //   // if (testid) {
    //   //   window.location.href = `/testoverview/${testid}`;
    //   // }
    // }
  }, [response]);

  const onchangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    msg?: any
  ) => {
    setlogindata({ ...logindata, [e.target.name]: e.target.value });
  };
  const onchangeHandler2 = (e: any, msg?: any) => {
    setuserdata({ ...userdata, [e.target.name]: e.target.value });
    setuserdata1({});
  };

  const onchangeHandler1 = (e: any, msg?: any) => {
    setuserdata1({ ...userdata1, [e.target.name]: e.target.value });
  };

  //userdata -for name
  //userdata.test_preference -for 	about_you_type
  //about you type on basis of userdata.about_you_type populate page  4 for either preparing for exams i.e test_preference naming changes and college school comapny as creator organization just three naming changes and data type change in table

  const updateprofile = () => {

    const token = JSON.parse(
      localStorage.getItem("userdetails") as string
    )?.token;

    dispatch(handleopenloader());
    

    let payload;
    if (userdata1?.college_school_company_name) {
      payload = {
        name: userdata?.name?.trim(),
        about_you_type: userdata?.about_you_type,
        college_school_company_name: userdata1?.college_school_company_name,
        organisation_city: userdata1?.organisation_city,
        testid: testid,
      };
    } else {
      payload = {
        name: userdata?.name?.trim(),
        about_you_type: userdata?.about_you_type,
        preparing_for_exam: userdata1?.preparing_for_exam,
        testid: testid,
      };
    }

    axios
      .post("userprofile/updateprofile", payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res: any) => {
        dispatch(handlecloseloader());
        if (res?.data?.success == "true") {
          const payloaddata = {
            messages: "Updated profile successfully",
            severity: "success",
          };
          if (res?.data?.name) {
            const userdetails = JSON.parse(
              localStorage.getItem("userdetails") as string
            );
            const newuserdetails = { ...userdetails, name: res?.data?.name };
            localStorage.setItem("userdetails", JSON.stringify(newuserdetails));
          }

          dispatch(handleopensnackbar(payloaddata));
          if (testid) {
            window.location.href = `/testoverview/${testid}`;
          } else {
            window.location.href = "/home/dashboard";
          }
        } else if (res?.msg?.success == "false") {
          const payloaddata = {
            messages: res?.sqlMessage,
            severity: "error",
          };

          dispatch(handleopensnackbar(payloaddata));
        }
      })
      .catch((err) => {
        dispatch(handlecloseloader());
        const payloaddata = {
          messages: err?.response?.data?.msg || err?.msg,
          severity: "error",
        };

        dispatch(handleopensnackbar(payloaddata));
      });
  };

  const handleChange = (otp: string) => setotpvalue(otp);

  const [disabled, setisdisabled] = useState(true);

  useEffect(() => {
    if (Object.values(logindata).length >= 3) {
      setisdisabled(false);
    } else {
      setisdisabled(true);
    }
  }, [logindata]);

  const { id }: Params<string> = useParams();

  const nextpage: () => void = () => {
    if (id) {
      const intid = parseInt(id);
      if (testid) {
        navigate(`/auth/${testid}/${intid + 1}`);
      } else {
        navigate(`/auth/${intid + 1}`);
      }
    }
  };
  const previouspage: () => void = () => {
    if (id) {
      const intid = parseInt(id);
      if (testid) {
        navigate(`/auth/${testid}/${intid - 1}`);
      } else {
        navigate(`/auth/${intid - 1}`);
      }
    }
  };
  const [company, setcompany] = useState<any>([]);
  const [school, setschool] = useState<any>([]);
  const [companycity, setcompanycity] = useState<any>([]);
  const [schoolcity, setschoolcity] = useState<any>([]);
  const [alltestseriestypename, setalltestseriestypename] = useState<any>([]);
  useEffect(() => {
    if (id === "3") {
      if (!logindata.email) {
        navigate("/auth/1");
      }
    } else if (id === "4") {
      if (!logindata.email || !userdata.name) {
        navigate("/auth/1");
      }
    }
  }, [id]);
  useEffect(() => {
    //fetch school
    axios.get(`/userprofile/getallorganizationsname`).then((res) => {
      res?.data?.msg.map((each: any) => {
        if (each.organisation_type == 1) {
          school.push(each.organisation_name);
          schoolcity.push(each.city);
        } else if (each.organisation_type == 0) {
          company.push(each.organisation_name);
          companycity.push(each.city);
        }
      });
    });
    axios.get(`/userprofile/getalltestseriesname`).then((res) => {
      res?.data?.msg.map((each: any) => {
        alltestseriestypename.push(each?.name);
      });
    });
    //fetch college and set it to both state
  }, []);

  // and in db store  organization at its and college/school as well at same
  //and for preparing for exams store it in test preffered

  const handleOnSearch = (string: any, results: any) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    // setsearchpayload({ query: string });
  };

  const searchhandler = () => {};

  const formatResult = (item: any) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
      </>
    );
  };

  const handlePaste = (event: any) => {
    const data = event.clipboardData.getData("text");
    handleChange(data);
  };

  const [isreg, setisreg] = useState(false);

  return (
    <Box
      sx={{
        padding: 2,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        border: "2px solid #03045e",
        borderRadius: "20px !important",
        gap: 1,
        backgroundColor: "#ffff !important",
        zIndex: 1,
      }}
      id="kt_login_signin_form"
    >
      {/* begin::Heading */}
      <div className="text-center" id="logindiv">
        <Box
          component="img"
          alt="mtestHub Logo"
          src={toAbsoluteUrl("/media/logos/mtesthub-new-logo.png")}
          className="app-sidebar-logo-default"
          sx={{
            width: 180,
            height: 30,
            padding: 0,
            transition: "all 0.5s ease-in-out",
            mb: 2,
          }}
        />{" "}
        {id === "1" && !isreg && (
          <Typography
            variant="h5"
            sx={{
              fontWeight: 800,
              letterSpacing: "1px",
            }}
            className="tracking-wide"
          >
            Sign in to continue
          </Typography>
        )}{" "}
        {id === "1" && isreg && (
          <Typography
            variant="h5"
            sx={{
              fontWeight: 800,
            }}
            className="tracking-wide"
          >
            Create a new account to continue
          </Typography>
        )}{" "}
        {id === "2" && (
          <Typography
            variant="h5"
            sx={{
              fontWeight: 800,
              color: "#03045e",
            }}
            className="tracking-wide"
          >
            The OTP is sent to your email
          </Typography>
        )}{" "}
        {(id === "3" || id === "4") && (
          <Typography
            variant="h5"
            sx={{
              fontWeight: 800,
            }}
            className="tracking-wide"
          >
            Let us know more about you
          </Typography>
        )}{" "}
        {id !== "3" && id !== "4" ? (
          testid ? (
            <div
              className="fw-semibold fs-6"
              style={{ color: "grey", fontWeight: "500" }}
            >
              To start the test
            </div>
          ) : null
        ) : null}
      </div>
      {/* begin::Heading */}

      {/* begin::Form group */}
      {id === "1" && (
        <>
          <Box
            component="form"
            sx={{ width: "100%", padding: 2, zIndex: 1 }}
            onSubmit={(e: any) => {
              e.preventDefault();
              document?.getElementById("kt_sign_in_submit")?.click();
            }}
          >
            <TextField
              name="email"
              label="Email address"
              value={logindata.email}
              onChange={onchangeHandler}
              type="email"
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#03045e",
                    borderRadius: 3,
                  },
                  "&:hover fieldset": {
                    borderColor: "#03045e",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#03045e",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#03045e",
                    opacity: 1,
                  },
                },
              }}
            />

            {userNotExistsError && (
              <StyledTypography
                variant="h6"
                sx={{ my: 2, color: "red" }}
                className="text-center"
                id="notfound"
              >
                User with this email not found! Please register first.
              </StyledTypography>
            )}
            <LoadingButton
              type="button"
              fullWidth
              size="large"
              variant="contained"
              loading={loading}
              onClick={(e) => {
                generateOtp(e);
              }}
              id="kt_sign_in_submit"
              className="login-btn"
            >
              {isreg && <span className="indicator-label">Register Now</span>}
              {!isreg && <span className="indicator-label">Login</span>}
            </LoadingButton>
          </Box>
        </>
      )}
      {id == "1" && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{ marginBottom: "10px", marginTop: "8px" }}
            className="title"
          >
            {!isreg && (
              <h5 style={{ color: "black" }}>
                Don't have an account{" "}
                <span
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    dispatch(handleopenloader());
                    setTimeout(() => {
                      setisreg(true);
                      dispatch(handlecloseloader());
                    }, 1000);
                  }}
                >
                  register now
                </span>
              </h5>
            )}
            {isreg && (
              <h5 style={{ color: "black" }}>
                Already have an account{" "}
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => {
                    dispatch(handleopenloader());
                    setTimeout(() => {
                      setisreg(false);
                      dispatch(handlecloseloader());
                    }, 1000);
                  }}
                >
                  login
                </span>
              </h5>
            )}
          </div>
        </div>
      )}
      {/* {id == "1" && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{ marginBottom: "10px", marginTop: "8px" }}
            className="title"
          >
            <h6 style={{ color: "grey" }}>OR</h6>
          </div>
        </div>
      )} */}
      {/* <GoogleOnetap testid={testid} userexists={userexists} />
      {id == "1" && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div id="Loginbutton" style={{ marginBottom: "10px" }}>
            <GoogleLoginButton userexists={userexists} testid={testid} />
          </div>
        </div>
      )} */}

      {id === "2" && (
        <>
          <Box
            component="form"
            sx={{
              width: "100%",
              padding: 1,
              zIndex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
            onSubmit={(e: any) => {
              e.preventDefault();
              document?.getElementById("kt_sign_in_submit")?.click();
            }}
          >
            <FormControl
              id="otpdiv"
              sx={{
                width: "386px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              variant="outlined"
            >
              <OtpInput
                value={otpvalue}
                onChange={handleChange}
                numInputs={6}
                renderSeparator={<span>-</span>}
                renderInput={(props: any) => <input {...props} />}
                onPaste={handlePaste}
                shouldAutoFocus={true}
              />
            </FormControl>
            <div className="d-grid mb-5">
              <input
                type="button"
                className="btn btn-ashoi"
                value={"Resend OTP"}
                onClick={(e) => {
                  generateOtp(e, "resendotp");
                }}
                style={{ marginTop: "4px" }}
              />
            </div>

            <p style={{ textAlign: "center" }}>
              {" "}
              Not recieved otp? Ensure correct email address {logindata.email}
            </p>

            {!res?.userimageexists && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  gap: 2,
                }}
              >
                <LoadingButton
                  type="submit"
                  loading={loading}
                  // onClick={() => { loginfortestfunction(); }}
                  id="kt_sign_in_submit"
                  className="btn"
                  disabled={disabled}
                  onClick={loginfortestfunction}
                  sx={{
                    flex: 1,
                    borderRadius: "8px",
                    backgroundColor: "#03045e !important",
                    color: "white !important",
                    fontWeight: "bold !important",
                    padding: "8px 16px !important",
                    fontSize: "16px !important",
                    letterSpacing: "1px !important",
                    textTransform: "initial !important",
                    "&:hover": {
                      opacity: 0.8,
                      backgroundColor: "#03045e !important",
                    },
                  }}
                >
                  <span className="indicator-label">Continue</span>
                </LoadingButton>
                <input
                  type="button"
                  className="btn"
                  value={"Go Back"}
                  onClick={previouspage}
                  style={{
                    marginTop: "4px",
                    flex: 1,
                    border: "1px solid #03045e",
                    color: "#000",
                    fontWeight: "bold",
                    letterSpacing: "1px",
                  }}
                />
              </Box>
            )}
            {res?.userimageexists && (
              <div className="d-grid mb-10">
                <LoadingButton
                  type="submit"
                  loading={loading}
                  // onClick={() => { loginfortestfunction(); }}
                  id="kt_sign_in_submit"
                  className="btn btn-primary"
                  disabled={disabled}
                  onClick={loginfortestfunction}
                >
                  <span className="indicator-label">Continue</span>
                </LoadingButton>
                <input
                  type="button"
                  className="btn btn-secondary"
                  value={"Go Back"}
                  onClick={previouspage}
                  style={{ marginTop: "4px" }}
                />
              </div>
            )}
          </Box>

          {/* end::Form group */}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {/* <input type="checkbox" id="showpass" />
        <label htmlFor="showpass" style={{ cursor: 'pointer', marginLeft: '2px' }}>
          Show Password
        </label> */}
          </div>

          {/* begin::Wrapper */}
          {/* end::Wrapper */}

          {/* begin::Action */}
        </>
      )}
      {id === "3" && (
        <>
          <div className="fv-row mb-8 ">
            {/* <div style={{ marginTop: '10px', marginBottom: '10px' }}>

              <TextField id="outlined-basic" value={userdata.phone} onChange={onchangeHandler2} label="Phone No" type='number' fullWidth variant="outlined" name="phone" />
              <small id="emailHelp" className="form-text text-muted">For recieving test schedule noifications.</small>



            </div> */}

            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
              <TextField
                id="outlined-basic"
                value={userdata.name}
                onChange={onchangeHandler2}
                label="Full Name"
                type="text"
                fullWidth
                variant="outlined"
                name="name"
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#03045e",
                      borderRadius: 3,
                    },
                    "&:hover fieldset": {
                      borderColor: "#03045e",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#03045e",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "#03045e",
                      opacity: 1,
                    },
                  },
                }}
              />
              {/* value={logindata.name} */}
            </div>

            <div
              id="preferswitch"
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="about_you_type"
                  onChange={onchangeHandler2}
                  value={userdata?.about_you_type}
                >
                  <FormControlLabel
                    value="1"
                    control={<CustomRadio />}
                    label="I am student preparing for exams and want to use this platform for test series, live tests etc"
                  />
                  <FormControlLabel
                    value="2"
                    control={<CustomRadio />}
                    label="I am a college student"
                  />
                  <FormControlLabel
                    value="3"
                    control={<CustomRadio />}
                    label="I am a School student/parent"
                  />
                  <FormControlLabel
                    value="4"
                    control={<CustomRadio />}
                    label="I am a candidate preparing for interviews"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div
              id="preferswitch"
              style={{
                marginTop: "6px",
                marginBottom: "10px",
                display: "flex",
                justifyContent: "center",
                marginLeft: "-10px",
              }}
            >
              {/* <p style={{ color: "black", alignItems: "center", display: 'flex' }}>I am a
                <span style={{ marginLeft: '4px' }}>
                  <Form.Select aria-label="Default select example" name="test_preference" onChange={onchangeHandler1}>
                    <option value="1">student</option>
                    <option value="2">student preparing</option>
                    <option value="3">candidate preparing</option>
                  </Form.Select>
                </span>
              </p>

              {userdata1?.test_preference == 1 &&
                <p style={{ color: "black", alignItems: "center", display: 'flex', marginLeft: '4px' }}> from
                  <span style={{ marginLeft: '4px' }}>
                    <Form.Select className="selectlogin" aria-label="Default select example" name="creator_organization" onChange={onchangeHandlerOrganization}>
                      <option >select a organisation</option>
                      {school.map((each: any) => {
                        return (<>
                          <option value={each?.id}>{each?.organisation_name}</option>
                        </>)
                      })
                      }
                    </Form.Select>
                  </span>
                </p>

              }

              {userdata1?.test_preference == 2 && <p style={{ color: "black", alignItems: "center", display: 'flex', marginLeft: '4px' }}> for
                <span style={{ marginLeft: '4px' }}>
                  <Form.Select className="selectlogin" aria-label="Default select example" name="preffered_series" onChange={onchangeHandler2}>
                    <option >select a testseries</option>

                    {alltestseriestypename.map((each: any) => {
                      return (
                        <>
                          <option value={each?.id}>{each?.name}</option>

                        </>
                      )
                    })
                    }
                  </Form.Select>
                </span>
              </p>}
              {userdata1?.test_preference == 3 && <p style={{ color: "black", alignItems: "center", display: 'flex', marginLeft: '4px' }}> for
                <span style={{ marginLeft: '4px' }}>
                  <Form.Select className="selectlogin" aria-label="Default select example" name="creator_organization" onChange={onchangeHandlerOrganization}>
                    <option >select a organization</option>

                    {company.map((each: any) => {
                      return (
                        <>
                          <option value={each?.id}>{each?.organisation_name}</option>
                        </>
                      )

                    })
                    }
                  </Form.Select>
                </span>
              </p>} */}
            </div>
          </div>

          {/* end::Wrapper */}

          {/* begin::Action */}
          {!res?.userimageexists && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                gap: 2,
              }}
            >
              <LoadingButton
                type="submit"
                loading={loading}
                id="kt_sign_in_submit"
                className="btn"
                disabled={!userdata.name?.trim() || !userdata?.about_you_type}
                onClick={nextpage}
                sx={{
                  flex: 1,
                  borderRadius: "8px",
                  backgroundColor: "#03045e !important",
                  color: "white !important",
                  fontWeight: "bold !important",
                  padding: "8px 16px !important",
                  fontSize: "16px !important",
                  letterSpacing: "1px !important",
                  textTransform: "initial !important",
                  "&:hover": {
                    opacity: 0.8,
                    backgroundColor: "#03045e !important",
                  },
                }}
              >
                <span className="indicator-label">Continue</span>
              </LoadingButton>
              <input
                type="button"
                className="btn btn-secondary"
                value={"Go Back"}
                onClick={previouspage}
                style={{
                  marginTop: "4px",
                  flex: 1,
                  border: "1px solid #03045e",
                  color: "#000",
                  fontWeight: "bold",
                  letterSpacing: "1px",
                }}
              />
            </Box>
          )}
          {res?.userimageexists && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                gap: 2,
              }}
            >
              <LoadingButton
                type="submit"
                loading={loading}
                // onClick={() => { loginfortestfunction(); }}
                style={{ width: "60vw" }}
                id="kt_sign_in_submit"
                className="btn "
                disabled={logindata.userimage == null ? true : false}
                onClick={nextpage}
                sx={{
                  flex: 1,
                  borderRadius: "8px",
                  backgroundColor: "#03045e !important",
                  color: "white !important",
                  fontWeight: "bold !important",
                  padding: "8px 16px !important",
                  fontSize: "16px !important",
                  letterSpacing: "1px !important",
                  textTransform: "initial !important",
                  "&:hover": {
                    opacity: 0.8,
                    backgroundColor: "#03045e !important",
                  },
                }}
              >
                <span className="indicator-label">Continue</span>
              </LoadingButton>
              <input
                type="button"
                className="btn"
                value={"Go Back"}
                onClick={previouspage}
                style={{
                  marginTop: "4px",
                  flex: 1,
                  border: "1px solid #03045e",
                  color: "#000",
                  fontWeight: "bold",
                  letterSpacing: "1px",
                }}
              />
            </Box>
          )}
        </>
      )}
      {id === "4" && (
        <>
          <Box sx={{ width: "100%" }}>
            {!userdata?.about_you_type && (
              <p style={{ color: "red" }}>
                no option selected go back and select it please
              </p>
            )}
            <div
              id="preferswitch"
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {userdata?.about_you_type == 1 && (
                <div style={{ width: "100vw" }}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={alltestseriestypename.map((option: any) => option)}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        label="Which exams you are preparing for"
                        name="preparing_for_exam"
                        onChange={onchangeHandler1}
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#03045e",
                              borderRadius: 3,
                            },
                            "&:hover fieldset": {
                              borderColor: "#03045e",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#03045e",
                            },
                            "& .MuiInputBase-input::placeholder": {
                              color: "#03045e",
                              opacity: 1,
                            },
                          },
                        }}
                      />
                    )}
                  />
                </div>
              )}
              {userdata?.about_you_type == 4 && (
                <div style={{ width: "100vw" }}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={company.map((option: any) => option)}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        label="mention the company name you wish to get in"
                        name="college_school_company_name"
                        onChange={onchangeHandler1}
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#03045e",
                              borderRadius: 3,
                            },
                            "&:hover fieldset": {
                              borderColor: "#03045e",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#03045e",
                            },
                            "& .MuiInputBase-input::placeholder": {
                              color: "#03045e",
                              opacity: 1,
                            },
                          },
                        }}
                      />
                    )}
                  />
                </div>
              )}
            </div>

            {userdata?.about_you_type == 2 && (
              <div style={{ display: "block", marginBottom: "10px" }}>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  options={school.map((option: any) => option)}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label="college name"
                      name="college_school_company_name"
                      onChange={onchangeHandler1}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#03045e",
                            borderRadius: 3,
                          },
                          "&:hover fieldset": {
                            borderColor: "#03045e",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#03045e",
                          },
                          "& .MuiInputBase-input::placeholder": {
                            color: "#03045e",
                            opacity: 1,
                          },
                        },
                      }}
                    />
                  )}
                />
              </div>
            )}
            {userdata?.about_you_type == 2 && (
              <div style={{ display: "block" }}>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  //city here
                  options={companycity.map((option: any) => option)}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label="city"
                      name="organisation_city"
                      onChange={onchangeHandler1}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#03045e",
                            borderRadius: 3,
                          },
                          "&:hover fieldset": {
                            borderColor: "#03045e",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#03045e",
                          },
                          "& .MuiInputBase-input::placeholder": {
                            color: "#03045e",
                            opacity: 1,
                          },
                        },
                      }}
                    />
                  )}
                />
              </div>
            )}
            {userdata?.about_you_type == 3 && (
              <div style={{ display: "block", marginBottom: "10px" }}>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  options={school.map((option: any) => option)}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label="school name"
                      name="college_school_company_name"
                      onChange={onchangeHandler1}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#03045e",
                            borderRadius: 3,
                          },
                          "&:hover fieldset": {
                            borderColor: "#03045e",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#03045e",
                          },
                          "& .MuiInputBase-input::placeholder": {
                            color: "#03045e",
                            opacity: 1,
                          },
                        },
                      }}
                    />
                  )}
                />
              </div>
            )}
            {userdata?.about_you_type == 3 && (
              <div style={{ display: "block" }}>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  //city here
                  options={schoolcity.map((option: any) => option)}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label="city"
                      name="city"
                      onChange={onchangeHandler1}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#03045e",
                            borderRadius: 3,
                          },
                          "&:hover fieldset": {
                            borderColor: "#03045e",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#03045e",
                          },
                          "& .MuiInputBase-input::placeholder": {
                            color: "#03045e",
                            opacity: 1,
                          },
                        },
                      }}
                    />
                  )}
                />
              </div>
            )}

            <div
              id="preferswitch"
              style={{
                marginTop: "6px",
                marginBottom: "10px",
                display: "flex",
                justifyContent: "center",
                marginLeft: "-10px",
              }}
            >
              {/* <p style={{ color: "black", alignItems: "center", display: 'flex' }}>I am a
                <span style={{ marginLeft: '4px' }}>
                  <Form.Select aria-label="Default select example" name="test_preference" onChange={onchangeHandler1}>
                    <option value="1">student</option>
                    <option value="2">student preparing</option>
                    <option value="3">candidate preparing</option>
                  </Form.Select>
                </span>
              </p>

              {userdata1?.test_preference == 1 &&
                <p style={{ color: "black", alignItems: "center", display: 'flex', marginLeft: '4px' }}> from
                  <span style={{ marginLeft: '4px' }}>
                    <Form.Select className="selectlogin" aria-label="Default select example" name="creator_organization" onChange={onchangeHandlerOrganization}>
                      <option >select a organisation</option>
                      {school.map((each: any) => {
                        return (<>
                          <option value={each?.id}>{each?.organisation_name}</option>
                        </>)
                      })
                      }
                    </Form.Select>
                  </span>
                </p>

              }

              {userdata1?.test_preference == 2 && <p style={{ color: "black", alignItems: "center", display: 'flex', marginLeft: '4px' }}> for
                <span style={{ marginLeft: '4px' }}>
                  <Form.Select className="selectlogin" aria-label="Default select example" name="preffered_series" onChange={onchangeHandler2}>
                    <option >select a testseries</option>

                    {alltestseriestypename.map((each: any) => {
                      return (
                        <>
                          <option value={each?.id}>{each?.name}</option>

                        </>
                      )
                    })
                    }
                  </Form.Select>
                </span>
              </p>}
              {userdata1?.test_preference == 3 && <p style={{ color: "black", alignItems: "center", display: 'flex', marginLeft: '4px' }}> for
                <span style={{ marginLeft: '4px' }}>
                  <Form.Select className="selectlogin" aria-label="Default select example" name="creator_organization" onChange={onchangeHandlerOrganization}>
                    <option >select a organization</option>

                    {company.map((each: any) => {
                      return (
                        <>
                          <option value={each?.id}>{each?.organisation_name}</option>
                        </>
                      )

                    })
                    }
                  </Form.Select>
                </span>
              </p>} */}
            </div>
          </Box>

          {/* end::Wrapper */}

          {/* begin::Action */}
          {!res?.userimageexists && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                gap: 2,
              }}
            >
              <LoadingButton
                type="submit"
                loading={loading}
                id="kt_sign_in_submit"
                className="btn"
                disabled={disabled}
                onClick={updateprofile}
                sx={{
                  flex: 1,
                  borderRadius: "8px",
                  backgroundColor: "#03045e !important",
                  color: "white !important",
                  fontWeight: "bold !important",
                  padding: "8px 16px !important",
                  fontSize: "16px !important",
                  letterSpacing: "1px !important",
                  textTransform: "initial !important",
                  "&:hover": {
                    opacity: 0.8,
                    backgroundColor: "#03045e !important",
                  },
                }}
              >
                <span className="indicator-label">Continue</span>
              </LoadingButton>
              <input
                type="button"
                className="btn"
                value={"Go Back"}
                onClick={previouspage}
                style={{
                  marginTop: "4px",
                  flex: 1,
                  border: "1px solid #03045e",
                  color: "#000",
                  fontWeight: "bold",
                  letterSpacing: "1px",
                }}
              />
            </Box>
          )}
          {res?.userimageexists && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                gap: 2,
              }}
            >
              <LoadingButton
                type="submit"
                loading={loading}
                // onClick={() => { loginfortestfunction(); }}
                style={{ width: "60vw" }}
                id="kt_sign_in_submit"
                className="btn"
                onClick={loginfortestfunction}
                sx={{
                  flex: 1,
                  borderRadius: "8px",
                  backgroundColor: "#03045e !important",
                  color: "white !important",
                  fontWeight: "bold !important",
                  padding: "8px 16px !important",
                  fontSize: "16px !important",
                  letterSpacing: "1px !important",
                  textTransform: "initial !important",
                  "&:hover": {
                    opacity: 0.8,
                    backgroundColor: "#03045e !important",
                  },
                }}
              >
                <span className="indicator-label">Continue</span>
              </LoadingButton>
              <input
                type="button"
                className="btn"
                value={"Go Back"}
                onClick={previouspage}
                style={{
                  marginTop: "4px",
                  flex: 1,
                  border: "1px solid #03045e",
                  color: "#000",
                  fontWeight: "bold",
                  letterSpacing: "1px",
                }}
              />
            </Box>
          )}
        </>
      )}

      {/* end::Action */}

      {/* <div className="text-gray-500 text-center fw-semibold fs-6">
        Not registered yet?{" "}
        <Link to="/auth/registration/1" className="link-primary">
          Sign up
        </Link>
      </div> */}
    </Box>
  );
}
