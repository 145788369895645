/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../helpers";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React, { useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import { fetchdata } from "../../../../../Redux/TestPage/TestSlice";
import { sampledatafullpayloadstate } from "./Questionssampledata";
import { FormControl, FormLabel } from "react-bootstrap";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { MdOutlineCancel, MdOutlineCheckCircle } from "react-icons/md";
import { relative } from "path";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./questioncard.css";
import Ckeditor from "../../../../../app/modules/auth/components/Ckeditor/Ckeditor";
import DOMPurify from "dompurify";
import { StyledTypography } from "../../../../../utils/StyledTypographyHeadings";
import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css"; // Import the CodeMirror theme (e.g., material)
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/python/python.js";
import "codemirror/mode/php/php.js";
import "codemirror/mode/jsx/jsx.js";
import CodeLanguageEnum from "./codingLanguages.enums";

var Scroll = require("react-scroll");
var Element = Scroll.Element;

type Props = {
  className?: string;
  srno: string;
  question: string;
  option: any[];
  user_answered?: any;
  iscorrect?: number;
  correct_answer?: any;
  sortno: any;
  question_type: number;
  question_image: any;
  answer_explanation: any;
  marks: any;
  correct_question_sort_no: any;
  mark_obtained: any;
  ai_interview_result: any;
};

function AIInterviewResult({ ai_interview_result }: any) {
  let resultContent;

  try {
    const parsedResult = ai_interview_result ? JSON.parse(ai_interview_result) : null;

    if (parsedResult && parsedResult.success) {
      // Render the result if the success flag is true
      resultContent = (
        <>
          <StyledTypography variant="body1" component="div">
            <strong>Body Language - {parsedResult?.result.body_language?.result}</strong>
          </StyledTypography>
          <StyledTypography variant="body2" component="div">
            Feedback - {parsedResult?.result.body_language?.feedback}
          </StyledTypography>
          <StyledTypography variant="body1" component="div">
            <strong>Communication Skill - {parsedResult?.result?.communication_skill?.result}</strong>
          </StyledTypography>
          <StyledTypography variant="body2" component="div">
            Feedback - {parsedResult?.result?.communication_skill?.feedback}
          </StyledTypography>
        </>
      );
    } else if (parsedResult && parsedResult?.error) {
      // Display error message if success is false
      resultContent = (
        <StyledTypography variant="body1" color="error" component="div">
          Error: {parsedResult?.error}
        </StyledTypography>
      );
    } else {
      resultContent = (
        <StyledTypography variant="body1" color="textSecondary" component="div">
          No result found
        </StyledTypography>
      );
    }
  } catch (error) {
    // Handle JSON parsing errors or unexpected formats
    resultContent = (
      <StyledTypography variant="body1" color="error" component="div">
        Error: Invalid result format
      </StyledTypography>
    );
  }

  return (
    <div>
      <StyledTypography variant="h5" component="div">
        Result:
      </StyledTypography>
      {resultContent}
    </div>
  );
}

export const Questioncardwidgetforallans = (props: Props) => {
  const replaceUnderlines = (
    text: string,
    values: { [key: number]: string }
  ): string => {
    // Split the text by the placeholder for underlines
    const parts = text.split("_ _ _ _ _");

    // Iterate over the parts and add replacements where applicable
    return parts
      .map((part, index) => {
        // Append the part
        let result = part;

        // Only replace if the index is within the user_answered values
        if (index in values) {
          result += `<u>${values[index]}</u>`;
        } else if (index < parts.length - 1) {
          // If no value is available, add the placeholder
          result += "_ _ _ _ _";
        }

        return result;
      })
      .join("");
  };

  // Clean HTML content
  const cleanHTML = (html: string) => DOMPurify.sanitize(html);

  const isCorrect = (userAns: string, optionAns: string) => {
    // Clean and compare the HTML content in both user answer and option
    const cleanUserAns = userAns.replace(/<[^>]+>/g, "").trim();
    const cleanOptionAns = optionAns.replace(/<[^>]+>/g, "").trim();
    return cleanUserAns === cleanOptionAns;
  };

  let calculatedHeight;

  if (props.question_type == 8) {
    const numberOfLines = props.user_answered?.stdout?.split("\n").length;
    const lineHeight = 20; // You can adjust this based on your theme's line height
    calculatedHeight = `${numberOfLines * lineHeight}px`;
  }

  return (
    <Element
      name={`${props.srno}-id`}
      className="element"
      id={`${props.srno}-id`}
    >
      <Card id={`${props.srno}- id`} sx={{ minWidth: 275, marginTop: 2 }}>
        <CardContent>
          <StyledTypography
            sx={{ fontSize: 14 }}
            color="text.secondary"
            gutterBottom
          >
            Question.
            {
              props?.question_type != 9 && <div
                style={{
                  position: "absolute",
                  right: "50px",
                  marginTop: "-20px",
                }}
              >
                {props?.mark_obtained} / {props?.marks} Marks
              </div>
            }
          </StyledTypography>

          <StyledTypography variant="h5" component="div">
            {props?.question_type === 6 ? (
              <div
                className="fill-in-the-blank-question"
                dangerouslySetInnerHTML={{
                  __html: cleanHTML(
                    replaceUnderlines(props?.question, props?.user_answered)
                  ),
                }}
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: cleanHTML(props?.question) }}
              />
            )}
          </StyledTypography>

          <StyledTypography variant="body2">
            {props?.option && props.question_type == 1 ? (
              props?.option.map((each: any, index: any) => {
                return (
                  <>
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <FormControlLabel
                        checked={
                          props.user_answered == each?.srno ? true : false
                        }
                        style={{
                          display: "inline-block",
                          marginRight: "0px",
                        }}
                        id={`${each?.srno} `}
                        name={props.sortno}
                        value={each?.srno}
                        control={<Radio />}
                        label=""
                      />
                      <div
                        className="optionparentdiv"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(each?.option),
                        }}
                      >
                        {/* {props?.datawhole?.question} */}
                      </div>

                      {props.user_answered == each?.srno &&
                        props?.correct_answer && (
                          <>
                            {props?.iscorrect === 1 ? (
                              <span
                                style={{
                                  position: "relative",
                                  marginLeft: "2px",
                                }}
                              >
                                <MdOutlineCheckCircle fill="green" size={24} />
                              </span>
                            ) : (
                              <span>
                                <MdOutlineCancel
                                  style={{
                                    position: "relative",
                                    marginLeft: "2px",
                                  }}
                                  fill="red"
                                  size={24}
                                />
                              </span>
                            )}
                          </>
                        )}
                    </Box>
                  </>
                );
              })
            ) : props?.option && props.question_type == 2 ? (
              props?.option.map((each: any, index: any) => {
                return (
                  <>
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <FormControlLabel
                        checked={
                          !props?.user_answered
                            ? false
                            : props?.user_answered[each?.srno] == 1
                              ? true
                              : false
                        }
                        style={{
                          display: "inline-block",
                          marginRight: "0px",
                        }}
                        id={`${each?.srno} `}
                        name={props.sortno}
                        value={each?.srno}
                        control={<Checkbox />}
                        label=""
                      />
                      <div
                        className="optionparentdiv"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(each?.option),
                        }}
                      >
                        {/* {props?.datawhole?.question} */}
                      </div>

                      {props?.correct_answer &&
                        JSON.parse(props?.correct_answer).includes(
                          `${each?.srno}`
                        ) ? (
                        <span
                          style={{
                            position: "relative",
                            marginLeft: "2px",
                          }}
                        >
                          <MdOutlineCheckCircle fill="green" size={24} />
                        </span>
                      ) : null}
                    </Box>
                  </>
                );
              })
            ) : props.question_type == 3 ? (
              <>
                <input
                  // defaultValue={}
                  disabled
                  value={props.user_answered}
                  name={props?.question}
                  style={{
                    width: "100%",
                    minHeight: "60px",
                    maxHeight: "40vh",
                    fontSize: "14px",
                  }}
                  type="text"
                />
              </>
            ) : props.question_type == 5 ? (
              <img src={props.user_answered} alt="" />
            ) : props.question_type == 4 ? (
              <>
                {/* <textarea
                        // defaultValue={}
                        value={props.user_answered}
                        name={props?.question}
                        style={{ maxWidth: "66vw", minHeight: "60px", maxHeight: "40vh", fontSize: '14px' }}
                        cols={100}
                        rows={10}
                      ></textarea> */}
                <Ckeditor
                  value={props.user_answered}
                  name={props?.question}
                  disablestate={true}
                />

                {props?.iscorrect === 1 ? (
                  <span
                    style={{
                      position: "relative",
                      marginLeft: "2px",
                      top: "10px",
                    }}
                  >
                    <MdOutlineCheckCircle fill="green" size={24} />
                  </span>
                ) : (
                  <span>
                    <MdOutlineCancel
                      style={{
                        position: "relative",
                        marginLeft: "2px",
                      }}
                      fill="red"
                      size={24}
                    />
                  </span>
                )}
              </>
            ) : props.question_type == 8 ? (
              <>
                <CodeMirror
                  value={props.user_answered?.source_code}
                  options={{
                    mode: `${CodeLanguageEnum[props.user_answered?.language_id]
                      }`,
                    theme: "material",
                    lineNumbers: true,
                    autofocus: true,
                    readOnly: true,
                  }}
                  onBeforeChange={() => { }}
                />

                <StyledTypography variant="h5" component="div">
                  Output: <br />
                </StyledTypography>

                <div style={{ height: calculatedHeight, overflow: "auto" }}>
                  <CodeMirror
                    value={props.user_answered?.stdout}
                    options={{
                      mode: `${CodeLanguageEnum[props.user_answered?.language_id]
                        }`,
                      theme: "material",
                      lineNumbers: false,
                      readOnly: true,
                    }}
                    onBeforeChange={() => { }}
                  />
                </div>

                <>
                  {props?.iscorrect === 1 ? (
                    <span
                      style={{
                        position: "relative",
                        marginLeft: "2px",
                      }}
                    >
                      <MdOutlineCheckCircle fill="green" size={24} />
                    </span>
                  ) : (
                    <span>
                      <MdOutlineCancel
                        style={{
                          position: "relative",
                          marginLeft: "2px",
                        }}
                        fill="red"
                        size={24}
                      />
                    </span>
                  )}
                </>
              </>
            ) : props.question_type == 9 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <video
                  src={`${process.env.REACT_APP_API_URL}/uploads/${props.user_answered}`}
                  crossOrigin="anonymous"
                  autoPlay
                  controls
                  width="300"
                  height="360"
                ></video>
                <AIInterviewResult ai_interview_result={props?.ai_interview_result ?? null} />
              </Box>
            ) : null}

            {props.question_type == 1 &&
              props?.iscorrect === 0 &&
              props?.correct_answer && (
                <p
                  style={{
                    fontSize: "14px",
                    marginTop: "40px",
                    marginLeft: "4px",
                  }}
                >
                  <span
                    style={{ fontWeight: "bold", fontSize: "14px !important" }}
                  >
                    Correct answer is :{" "}
                  </span>
                  {props?.option[parseInt(props?.correct_answer)]?.option && (
                    <span style={{ fontSize: "14px" }}>
                      {
                        <span
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              props?.option[parseInt(props?.correct_answer)]
                                ?.option
                            ),
                          }}
                        />
                      }
                    </span>
                  )}
                </p>
              )}

            {props.question_type == 8 && props?.iscorrect === 0 && (
              <div>
                <p
                  style={{
                    fontSize: "14px",
                    marginTop: "40px",
                    marginLeft: "4px",
                  }}
                >
                  <span
                    style={{ fontWeight: "bold", fontSize: "14px !important" }}
                  >
                    Correct answer is :{" "}
                  </span>

                  <CodeMirror
                    value={props.correct_answer}
                    options={{
                      mode: `${CodeLanguageEnum[props.user_answered?.language_id]
                        }`,
                      theme: "material",
                      lineNumbers: false,
                      readOnly: true,
                    }}
                    onBeforeChange={() => { }}
                  />
                </p>
              </div>
            )}

            <div style={{ marginTop: "20px" }}>
              {props?.answer_explanation && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="subtitle1" style={{ color: "black" }}>
                      Answer Explanation
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(props?.answer_explanation),
                      }}
                    ></Typography>
                  </AccordionDetails>
                </Accordion>
              )}
            </div>
          </StyledTypography>

          <StyledTypography variant="h5" component="div">
            {props.question_type === 6 && (
              <div className="fill-in-the-blank-question">
                {props?.option?.map((eachOption, index) => {
                  const userAns = props?.user_answered?.[index];

                  if (userAns) {
                    const isAnswerCorrect = isCorrect(
                      userAns,
                      eachOption.option
                    );

                    return (
                      <div
                        key={index}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {isAnswerCorrect ? (
                          <MdOutlineCheckCircle fill="green" size={24} />
                        ) : (
                          <MdOutlineCancel fill="red" size={24} />
                        )}

                        <span style={{ marginLeft: "8px" }}>
                          <span dangerouslySetInnerHTML={{ __html: userAns }} />
                        </span>

                        {!isAnswerCorrect && (
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginLeft: "10px",
                              gap: 8,
                              color: "green",
                            }}
                          >
                            <strong>Correct Answer:</strong>{" "}
                            <div
                              className="fill-in-the-blank-question-correct-answer"
                              dangerouslySetInnerHTML={{
                                __html: eachOption.option,
                              }}
                            />
                          </span>
                        )}
                      </div>
                    );
                  }

                  return null; // Skip unanswered options
                })}
              </div>
            )}
          </StyledTypography>
        </CardContent>
      </Card>
    </Element>
  );
};
