import * as React from "react";
import Box from "@mui/material/Box";
import "./Test.css";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  fetchoverview,
  fetchoverviewnoauth,
  testexists,
} from "../../../Redux/TestPage/TestSlice";
import { useIntl } from "react-intl";
import DOMPurify from "dompurify";
import { Skeleton } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import { OGPAdd } from "./Prerequisites";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import { HeadingsTypography, StyledTypography } from "../../../utils/StyledTypographyHeadings";

function MyTimer({ expiryTimestamp }: any) {
  const { testid } = useParams();

  // Parse the expiryTimestamp into a Date object
  const expiryDate = new Date(expiryTimestamp);

  const applicationMode = localStorage.getItem("application_mode");

  const handleExpire = () => {
    window.location.href = `/testoverview/${testid}`;
  };

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Row>
        <Col>
          <FlipClockCountdown
            to={expiryDate}
            onComplete={handleExpire}
            className="flip-clock"
            showLabels={true}
            labelStyle={{
              color: applicationMode === "dark" ? "#ffffff" : "#000000",
              fontSize: "1rem",
              fontWeight: 700,
            }}
          />
        </Col>
      </Row>
    </Box>
  );
}

export default function TestOverview() {
  type fetchedoverviewtype = {
    conductedby: string;
    testname: string;
    testtype: string;
    timelimit: string;
    totalquestion: string;
  };
  const intl = useIntl();

  const applicationMode = localStorage.getItem("application_mode");

  const MainLogoLight = "/media/logos/mtesthub-new-logo.svg";
  const MainLogoDark = "/media/logos/mtesthub-new-logo-dark.svg";

  const [page, setpage] = React.useState<number>(1);

  const [loading, setLoading] = React.useState<boolean>(true);

  const [fetchedoverview, setfetchedoverview] = React.useState<
    fetchedoverviewtype | any
  >({});
  const { testid } = useParams();

  const userToken = JSON.parse(
    localStorage?.getItem("userdetails") as string
  )?.token;

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const fetchedexistsdata = useAppSelector(
    (state) => state?.tests?.fetchedexists
  );
  const fetchedoverviewdata = useAppSelector(
    (state) => state?.tests?.overviewdata
  );
  React.useEffect(() => {
    const payload = {
      testid: testid,
    };
    dispatch(testexists(payload));

    if (fetchedoverviewdata?.setting?.randomphotos == "false") {
      setpage(page + 1);
    }
  }, []);

  React.useEffect(() => {
    const tokenexists = userToken ? true : false;

    let payload: any = {
      testid: testid,
    };

    if (fetchedexistsdata?.success === true) {
      if (tokenexists) {
        dispatch(fetchoverview(payload));
      } else {
        dispatch(fetchoverviewnoauth(payload));
      }
    } else if (fetchedexistsdata?.success === false) {
      window.location.href = "/home/dashboard";
    }
  }, [fetchedexistsdata]);

  React.useEffect(() => {
    setfetchedoverview(fetchedoverviewdata);
  }, [fetchedoverviewdata]);

  React.useEffect(() => {
    if (!userToken && fetchedoverviewdata?.testactive == 0) {
      navigate(`/auth/1`);
    }
  }, [fetchedoverviewdata, userToken, navigate]);

  // function gototest() {
  //   navigate(`/test/${testid}`);
  //   if (fetchedoverviewdata?.setting?.fullscreen === "true") {
  //     fullscreentogglehandler();
  //   }
  // }
  // function fullscreentogglehandler() {
  //   const fullscreenele: any = document.getElementById("forfullscreen");
  //   // if (fullscreenele.requestFullscreen) {
  //   fullscreenele.requestFullscreen();
  //   if (fullscreenele.webkitRequestFullscreen) {
  //     /* Safari */
  //     fullscreenele.webkitRequestFullscreen();
  //   } else if (fullscreenele.msRequestFullscreen) {
  //     /* IE11 */
  //     fullscreenele.msRequestFullscreen();
  //   }
  // }

  // const matches = useMediaQuery("(max-width:768px)");

  const [matches, setMatches] = React.useState<Boolean | null>(null);

  React.useEffect(() => {
    function isMobile() {
      const userAgent = navigator.userAgent.toLowerCase();
      return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    }
  
    if (isMobile()) {
      setMatches(true);
    } else {
      const desktopMediaQuery = window.matchMedia("(min-width: 1024px)");
  
      const handleMediaQueryChange = (e:any) => {
        setMatches(!e.matches);
      };
  
      setMatches(!desktopMediaQuery.matches);
  
      desktopMediaQuery.addEventListener("change", handleMediaQueryChange);
  
      return () => {
        desktopMediaQuery.removeEventListener("change", handleMediaQueryChange);
      };
    }
  }, []);
  
  
  const [timerStamp, settimerStamp] = React.useState<any>(null);

  React.useEffect(() => {
    if (fetchedoverviewdata?.refreshMilliSeconds) {
      const time = new Date();
      time.setSeconds(
        time.getSeconds() +
        Number(fetchedoverviewdata?.refreshMilliSeconds * 0.001)
      );
      settimerStamp(time);
    }
  }, [fetchedoverviewdata]);

  const minuteValue =
    Math.round(parseInt(fetchedoverview.timelimitinmin)) > 1
      ? "minutes"
      : "minute";

  React.useEffect(() => {
    if (Object.entries(fetchedoverviewdata).length > 0) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [fetchedoverviewdata]);


  return (
    <>
      <OGPAdd testid={testid} fetchedoverviewdata={fetchedoverviewdata} />
      <Box
        component="img"
        src={toAbsoluteUrl(applicationMode === "dark" ? MainLogoDark : MainLogoLight)}
        alt="mtesthub-logo"
        sx={{
          width: { xs: 150, md: 250 },
          height: 60,
          position: "absolute",
          top: 2,
          left: { xs: 20, sm: 80 },
        }}
      />
      <Box sx={{
        width: "100% !important"
      }}>

        <Box
          sx={{
            width: "100% !important",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            paddingY: 0,
            paddingX: { xs: 2, sm: 10 },
            marginTop: { xs: 4, md: 6, xl: 12 },
            marginBottom:
              fetchedoverview.test_description &&
                fetchedoverview.test_description.length > 100
                ? { xs: 0, sm: 8, lg: 10 }
                : 0,
          }}
        >

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "flex-start",
              padding: { xs: 2, sm: 5 },
              borderRadius: "10px",
              marginTop: 5,
              border: applicationMode === "light" ? "2px solid #0b3aff" : "2px solid #ffffff",
              boxShadow: "13px 16px 3px -5px rgba(11, 58, 255, 0.5)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: { xs: 'flex-start', sm: 'center' },
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "flex-start",
                gap: 5,
                width: "100%",
              }}
            >
              {loading ? (
                <Skeleton
                  sx={{ bgcolor: "#d1d1d1" }}
                  variant="rectangular"
                  animation="wave"
                  width={150}
                  height={120}
                />
              ) : (
                <Box
                  component="img"
                  src={
                    fetchedoverview?.organization_logo
                      ? `${process.env.REACT_APP_CREATOR_URL}/${fetchedoverview.organization_logo}`
                      : toAbsoluteUrl("/media/avatars/placeholder_img.png")
                  }
                  alt="Organization logo"
                  sx={{
                    // width: 180,
                    height: { xs: 50, sm: 60, lg: 100, xl: 120 },
                    objectFit: "cover",
                  }}
                />
              )}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                {loading ? (
                  [...Array(3)].map((_, i) => (
                    <Skeleton
                      key={`skeleton-${i}`}
                      variant="rectangular"
                      animation="wave"
                      // width={600}
                      // height={30}
                      sx={{
                        bgcolor: "#d1d1d1",
                        borderRadius: 1,
                        gap: 2,
                        // Mobile devices
                        "@media (max-width:500px)": {
                          width: "250px",
                          height: "40px",
                        },

                        // Medium devices
                        "@media (min-width:501px) and (max-width:900px)": {
                          width: "300px",
                          height: "30px",
                        },

                        // Large devices
                        "@media (min-width:1000px)": {
                          width: "600px",
                          height: "30px",
                        },
                      }}
                    />
                  ))
                ) : (
                  <>
                    <HeadingsTypography
                      variant="h2"
                      sx={{
                        letterSpacing: "1px",
                        color: applicationMode === "dark" ? "#ffffff" : "#03045e",
                        fontWeight: 500,
                        // fontSize: { xs: 12, sm: 14, lg: 25, xl: 28 },
                      }}
                    >
                      {fetchedoverview.testname ?? ""}
                    </HeadingsTypography>
                    <StyledTypography
                      variant="body1"
                      sx={{
                        // fontWeight: 700,
                        fontSize: { xs: 10, sm: 12, lg: 16, xl: 20 },
                      }}
                    >
                      {intl.formatMessage({ id: "TEST.CONDUCTEDBY" })}:{" "}
                      {fetchedoverview.conductedby ?? ""}
                    </StyledTypography>
                    <StyledTypography
                        variant="body2"
                      sx={{
                        // fontWeight: 700,
                        fontSize: { xs: 10, sm: 12, lg: 16, xl: 20 },
                      }}
                    >
                      {" "}
                      Duration:{" "}
                      {Math.round(
                        parseInt(fetchedoverview.timelimitinmin ?? 0)
                      )}{" "}
                      {minuteValue}
                    </StyledTypography>
                  </>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
                padding: { xs: 1, sm: 2 },
                marginTop: 2,
              }}
            >
              {loading ? (
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  sx={{
                    bgcolor: "#d1d1d1",
                    "@media (max-width:500px)": {
                      width: "250px",
                      height: "40px",
                    },

                    // Medium devices
                    "@media (min-width:501px) and (max-width:900px)": {
                      width: "400px",
                      height: "50px",
                    },

                    // Large devices
                    "@media (min-width:1000px)": {
                      width: "600px",
                      height: "50px",
                    },
                  }}
                />
              ) : (
                <>
                  <HeadingsTypography
                    variant="h4"
                    sx={{
                      // fontWeight: "bold",
                      // fontSize: { xs: 10, sm: 12, lg: 16, xl: 20 },
                    }}
                  >
                    Description:{" "}
                  </HeadingsTypography>
                  <StyledTypography
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        fetchedoverview.test_description
                      ),
                    }}
                    sx={{
                      color: "var(--kt-app-layout-grey-text-color)",
                      fontSize: { xs: 10, sm: 12, lg: 16, xl: 20 },
                    }}
                  />
                </>
              )}
            </Box>

            {!loading && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "100%",
                  padding: 2,
                  marginTop: 1,
                }}
              >
                {fetchedoverview?.success == false && (
                  <StyledTypography
                    variant="body1"
                    sx={{
                      color: "#777777",
                      letterSpacing: "1px",
                      fontSize: { xs: 10, sm: 12, lg: 16, xl: 20 },
                    }}
                  >
                    *
                    {fetchedoverview?.message
                      ? fetchedoverview?.message
                      : "You have already submitted the test"}
                  </StyledTypography>
                )}

                {fetchedoverview?.success == true &&
                  (userToken ? (
                    <Box
                      className="dflex justify-content-center align-items-center"
                      sx={{}}
                    >

                      {matches ? (
                        <>
                          <HeadingsTypography
                            variant="h5"
                            sx={{
                              color: "red",
                              textAlign: "center",
                              // fontSize: { xs: 10, sm: 12, lg: 16, xl: 20 },
                            }}
                          >
                            This test is not attemptable on mobile/tablet
                            devices kindly access it from desktop or laptop{" "}
                          </HeadingsTypography>
                        </>
                      ) : (
                        <button
                          className="styled-btn"
                          onClick={() => {
                            sessionStorage.removeItem(
                              "sortnofetched_session"
                            );
                            sessionStorage.removeItem("user_answers");
                            navigate(`/testoverviewpage/${testid}`);
                          }}
                        >
                          <span>Proceed</span>
                        </button>

                      )}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        flexDirection: "column",
                        gap: 3,
                      }}
                    >
                      <StyledTypography
                        variant="body1"
                        sx={{ fontWeight: 700, letterSpacing: "1px" }}
                      >
                        &#42;To proceed further with the test you need to login
                        first
                      </StyledTypography>

                      <button
                        className="styled-btn"
                        onClick={() => {
                          navigate(`/auth/${testid}/1`);
                        }}
                      >
                        <span>Login</span>
                      </button>
                    </Box>
                  ))}

                {fetchedoverview?.success == "false for schedule" &&
                  timerStamp && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <StyledTypography
                        variant="body1"
                        sx={{ fontWeight: 700, letterSpacing: "1px" }}
                      >
                        {fetchedoverview?.message + " in "}
                      </StyledTypography>
                      <MyTimer expiryTimestamp={timerStamp} />
                    </Box>
                  )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
