import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "./Test.css";
import axios from "axios";
import { Params, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  fetchoverview,
  fetchoverviewpreview,
  testexists,
} from "../../../Redux/TestPage/TestSlice";
import { FiInfo } from "react-icons/fi";
import Webcam from "react-webcam";
import { useIntl } from "react-intl";
import Googletranslate from "../../../_metronic/layout/components/header/header-menus/Googletranslate";
import GoBackButton from "../../modules/auth/components/GobackButton/Gobackbutton";
import { AiFillHome } from "react-icons/ai";
import DOMPurify from "dompurify";
import { Avatar, Skeleton } from "@mui/material";
import { RecordRTCPromisesHandler } from "recordrtc";
import { handleopenloader } from "../../../Redux/Loader/Loader";
import { handlestartrecording } from "../../../Redux/ScreenRecord/ScreenRecord";
import RecordRTC from "recordrtc";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Container, Row, Col, Badge } from "react-bootstrap";
import { useTimer } from "react-timer-hook";
import { handleopensnackbar } from "../../../Redux/Snackbarwithmessages/Snackbarwithmessages";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import {
  HeadingsTypography,
  StyledTypography,
} from "../../../utils/StyledTypographyHeadings";

function MyTimer({ expiryTimestamp }: any) {
  const { tokenid } = useParams();

  const { seconds, minutes, hours, days, isRunning, start } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      window.location.href = `/preview-testoverview/${tokenid}`;
    },
  });

  React.useEffect(() => {
    start();
  }, [start]);

  return (
    <Container className="text-center">
      <Row>
        <Col>
          <Badge bg="primary" className="timer-badge">
            {days ? (
              <span className="timer-part">
                {days} {days > 1 ? "days" : "day"}
              </span>
            ) : null}
            {hours ? (
              <span className="timer-part">
                {hours} {hours > 1 ? "hours" : "hour"}
              </span>
            ) : null}
            {minutes ? (
              <span className="timer-part">
                {minutes} {minutes > 1 ? "minutes" : "minute"}
              </span>
            ) : null}
            <span className="timer-part">{seconds} seconds</span>
          </Badge>
        </Col>
      </Row>
    </Container>
  );
}

export default function TestOverview() {
  const { tokenid } = useParams();

  type fetchedoverviewtype = {
    conductedby: string;
    testname: string;
    testtype: string;
    timelimit: string;
    totalquestion: string;
  };
  const intl = useIntl();

  const applicationMode = localStorage.getItem("application_mode");

  const MainLogoLight = "/media/logos/mtesthub-new-logo.svg";
  const MainLogoDark = "/media/logos/mtesthub-new-logo-dark.svg";

  const [page, setpage] = React.useState<number>(1);

  const [loading, setLoading] = React.useState<boolean>(true);

  const [fetchedoverview, setfetchedoverview] = React.useState<
    fetchedoverviewtype | any
  >({});

  const dispatch = useAppDispatch();
  const fetchedexistsdata = useAppSelector(
    (state) => state?.tests?.fetchedexists
  );
  const fetchedoverviewdata = useAppSelector(
    (state) => state?.tests?.overviewdata
  );

  // console.log("fetchedoverviewdata", fetchedoverviewdata);

  React.useEffect(() => {
    if (fetchedoverviewdata?.setting?.randomphotos == "false") {
      setpage(page + 1);
    }
  }, []);

  React.useEffect(() => {
    const payload = {
      testid: tokenid,
    };
    dispatch(fetchoverviewpreview(payload));
  }, []);

  React.useEffect(() => {
    setfetchedoverview(fetchedoverviewdata);
  }, [fetchedoverviewdata]);

  const navigate = useNavigate();

  const [matches, setMatches] = React.useState<Boolean | null>(null);

  React.useEffect(() => {
    function isMobile() {
      const userAgent = navigator.userAgent.toLowerCase();
      return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );
    }

    if (isMobile()) {
      setMatches(true);
    } else {
      const desktopMediaQuery = window.matchMedia("(min-width: 1024px)");

      const handleMediaQueryChange = (e: any) => {
        setMatches(!e.matches);
      };

      setMatches(!desktopMediaQuery.matches);

      desktopMediaQuery.addEventListener("change", handleMediaQueryChange);

      return () => {
        desktopMediaQuery.removeEventListener("change", handleMediaQueryChange);
      };
    }
  }, []);

  const [timerStamp, settimerStamp] = React.useState<any>(null);

  React.useEffect(() => {
    if (fetchedoverviewdata?.refreshMilliSeconds) {
      const time = new Date();
      time.setSeconds(
        time.getSeconds() +
          Number(fetchedoverviewdata?.refreshMilliSeconds * 0.001)
      );
      settimerStamp(time);
    }
  }, [fetchedoverviewdata]);

  const minuteValue =
    Math.round(parseInt(fetchedoverview.timelimitinmin)) > 1
      ? "minutes"
      : "minute";

  React.useEffect(() => {
    if (Object.entries(fetchedoverviewdata).length > 0) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [fetchedoverviewdata]);

  return (
    <>
      <Box
        component="img"
        src={toAbsoluteUrl(
          applicationMode === "dark" ? MainLogoDark : MainLogoLight
        )}
        alt="mtesthub-logo"
        sx={{
          width: { xs: 150, md: 250 },
          height: 60,
          position: "absolute",
          top: 2,
          left: { xs: 20, sm: 80 },
        }}
      />

      <Box sx={{ width: "100% !important" }}>
        <Box
          sx={{
            width: "100% !important",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            paddingY: 0,
            paddingX: { xs: 2, sm: 10 },
            marginTop: { xs: 4, md: 6, xl: 12 },
            marginBottom:
              fetchedoverview.test_description &&
              fetchedoverview.test_description.length > 100
                ? { xs: 0, sm: 8, lg: 10 }
                : 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "flex-start",
              padding: { xs: 2, sm: 5 },
              borderRadius: "10px",
              marginTop: 5,
              border:
                applicationMode === "light"
                  ? "2px solid #0b3aff"
                  : "2px solid #ffffff",
              boxShadow: "13px 16px 3px -5px rgba(11, 58, 255, 0.5)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: { xs: "flex-start", sm: "center" },
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "flex-start",
                gap: 5,
                width: "100%",
              }}
            >
              {loading ? (
                <Skeleton
                  sx={{ bgcolor: "#d1d1d1" }}
                  variant="rectangular"
                  animation="wave"
                  width={150}
                  height={120}
                />
              ) : (
                <Box
                  component="img"
                  src={
                    fetchedoverview?.organization_logo
                      ? `${process.env.REACT_APP_CREATOR_URL}/${fetchedoverview.organization_logo}`
                      : toAbsoluteUrl("/media/avatars/placeholder_img.png")
                  }
                  alt="Organization logo"
                  sx={{
                    // width: 180,
                    height: { xs: 50, sm: 60, lg: 100, xl: 120 },
                    objectFit: "cover",
                  }}
                />
              )}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                {loading ? (
                  [...Array(3)].map((_, i) => (
                    <Skeleton
                      key={`skeleton-${i}`}
                      variant="rectangular"
                      animation="wave"
                      sx={{
                        bgcolor: "#d1d1d1",
                        borderRadius: 1,
                        gap: 2,
                        // Mobile devices
                        "@media (max-width:500px)": {
                          width: "250px",
                          height: "40px",
                        },

                        // Medium devices
                        "@media (min-width:501px) and (max-width:900px)": {
                          width: "300px",
                          height: "30px",
                        },

                        // Large devices
                        "@media (min-width:1000px)": {
                          width: "600px",
                          height: "30px",
                        },
                      }}
                    />
                  ))
                ) : (
                  <>
                    <HeadingsTypography
                      variant="h2"
                      sx={{
                        letterSpacing: "1px",
                        color:
                          applicationMode === "dark" ? "#ffffff" : "#03045e",
                        fontWeight: 500,
                        // fontSize: { xs: 12, sm: 14, lg: 25, xl: 28 },
                      }}
                    >
                      {fetchedoverview.testname ?? ""}
                    </HeadingsTypography>
                    <StyledTypography
                      variant="body1"
                      sx={{
                        // fontWeight: 700,
                        fontSize: { xs: 10, sm: 12, lg: 16, xl: 20 },
                      }}
                    >
                      {intl.formatMessage({ id: "TEST.CONDUCTEDBY" })}:{" "}
                      {fetchedoverview.conductedby ?? ""}
                    </StyledTypography>
                    <StyledTypography
                      variant="body2"
                      sx={{
                        // fontWeight: 700,
                        fontSize: { xs: 10, sm: 12, lg: 16, xl: 20 },
                      }}
                    >
                      {" "}
                      Duration:{" "}
                      {Math.round(
                        parseInt(fetchedoverview.timelimitinmin ?? 0)
                      )}{" "}
                      {minuteValue}
                    </StyledTypography>
                  </>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
                padding: { xs: 1, sm: 2 },
                marginTop: 2,
              }}
            >
              {loading ? (
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  sx={{
                    bgcolor: "#d1d1d1",
                    "@media (max-width:500px)": {
                      width: "250px",
                      height: "40px",
                    },

                    // Medium devices
                    "@media (min-width:501px) and (max-width:900px)": {
                      width: "400px",
                      height: "50px",
                    },

                    // Large devices
                    "@media (min-width:1000px)": {
                      width: "600px",
                      height: "50px",
                    },
                  }}
                />
              ) : (
                <>
                  <HeadingsTypography
                    variant="h4"
                    sx={{
                      // fontWeight: "bold",
                      // fontSize: { xs: 10, sm: 12, lg: 16, xl: 20 },
                    }}
                  >
                    Description:{" "}
                  </HeadingsTypography>
                  <StyledTypography
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        fetchedoverview.test_description
                      ),
                    }}
                    sx={{
                      color: "var(--kt-app-layout-grey-text-color)",
                      fontSize: { xs: 10, sm: 12, lg: 16, xl: 20 },
                    }}
                  />
                </>
              )}
            </Box>

            {!loading && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "100%",
                  padding: 2,
                  marginTop: 1,
                }}
              >
                {fetchedoverview?.success == false && (
                  <StyledTypography
                    sx={{
                      color: "#777777",
                      letterSpacing: "1px",
                      fontSize: { xs: 10, sm: 12, lg: 16, xl: 20 },
                    }}
                    variant="body1"
                  >
                    *
                    {fetchedoverview?.message
                      ? fetchedoverview?.message
                      : "You have already submitted the test"}
                  </StyledTypography>
                )}

                {fetchedoverview?.success == true && (
                  <Box
                    className="dflex justify-content-center align-items-center"
                    sx={{}}
                  >
                    {fetchedoverviewdata?.setting?.fullscreen === "true" ? (
                      !matches ? (
                        <>
                          <button
                            className="styled-btn"
                            onClick={() => {
                              navigate(`/preview-testoverview/${tokenid}`);
                            }}
                          >
                            <span>Proceed</span>
                          </button>
                        </>
                      ) : (
                        <HeadingsTypography
                          variant="h5"
                          sx={{
                            color: "red",
                            textAlign: "center",
                          }}
                        >
                          This test is not attemptable on mobile/tablet devices
                          kindly access it from desktop or laptop{" "}
                        </HeadingsTypography>
                      )
                    ) : (
                      <>
                        <button
                          className="styled-btn"
                          onClick={() => {
                            navigate(`/preview-testoverview/${tokenid}`);
                          }}
                        >
                          <span>Proceed</span>
                        </button>
                      </>
                    )}
                  </Box>
                )}

                {fetchedoverview?.success == "false for schedule" &&
                  timerStamp && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <StyledTypography
                        variant="body1"
                        sx={{ fontWeight: 700, letterSpacing: "1px" }}
                      >
                        {fetchedoverview?.message + " in "}
                      </StyledTypography>
                      <MyTimer expiryTimestamp={timerStamp} />
                    </Box>
                  )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
