import React from "react";
import { Modal } from "react-bootstrap";
import "./Fullscreenmodal.css";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";

const InternetTurnOffModal = ({ isModalOpenResize }) => {
  return (
    <Modal
      show={isModalOpenResize}
      onHide={null}
      backdrop="static"
      disableEscapeKeyDown
      disableBackdropClick
    >
      <Modal.Body style={{ border: "1px solid red" }}>
        <div>
          <h2>Warning: Your internet connection has been lost!</h2>
          <div>
            <img
              src={toAbsoluteUrl("/media/no-internet.png")}
              height={130}
              alt="no internet img"
            />
          </div>
          <p>
            To ensure a smooth testing experience, please reconnect to the
            internet immediately. Continuous internet access is required to
            participate in the assessment. If you do not restore your
            connection, you may not be able to complete the test.
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default InternetTurnOffModal;
