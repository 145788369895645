import React, { useState, useEffect } from "react";
import { StyledTypography } from "../../../../../utils/StyledTypographyHeadings";

const PermissionMessage = ({ microphone, location }: { microphone?: boolean, location?: boolean }) => {
  const [browser, setBrowser] = useState("");
  const [permissionLink, setPermissionLink] = useState("");

  const message = location? "location" : microphone ? "microphone" : "camera";

  useEffect(() => {
    const detectBrowser = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.includes("edg")) {
        return "edge";
      } else if (userAgent.includes("opera") || userAgent.includes("opr")) {
        return "opera";
      } else if (userAgent.includes("chrome") && !userAgent.includes("edg")) {
        return "chrome";
      } else if (userAgent.includes("firefox") || userAgent.includes("fxios")) {
        return "firefox";
      } else if (userAgent.includes("safari") && !userAgent.includes("chrome")) {
        return "safari";
      } else {
        return "default";
      }
    };

    let browserLinks: any;

    if(location){
      browserLinks = {
        chrome:
          "https://support.google.com/chrome/answer/142065?hl=en&co=GENIE.Platform%3DDesktop",
        firefox:
          "https://support.mozilla.org/bm/questions/1237471",
        safari: "https://support.apple.com/en-in/guide/personal-safety/ips9bf20ad2f/web",
        opera: "https://help.opera.com/en/latest/web-preferences/#location",
        edge: "https://support.microsoft.com/en-us/microsoft-edge/location-and-privacy-in-microsoft-edge-31b5d154-0b1b-90ef-e389-7c7d4ffe7b04#:~:text=Visit%20a%20website%20in%20Microsoft,or%20Block%20from%20the%20list.",
        default: "https://support.google.com",
      };
    }else {
      browserLinks = {
        chrome:
          "https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DDesktop",
        firefox:
          "https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions",
        safari: "https://discussions.apple.com/thread/255231012?sortBy=rank",
        opera: "https://help.opera.com/en/latest/web-preferences/",
        edge: "https://support.microsoft.com/en-us/windows/windows-camera-microphone-and-privacy-a83257bc-e990-d54a-d212-b5e41beba857#:~:text=In%20Microsoft%20Edge%2C%20select%20Settings,Permissions%2C%20select%20Camera%20or%20Microphone.",
        default: "https://support.google.com",
      };
    }

    const detectedBrowser = detectBrowser();
    setBrowser(detectedBrowser);
    setPermissionLink(browserLinks[detectedBrowser]);
  }, [location]);

  const permissionMessages: any = {
    chrome:
      `If you're having trouble granting permissions for ${message}, click here to learn how to enable them for Chrome.`,
    firefox:
      `If you're having trouble granting permissions for ${message}, click here to learn how to enable them for Firefox.`,
    safari:
      `If you're having trouble granting permissions for ${message}, click here to learn how to enable them for Safari.`,
    edge: `If you're having trouble granting permissions for ${message}, click here to learn how to enable them for Edge.`,
    opera: `If you're having trouble granting permissions for ${message}, click here to learn how to enable them for Opera.`,
    default:
      `If you're having trouble granting permissions for ${message}, click here to learn how to enable them in your browser.`,
  };

  return (
    <div>
      <StyledTypography variant="body1" sx={{ fontSize: { xs: 10, sm: 16, xl: 18 } }}>
        <StyledTypography variant="body1" component="span" sx={{fontSize: { xs: 10, sm: 16, xl: 18 }, fontWeight: 800 }}>
          Note:
        </StyledTypography> {permissionMessages[browser]}{" "}
        <a style={{ color: "blue" }} href={permissionLink} target="_blank" rel="noopener noreferrer">
          Click here
        </a>
        .
      </StyledTypography>
    </div>
  );
};

export default PermissionMessage;
