import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { Rating } from '@mui/material';
import { handleopensnackbar } from '../../../Redux/Snackbarwithmessages/Snackbarwithmessages';
import { useAppDispatch, useAppSelector } from '../../../Redux/hooks';
import { RecommendedAssigned } from '../dashboard/RecommendedAssigned';
import { fetchassignedtestseries } from '../../../Redux/Dashboard/Testseries';

const FeedbackForm = ({ show, handleClose, testid }) => {
    const [rating, setRating] = useState('');
    const [comment, setComment] = useState('');
    const dispatch = useAppDispatch();
   
    React.useEffect(() => {
        const payload = {
            type: "all",
        };
        dispatch(fetchassignedtestseries(payload));
    }, [dispatch]);

    const fetcheddata = useAppSelector(
        (state) => state.testseries.fetcheddataprivatedashboard
    );

    const [error, seterror] = useState(null)

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if both rating and comment are provided
        if (!rating || !comment) {
            const payloaddata = {
                messages: "Please provide both rating and comment.",
                severity: "error",
            };
            seterror("Please provide both rating and comment.")

            dispatch(handleopensnackbar(payloaddata));
            return; // Stop further execution if validation fails
        }

        seterror(null)
        try {
            await axios.post('/getexamdata/sendfeedback', { testid, rating, comment });
            const payloaddata = {
                messages: "Feedback submitted successfully",
                severity: "success",
            };

            dispatch(handleopensnackbar(payloaddata));
        } catch (error) {
            console.error('Error submitting feedback:', error);
            const payloaddata = {
                messages: "Error submitting feedback",
                severity: "error",
            };

            dispatch(handleopensnackbar(payloaddata));
        }

        // Reset form fields
        setRating('');
        setComment('');

        // Close the modal
        handleClose();
        handleRemoveFeedback();
    };

    const [isFeedbackdone, setIsFeedbackdone] = useState(0)
    const handleRemoveFeedback = () => {
        setIsFeedbackdone(1);
    }

    return (
        <Modal size='xl' show={show} onHide={handleClose}>
            {!isFeedbackdone ?
                <Modal.Header closeButton>
                    <h2>How was your experience with the test?</h2>
                </Modal.Header> : <></>
            }


            <Modal.Body>
                {!isFeedbackdone ?
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="rating" className='p-2'>
                            <Form.Label>Rating:</Form.Label>
                            <Rating
                                name="simple-controlled"
                                className='position-relative'
                                style={{ top: '6px' }}
                                size="large"
                                value={rating}
                                onChange={(e) => { setRating(e.target.value) }}
                            />
                        </Form.Group>
                        <Form.Group controlId="comment" className='p-2'>
                            <Form.Label>Comment:</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                            // style={{ width: "40vw" }}
                            />
                        </Form.Group>

                        {error ? <p style={{ color: "red" }}>{error}</p> : null}
                        <div className='d-flex mt-4' style={{ justifyContent: 'space-between' }}>

                            <Button variant="primary" className='mt-4 p-2' style={{ marginLeft: '4px' }} type="submit">
                                Submit Feedback
                            </Button>
                        </div>
                    </Form>
                    : <></>
                }

                {fetcheddata && <RecommendedAssigned fetcheddata={fetcheddata} handleClose={handleClose} isFeedbackdone={isFeedbackdone} />}
            </Modal.Body>

        </Modal>
    );
};

export default FeedbackForm;
