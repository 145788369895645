import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./Fullscreenmodal.css";

const ShareEntireScreenModal = ({
  isModalOpen,
  setIsModalOpen,
  modalsRef,
  handleShareScreen,
}) => {
  const handleModalClose = () => {
    setIsModalOpen(false);
    if (modalsRef) {
      modalsRef.current.share_entire_screen_modal = 0;
    }
  };
  return (
    <Modal
      show={isModalOpen}
      onHide={null}
      backdrop="static"
      disableEscapeKeyDown
      disableBackdropClick
    >
      <Modal.Body style={{ border: "1px solid red" }}>
        <div>
          <h2>Warning: looks like you have not shared your entire screen</h2>
          <p>
            Please make sure you have shared your entire screen for screen
            sharing to proceed further with the test.
          </p>
          <button
            className="btn btn-danger"
            onClick={async () => {
              const result = await handleShareScreen();
              if (result) {
                handleModalClose();
              }
            }}
          >
            Share Screen Again
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShareEntireScreenModal;
