import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  messages: "",
  severity: "",
  autoClose: 5000,
};

export const Snackbarslice = createSlice({
  name: "Snackbar",
  initialState,
  reducers: {
    handleopensnackbar: (state, action) => {
      state.open = true;
      state.messages = action.payload.messages;
      state.severity = action.payload.severity;
      state.autoClose = action.payload.autoClose;
    },
    handleclosesnackbar: (state) => {
      state.open = false;
      state.messages = "";
      state.severity = "";
      state.autoClose = 5000;
    },
  },
});

export const { handleopensnackbar, handleclosesnackbar } =
  Snackbarslice.actions;
export default Snackbarslice.reducer;
