/* eslint-disable jsx-a11y/anchor-is-valid */
import axios, { AxiosResponse } from "axios";
import React, { FC, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import {
  CardsWidget17,
  CardsWidget20,
  CardsWidget7,
  Questioncardwidget,
} from "../../../_metronic/partials/widgets";
import { answeredvalues, fetchdata } from "../../../Redux/TestPage/TestSlice";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { Button } from "react-bootstrap";
import { Online, Offline } from "react-detect-offline";
import { Alert, Snackbar } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
// import SubmitModal from "./SubmitModal";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { handleopensnackbar } from "../../../Redux/Snackbarwithmessages/Snackbarwithmessages";
import {
  handlecloseloader,
  handleopenloader,
} from "../../../Redux/Loader/Loader";
import { CameraModal } from "../../modules/auth/components/CameraModal";
import WebcamCapture from "../../modules/auth/components/MainCamera";
// import Camerafortest from "./Camerfortest/Camerafortest";
import { handlelocationpermission } from "../../../Redux/Checkpermissions/Permission";
import {
  fetchdashboard,
  fetchsubmittedtest,
} from "../../../Redux/Dashboard/Testseries";
import { CardsWidget21 } from "../../../_metronic/partials/widgets/_new/cards/CardsWidget21";
import "./Resultpage.css";
import { CardsWidget22 } from "../../../_metronic/partials/widgets/_new/cards/CardsWidget22";
import { fetchresultpage } from "../../../Redux/ResultPage/Resultpage";
var Scroll = require("react-scroll");
var scroller = Scroll.scroller;

const Testpage = (props: any) => {
  const { requestid } = props
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(fetchresultpage(dispatch));
  }, []);

  const fetcheddataresult = useAppSelector<any>(
    (state) => state.resultpage.fetcheddataresult
  );



  return (
    <>
      {/* begin::Row */}
      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        {/* begin::Col */}
        <div>
          {/* add tests question here */}
          {/* <CardsWidget17 className="abc" /> */}
          {/* <Dashboardcarousel /> */}
          {/* <div className="filterbuttonparentdiv" style={{ marginTop: '-20px !important' }}>
                        <SubmittedFilter />
                    </div> */}
        </div>
        <div className="seriesContainer2">
          {fetcheddataresult?.result?.map((each: any) => {
            return (
              <>
                {/* {console.log(each)} */}
                <div style={{ marginRight: "10px", marginBottom: "10px" }}>
                  <CardsWidget22
                    className="abc"
                    description={each?.description}
                    color="rgb(58, 51, 53)"
                    requestid={requestid}
                    resultname={each?.name}
                    declaredon={each?.requested_date}
                    reportid={each?.id}
                    sendinmail={each?.send_in_email}
                  />
                </div>
              </>
            );
          })}
          {fetcheddataresult?.result?.length == 0 && (
            <>
              <div style={{ marginTop: "10px" }}>
                <p style={{ color: "red" }}>No result data found</p>
              </div>
            </>
          )}
        </div>
        {/* end::Col */}
        <div className="submitdiv">{/* </Online> */}</div>
      </div>
      {/* <Online> */}
      {/* <SubmitModal handleClose={handleClose} show={show} submithandler={() => { submithandler(1) }} /> */}
      {/* <Offline>
        <Snackbar
          open={true}
          autoHideDuration={6000}
        // onClose={handleClose}
        // action={action}
        >
          <Alert variant="filled" severity="error">
            You are currently offline please turn on your internet else your
            exam won't be submitted
          </Alert>
        </Snackbar>
      </Offline> */}

      {/* end::Row */}

      {/* begin::Row */}
      <div className="row gx-5 gx-xl-10">
        {/* begin::Col */}
        <div className="col-xxl-6 mb-5 mb-xl-10">
          {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
      {/* {fetchedsetting?.randomphotos && <Camerafortest isready={isready} />} */}
    </>
  );
};

const Resultpage: FC = () => {
  const intl = useIntl();
  const { requestid } = useParams();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "Report card/Marksheets" })}
      </PageTitle>

      <Testpage requestid={requestid} />
    </>
  );
};

export { Resultpage };
