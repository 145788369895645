export const cleanObject = (obj: any) => {
  const newObj: any = {};

  for (const key in obj) {
    const value = obj[key];

    // If the value is an object, process its keys
    if (typeof value === "object" && value !== null) {
      const filteredObj: any = {};

      // Keep only the keys where the value is not 0 or an empty string
      for (const nestedKey in value) {
        if (
          (typeof value[nestedKey] === "number" && value[nestedKey] !== 0) ||
          (typeof value[nestedKey] === "string" && value[nestedKey].trim() !== "")
        ) {
          filteredObj[nestedKey] = value[nestedKey];
        }
      }

      // Only add the non-empty filtered object to the newObj
      if (Object.keys(filteredObj).length > 0) {
        newObj[key] = filteredObj;
      }
    }
    // If the value is a non-empty string, add it
    else if (typeof value === "string" && value.trim() !== "") {
      newObj[key] = value;
    }
    // If the value is a valid finite number, add it
    else if (typeof value === "number" && Number.isFinite(value) && value !== 0) {
      newObj[key] = value;
    }
  }

  return newObj;
};
