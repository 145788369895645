/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { KTSVG, toAbsoluteUrl } from "../../../../helpers";
import SummaryOfanswered from "./allquestionsaside/SummaryOfanswered";
import Jumptoquestion from "./Jumptoquestion";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { Steps, Hints } from 'intro.js-react';
import 'intro.js/introjs.css';
import introJs from 'intro.js';
import { useAppSelector } from "../../../../../Redux/hooks";
import { useMediaQuery } from "@mui/material";
import { siteConfig } from "../../../../../config/siteConfig";

const SidebarMenuMaindashboard = () => {
  const intl = useIntl();
  const [stepsEnabled, setstepsEnabled] = useState(false)
  const [stepsEnabledstatus, setstepsEnabledstatus] = useState(false)
  const location = useLocation();
  const fetcheddata = useAppSelector(
    (state) => state.testseries.fetcheddatadashboard
  );

  useEffect(() => {

    if (stepsEnabledstatus && fetcheddata) {
      setTimeout(() => {
        setstepsEnabled(true)
        introJs().start();
      }, 200);
    }

  }, [stepsEnabledstatus])
  const matches = useMediaQuery('(max-width:990px)');
  const [steps, setsteps] = useState<any>([])
  useEffect(() => {
    if (matches) {
      setsteps([
        {
          element: '.seriesContainer',
          intro: 'The test are all here it contains name of the test,description of the test,test settings,duration,preregistration time,status of test,and start test button',
          position: 'left',
          tooltipClass: 'myTooltipClass',
          highlightClass: 'myHighlightClass',
        },
        {
          element: '.searchofdash',
          intro: 'search bar which autocompletes while typing for ease of usage',
          tooltipClass: 'myTooltipClass',
          highlightClass: 'myHighlightClass',
        },
        {
          element: '.filteronpage',
          intro: 'filter which contains filter according to type of the test for time saving',
          tooltipClass: 'myTooltipClass',
          highlightClass: 'myHighlightClass',
        },

      ])
    } else {

      setsteps([
        {
          element: '.Dashboard-home',
          intro: 'The dashboard displays the count of assigned, public, and attempted tests, along with essential links for quick access.',
          tooltipClass: 'myTooltipClass',
          highlightClass: 'myHighlightClass',
        },
        {
          element: '.test_series',
          intro: 'The Test Gallery contains a comprehensive collection of publicly available tests.',
          tooltipClass: 'myTooltipClass',
          highlightClass: 'myHighlightClass',
        },

        {
          element: '.assigned_series',
          intro: 'The Assigned Test section displays all tests that have been assigned to you.',
          tooltipClass: 'myTooltipClass',
          highlightClass: 'myHighlightClass',
        },
        {
          element: '.attempted_test',
          intro: 'Your attempted tests can be found in the Attempted Test section.',
          tooltipClass: 'myTooltipClass',
          highlightClass: 'myHighlightClass',
        },
        {
          element: '.announcements',
          intro: 'Announcements by your organization will be displayed here',
          tooltipClass: 'myTooltipClass',
          highlightClass: 'myHighlightClass',
        },
        {
          element: '.marksheets',
          intro: 'If your organization has generated a report card then all marksheets/report card will be accesible here',
          tooltipClass: 'myTooltipClass',
          highlightClass: 'myHighlightClass',
        },
        {
          element: '.my_profile',
          intro: 'You can change about you in this menu and also toggle dark mode',
          tooltipClass: 'myTooltipClass',
          highlightClass: 'myHighlightClass',
        },
        {
          element: '.faq',
          intro: `All the frequently asked question and the tutorial videos how to use ${siteConfig?.sitename}  platform is visible here`,
          tooltipClass: 'myTooltipClass',
          highlightClass: 'myHighlightClass',
        },
        {
          element: '.seriesContainer',
          intro: 'The test are all here it contains name of the test,description of the test,test settings,duration,preregistration time,status of test,and start test button',
          position: 'left',
          tooltipClass: 'myTooltipClass',
          highlightClass: 'myHighlightClass',
        },
        {
          element: '.searchofdash',
          intro: 'search bar which autocompletes while typing for ease of usage',
          tooltipClass: 'myTooltipClass',
          highlightClass: 'myHighlightClass',
        },
        {
          element: '.filteronpage',
          intro: 'filter which contains filter according to type of the test for time saving',
          tooltipClass: 'myTooltipClass',
          highlightClass: 'myHighlightClass',
        },
        {
          element: '.how_to_use',
          intro: 'To replay this demo please click on this',
          tooltipClass: 'myTooltipClass',
          highlightClass: 'myHighlightClass',
        },
      ])
    }
  }, [matches])

  const navigate = useNavigate();

  const onExit = () => {
    setstepsEnabled(false);
    setstepsEnabledstatus(false);
  };


  return (
    <>
      {location.pathname == "/dashboard/gallery" && <Steps
        enabled={stepsEnabled}
        steps={steps}
        initialStep={0}
        onExit={onExit}
      />}
      <SidebarMenuItem
        to="/home/dashboard"
        icon="/media/icons/duotune/general/gen008.svg"
        title={intl.formatMessage({ id: "Dashboard" })}
        fontIcon="bi-app-indicator"
        className={`Dashboard-home`}

      />
      {/* <SidebarMenuItem
        to="/dashboard/gallery"
        icon="/media/icons/duotune/files/fil001.svg"
        title={intl.formatMessage({ id: "Test Gallery" })}
        fontIcon="bi-app-indicator"
        className={`test_series`}

      /> */}
      <SidebarMenuItem
        to="/dashboard/assigned"
        icon="/media/icons/duotune/general/gen028.svg"
        title={intl.formatMessage({ id: "Assigned Test" })}
        fontIcon="bi-app-indicator"
        className={`assigned_series`}

      />
      <SidebarMenuItem
        to="/dashboard/attemptedseries"
        icon="/media/icons/duotune/general/gen005.svg"
        title={intl.formatMessage({ id: "Attempted Test" })}
        fontIcon="bi-app-indicator"
        className={`attempted_test`}
      />
      {/* <SidebarMenuItem
        to="/dashboard/announcements"
        icon="/media/icons/duotune/coding/cod002.svg"
        title={intl.formatMessage({ id: "Announcements" })}
        fontIcon="bi-app-indicator"
        className={`announcements`}
      /> */}
      {/* <SidebarMenuItem
        to="/dashboard/mycertificates"
        icon="/media/icons/duotune/graphs/gra001.svg"
        title={intl.formatMessage({ id: "Report card/Marksheets" })}
        fontIcon="bi-app-indicator"
        className={`marksheets`}
      /> */}
      {/* <SidebarMenuItem
        to="/dashboard/myprofile"
        icon="/media/icons/duotune/communication/com006.svg"
        title={intl.formatMessage({ id: "My Profile" })}
        fontIcon="bi-app-indicator"
        className={`my_profile`}
      /> */}

      {/* <SidebarMenuItem
        to="/dashboard/faq"
        icon="/media/icons/duotune/general/gen046.svg"
        title={intl.formatMessage({ id: "FAQ" })}
        fontIcon="bi-app-indicator"
        className={`faq`}
      /> */}
      {/* <SidebarMenuItem
        to=""
        onClick={() => { if (location.pathname != "/dashboard/gallery") { navigate("/dashboard/gallery"); setstepsEnabledstatus(true); } else { setstepsEnabledstatus(true); } }}
        icon="/media/icons/duotune/general/gen045.svg"
        title={intl.formatMessage({ id: "How to use" })}
        fontIcon="bi-app-indicator"
        className={`how_to_use`}
      /> */}

    </>
  );
};

export { SidebarMenuMaindashboard };
