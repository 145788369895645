/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useEffect, useState } from "react";
import {
  Routes,
  Route,
  BrowserRouter,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { Logout, AuthPage, useAuth } from "../modules/auth";
import { App } from "../App";
import { useAppSelector } from "../../Redux/hooks";
import Termsandcondition from "../pages/Termsandcondition/Termsandcondition";
import { AuthLayout } from "../modules/auth/AuthLayout";
import { Loginfortest } from "../modules/auth/components/Loginfortest";
import { Registration } from "../modules/auth/components/Registration";
import { ForgotPassword } from "../modules/auth/components/ForgotPassword";

import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import { TestPreview } from "../pages/PreviewPages/TestPreview";
import Prerequisitespreview from "../pages/PreviewPages/PrequisitePreview";
import TestOverviewPreview from "../pages/PreviewPages/TestOverviewPreview";
import { Testwrapper } from "../pages/testpage/Testwrapper";
import TestOverview from "../pages/testpage/TestOverview";

const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const [tokendata, settokendata] = useState<string | null>("");

  useEffect(() => {
    settokendata(localStorage?.getItem("userdetails"));
  }, [localStorage?.getItem("userdetails")]);

  return (
    <div>
      <BrowserRouter basename={PUBLIC_URL}>
        <Routes>
          <Route element={<App />}>
            <Route path="error/*" element={<ErrorsPage />} />
            <Route path="logout" element={<Logout />} />
            {tokendata ? (
              <>
                <Route path="/*" element={<PrivateRoutes />} />

                <Route index element={<Navigate to="/home/dashboard" />} />
                <Route path="/terms" element={<Termsandcondition />} />
              </>
            ) : (
              <>
                <Route element={<AuthLayout />}>
                  <Route path="auth/:testid/:id" element={<Loginfortest />} />
                  <Route path="auth/:id" element={<Loginfortest />} />
                </Route>

                <Route
                  path="/testoverview/:testid"
                  element={<TestOverview />}
                />

                <Route path="/dashboard/assigned" element={<Loginfortest />} />

                <Route path="*" element={<Navigate to="/auth/1" />} />
              </>
            )}

            <Route element={<MasterLayout />}>
              <Route path="/preview-test/:tokenid" element={<Testwrapper />} />
            </Route>
            <Route
              path="/preview-testoverview/:tokenid"
              element={<Prerequisitespreview />}
            />
            <Route
              path="/preview-testpage/:tokenid"
              element={<TestOverviewPreview />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export { AppRoutes };
