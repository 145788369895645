import { Box, Card, CardContent } from "@mui/material";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useAppSelector } from "../../../../../../Redux/hooks";
import Colorlabel from "../colorlabel/Colorlabel";
import { cleanObject } from "../../../../../../app/pages/testpage/clearnAnswerObjects";
var Scroll = require("react-scroll");
var scroller = Scroll.scroller;

type Props = {};

const SummaryOfanswered = (props: Props) => {
  const answereddata = useAppSelector((state) => state.tests.answereddata);
  const sortnofetched = useAppSelector((state) => state.tests.fetchedsortno);
  const memoizedsortnofetched = React.useMemo(() => sortnofetched, [sortnofetched]);
  const [answeredvalues, setansweredvalues] = React.useState<string[]>([]);
  useEffect(() => {
    const data = Object.keys(cleanObject(answereddata));


    //solution for summary of answered after test creator side complete it
    const newarry: any = []
    data.map((Each: any) => {
      return newarry.push(memoizedsortnofetched.includes(parseInt(Each)) ? memoizedsortnofetched.indexOf(parseInt(Each)) + 1 : "")
    })

    setansweredvalues(newarry);
  }, [memoizedsortnofetched, answereddata]);

  function gotoquestion(each: number) {
    scroller.scrollTo(`${each}-id`, {
      duration: 0,
      delay: 0,
      smooth: true,
      offset: -120, // Scrolls to element + 50 pixels down the page
    });
  }


  const intl = useIntl();
  return (
    <div id="summaryparentdiv">
      <Card style={{ minHeight: "12vh", border: "1px solid #888888" }}>
        <CardContent>
          <div>
            <div style={{ display: 'flex' }}>
              <Colorlabel />
            </div>

            {/* {intl.formatMessage({ id: "TEST.ANSWEREDQUESTIONS" })} */}
            <div>
              {memoizedsortnofetched.length === 0 && (
                <p style={{ marginTop: "18px", color: "red" }}>
                  {intl.formatMessage({ id: "TEST.SUMMARYOFANSWERED" })}
                </p>
              )}

              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(40px, 1fr))',
                  gap: 1,
                  mt: 1,
                  alignItems: 'center',
                }}
              >
                {memoizedsortnofetched?.map((each: any, index: any) => (
                  <div
                    key={index}
                    onClick={() => gotoquestion(index + 1)}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "40px",
                      height: "40px",
                      background: answeredvalues.includes(index + 1) ? "green" : "grey",
                      cursor: "pointer",
                      borderRadius: "4px",
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    {index + 1}
                  </div>
                ))}
              </Box>

              <div style={{ marginTop: '10px' }}>
                Answered Questions: {answeredvalues.length} / {memoizedsortnofetched.length}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>

  );
};

export default SummaryOfanswered;
