/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import { KTSVG, toAbsoluteUrl } from "../../../../helpers";
import SummaryOfanswered from "./allquestionsaside/SummaryOfanswered";
import Jumptoquestion from "./Jumptoquestion";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { useAppSelector } from "../../../../../Redux/hooks";

const SidebarMenuMain = () => {
  const intl = useIntl();
  const sortnofetched = useAppSelector((state) => state.tests.fetchedsortno);
  console.log("🚀 ~ file: SidebarMenuMain.tsx:14 ~ SidebarMenuMain ~ sortnofetched:", sortnofetched)

  return (
    <>
      {/* <SidebarMenuItem
        to="/dashboard"
        icon="/media/icons/duotune/art/art002.svg"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="bi-app-indicator"
      /> */}
      <SummaryOfanswered />
      {/* {sortnofetched && sortnofetched.length > 1 &&
        <Jumptoquestion />

      } */}
    </>
  );
};

export { SidebarMenuMain };
