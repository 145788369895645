import React from 'react';
import './AnimatedRecordingIcon.css';

const AnimatedRecordingIcon = () => {
  return (
    <div className="recording-icon">
      <div className="recording-dot"></div>
      <span className="recording-text">REC</span>
    </div>
  );
};

export default AnimatedRecordingIcon;
