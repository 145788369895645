/* eslint-disable jsx-a11y/anchor-is-valid */
import axios, { AxiosResponse } from "axios";
import React, { FC, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import {
  CardsWidget17,
  CardsWidget20,
  CardsWidget7,
  Questioncardwidget,
} from "../../../_metronic/partials/widgets";
import { answeredvalues, fetchdata } from "../../../Redux/TestPage/TestSlice";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { Button } from "react-bootstrap";
import { Online, Offline } from "react-detect-offline";
import { Alert, Snackbar } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
// import SubmitModal from "./SubmitModal";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { handleopensnackbar } from "../../../Redux/Snackbarwithmessages/Snackbarwithmessages";
import {
  handlecloseloader,
  handleopenloader,
} from "../../../Redux/Loader/Loader";
import { CameraModal } from "../../modules/auth/components/CameraModal";
import WebcamCapture from "../../modules/auth/components/MainCamera";
// import Camerafortest from "./Camerfortest/Camerafortest";
import { handlelocationpermission } from "../../../Redux/Checkpermissions/Permission";
import Dashboardcarousel from "./carousel-dashboard/Dashboard-carousel";
import "./Dashboard.css";
import Dashboardsearch from "./dashboard-searchbox/Dashboardsearch";
import { fetchassignedtestseries, fetchdashboard, fetchsubmittedtest } from "../../../Redux/Dashboard/Testseries";
import DashboardFilter from "./DashboardFilter/DashboardFIlter";
import { LessDataCardsWidget20 } from "../../../_metronic/partials/widgets/_new/cards/LessDataCardsWidget20";
var Scroll = require("react-scroll");
var scroller = Scroll.scroller;
type Props = { fetcheddata: any, isFeedbackdone: Number, handleClose: any };

const Testpage = (props: Props) => {
  const { handleClose, isFeedbackdone } = props;
  const [fetchdata, setfetcheddata] = useState<any>({
    result: [],
    success: "",
    result2: [],
  });
  const [count, setcount] = useState([{ id: "" }]);
  const [countOne, setcountOne] = useState(0);
  const dispatch = useAppDispatch();


  useEffect(() => {
    let countone = 0;
    setfetcheddata(props.fetcheddata);
    props.fetcheddata?.result?.map((each: any) => {
      // setcount(each?.id);
      if (each?.multiple_time === 0) {
        countone = countone + 1;
      }
    });
    setcountOne(countone)
  }, [props.fetcheddata]);


  useEffect(() => {
    if (!countOne && isFeedbackdone) {
      handleClose();

    }
  }, [countOne, isFeedbackdone, handleClose])


  return (
    countOne ? <>
      <hr />
      <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
        <div>
          <h2>You have {countOne} more {countOne === 1 ? "assignment" : "assignments"}  to take. Kindly proceed with it.</h2>
        </div>
        <div className="seriesContainer">
          {fetchdata?.result?.map((each: any, index: any) => {
            return (
              each?.multiple_time === 0 &&
              <>

                <div key={index} style={{ marginRight: "10px", marginBottom: "10px" }}>
                  <LessDataCardsWidget20
                    className="abc"
                    description={each?.test_description}
                    color="rgb(58, 51, 53)"
                    title={each?.test_name}
                    createdat={each?.created_at}
                    duration={each?.duration}
                    scheduled={each?.schedule_datetime}
                    img={""}
                    testid={each?.assesment_id}
                    test_settings={each?.test_settings}
                    istestseries={each?.multiple_time}
                    result_declare={each?.result_declare}
                    count={count}
                    id={each?.test_serires_id}
                    fetchdata={fetchdata?.result2}
                    preregistration_status={each?.ispreregistration}
                    preregistration_to={each?.preregistrationdatetimeto}
                    preregisteration_from={each?.preregistrationdatetimefrom}
                    fetchdataprewhole={fetchdata?.preresult}
                  />
                </div>
              </>
            );
          })}
          {fetchdata?.result?.length <= 0 ||
            !fetchdata ||
            (fetchdata?.success == false && (
              <>
                <div style={{ marginTop: "-40px" }}>
                  <p style={{ color: "red" }}>No test series found</p>
                </div>
              </>
            ))}
        </div>
        {/* end::Col */}
        <div className="submitdiv">{/* </Online> */}</div>
      </div>



      <div className="row gx-5 gx-xl-10">
        {/* begin::Col */}
        <div className="col-xxl-6 mb-5 mb-xl-10">

        </div>
        {/* end::Col */}
      </div>

    </> : <></>
  );
};

const RecommendedAssigned: FC = (props: any) => {
  const intl = useIntl();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "Assigned Test" })}
      </PageTitle>

      <Testpage fetcheddata={props.fetcheddata} handleClose={props.handleClose} isFeedbackdone={props.isFeedbackdone} />
    </>
  );
};

export { RecommendedAssigned };
