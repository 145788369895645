import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { useLayout } from "../../core";
import { Footer } from "./Footer";
import "./Footer.css";

const FooterWrapper = () => {
  const location = useLocation();
  const { config } = useLayout();
  if (!config.app?.footer?.display) {
    return null;
  }

  return (
    <>
      {!location.pathname.includes("testoverview") && (
        <div className="app-footer" id="kt_app_footer">
          {config.app.footer.containerClass ? (
            <div
              style={{
                width: "-webkit-fill-available",
              }}
              className={clsx(
                "app-container",
                config.app.footer.containerClass
              )}
            >
              <Footer />
            </div>
          ) : (
            <Footer />
          )}
        </div>
      )}
    </>
  );
};

export { FooterWrapper };
