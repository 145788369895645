import * as React from "react";
import Stack from "@mui/material/Stack";
import { useSelector } from "react-redux/es/exports";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function Snackbarwithmessage() {
  const opendata = useSelector((state) => state?.snackbar?.open);
  const severity = useSelector((state) => state?.snackbar?.severity);

  const messages = useSelector((state) => state?.snackbar?.messages);

  const autoClose = useSelector((state) => state?.snackbar?.autoClose);



  const notifyToast = async (msg, severity, autoClose=5000) => {
    switch (severity) {
      case "success":
        return toast.success(msg, {
          position: "top-right",
          autoClose: autoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      case "error":
        return toast.error(msg, {
          position: "top-right",
          autoClose: autoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      case "info":
        return toast.info(msg, {
          position: "top-right",
          autoClose: autoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      default:
        return toast(msg, {
          position: "top-right",
          autoClose: autoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
    }
  }


  React.useEffect(() => {
    if (opendata) {
      notifyToast(messages, severity, autoClose)
    }
  }, [opendata, severity, messages, autoClose])

  return (
    <>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <ToastContainer />
      </Stack>
    </>
  );
}
