import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
};

export const LoaderSlice = createSlice({
  name: "Loader",
  initialState,
  reducers: {
    handleopenloader: (state) => {
      state.open = true;
    },
    handlecloseloader: (state) => {
      state.open = false;
    },
  },
});

export const { handleopenloader, handlecloseloader } = LoaderSlice.actions;
export default LoaderSlice.reducer;
