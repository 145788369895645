import { Box, Typography } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import { HeadingsTypography, StyledTypography } from "../../../../../utils/StyledTypographyHeadings";

interface Props {
  className: string;
  title: string;
  description: string;
  icon?: boolean;
  iconColor: string;
  stats: string;
  labelColor: string;
  textColor: string;
  url: string;
  disabled?: boolean;
}

const CardsWidget7: React.FC<Props> = ({
  className,
  title,
  description,
  icon,
  iconColor,
  stats,
  labelColor,
  textColor,
  url,
  disabled,
}) => {
  const navigate = useNavigate();
  const applicationMode = localStorage.getItem("application_mode");
  return (
    <Box
      onClick={() => {
        navigate(url);
      }}
      sx={{ boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1) !important", cursor: "pointer", borderRadius: 2, border: applicationMode === "light" ? "1px solid #000000" : "1px solid #ffffff", flexBasis: {xs: 0 ,md: 300}, width: {xs: "100%", sm: "80%", md: "100%"},  paddingTop: 1, paddingLeft: 2, paddingRight: 2, paddingBottom: 1 }}
    >
      <Box sx={{gap: 10}}>
        <HeadingsTypography variant="h5">{title}</HeadingsTypography>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginY: 2}}>
          <HeadingsTypography variant="h4">{stats}</HeadingsTypography>
          <StyledTypography variant="body2">{icon}</StyledTypography>
          <SignalCellularAltIcon  sx={{color: applicationMode === "light" ? iconColor : "#ffffff", fontSize: 40}} />
        </Box>
      </Box>
    </Box>
  );
};
export { CardsWidget7 };
