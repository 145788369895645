import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useAppDispatch, useAppSelector } from "../../../../../../Redux/hooks";
import { timerComplete } from "../../../../../../Redux/TestPage/TestSlice";

import "./timerstyles.css";
import { Box } from "@mui/material";
import { handleopensnackbar } from "../../../../../../Redux/Snackbarwithmessages/Snackbarwithmessages";
import { toAbsoluteUrl } from "../../../../../helpers";

let datapayload = {
  severity: "info",
  autoClose: false,
};

let timeUpMsg = "The allocated time for this test is now up. Your test is being automatically submitted, and no further changes can be made"
let FiveMinLeftMsg = "Only 5 minutes remain for this test. The test will be automatically submitted once the time is up. Please review everything and make any necessary changes before submission. Once submitted, the test cannot be undone."

const formatRemainingTime = (time) => {
  // const hours = String(Math.floor(time / 3600)).padStart(2, "0");
  const minutes = String(Math.floor(time / 60)).padStart(2, "0");
  const seconds = String(time % 60).padStart(2, "0");

  return `${minutes} : ${seconds}`;
};

const TimerComponent = ({ remainingTime, totalTime }) => {
  const dispatch = useAppDispatch();
  
  const [totalRemainingTime, setTotalRemainingTime] = useState(remainingTime);

  const [messageDisplayed, setMessageDisplayed] = useState(false);

  const lastCheckedTimeRef = useRef(Date.now());
  
  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      const elapsed = (now - lastCheckedTimeRef.current) / 1000;
      lastCheckedTimeRef.current = now;

      setTotalRemainingTime(prev => Math.max(0, prev - elapsed));

      if (totalRemainingTime <= 0) {
        dispatch(timerComplete(true));
        showNotification(timeUpMsg, "timeup");
        datapayload = {
          ...datapayload,
          messages: timeUpMsg,
        };
        dispatch(handleopensnackbar(datapayload));
        clearInterval(interval);
      } else if (Math.round(totalTime) > 300000) {
        if (Math.round(totalRemainingTime) <= 300 && !messageDisplayed) {
          showNotification(FiveMinLeftMsg);
          datapayload = {
            ...datapayload,
            messages: FiveMinLeftMsg,
          };
          dispatch(handleopensnackbar(datapayload));
          setMessageDisplayed(true);
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [totalRemainingTime, messageDisplayed, dispatch, totalTime]);

  
  const showNotification = (message, type="5minsleft") => {
    if (Notification.permission === 'granted') {
      const options = {
        icon: toAbsoluteUrl("/media/logos/mTestHubIconLogo.png"),
        requireInteraction: type !== "timeup",
      };
      
      const notification = new Notification(message, options);
      if(type!== "timeup"){
        notification.onclick = () => window.focus();
      }
    }
  };

  // Handle when the tab is inactive
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        lastCheckedTimeRef.current = Date.now();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, []);

  return (
    <>
      <div>{formatRemainingTime(remainingTime)}</div>
    </>
  );
};

export default function Timercontainer() {
  const [timervalue, settimervalue] = useState(null);

  const fetchedtimer = useAppSelector((state) => state?.tests?.fetchedtimer);
  const totaltime = useAppSelector((state) => state?.tests?.totaltime);

  useEffect(() => {
    settimervalue(fetchedtimer / 1000);
  }, [fetchedtimer]);

  return (
    <div className="App">
      {timervalue && (
        <div className="timer-wrapper">
          <CountdownCircleTimer
            size={64}
            strokeWidth={3}
            isPlaying
            duration={totaltime ? Math.round(totaltime) / 1000 : timervalue}
            initialRemainingTime={timervalue && timervalue}
            colors={["#008000", "#ffc710", "#f55b0d", "#d50404"]}
            colorsTime={[
              totaltime / 1500,
              totaltime / 2500,
              totaltime / 5000,
              totaltime / 100000,
            ]}
            onComplete={() => [true, 1000]}
          >
            {({ remainingTime }) => (
              <TimerComponent
                remainingTime={remainingTime}
                totalTime={totaltime}
              />
            )}
          </CountdownCircleTimer>
        </div>
      )}
    </div>
  );
}
