import { lazy, FC, Suspense, useMemo, memo } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { Testwrapper } from "../pages/testpage/Testwrapper";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import TestOverview from "../pages/testpage/TestOverview";
import { Dashboard } from "../pages/dashboard/Dashboard";
import { SubmittedTest } from "../pages/submittedtest/submittedtest";
import { PreRegistration } from "../modules/auth/components/PreRegistration";
import { Resultpage } from "../pages/Resultpage/Resultpage";
import { PrivateTestDashboard } from "../pages/dashboard/PrivateTestDashboard";
import { Initialdash } from "../pages/InitialDash/InitialDash";
import Prerequisites from "../pages/testpage/Prerequisites";

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  const FaqPage = lazy(() => import("../pages/faq/Faq"));
  const AllvideotutorialsPage = lazy(
    () => import("../pages/faq/Allvideotutorials")
  );
  const AnnouncementpageLazy = lazy(
    () => import("../pages/announcements/Announcementspage")
  );
  const AfterSubmissionScreenCaptureLazy = lazy(
    () => import("../pages/testpage/AfterSubmissionScreenCapture")
  );
  const TestresultLazy = lazy(
    () => import("../pages/submittedtest/Certificates/Testresult")
  );
  const DownloadResultLazy = lazy(
    () => import("../pages/Resultpage/DownloadResult")
  );

  const MemoizedComponent = memo(Testwrapper);

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/test/:testid" />} />
        {/* Pages */}
        <Route path="/test/:id" element={<MemoizedComponent />} />
        <Route path="/home/dashboard/*" element={<Initialdash />} />
        <Route path="/dashboard/gallery/*" element={<Dashboard />} />
        <Route
          path="/dashboard/assigned/*"
          element={<PrivateTestDashboard />}
        />
        <Route
          path="/dashboard/attemptedseries/*"
          element={<SubmittedTest />}
        />
        <Route
          path="/dashboard/preregister/:id"
          element={<PreRegistration />}
        />
      
        {/* Lazy Modules */}
        <Route
          path="/dashboard/faq"
          element={
            <SuspensedView>
              <FaqPage />
            </SuspensedView>
          }
        />
        <Route
          path="/dashboard/faq/videotutorials"
          element={
            <SuspensedView>
              <AllvideotutorialsPage />
            </SuspensedView>
          }
        />
        <Route
          path="/dashboard/myprofile"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        <Route
          path="/dashboard/announcements/*"
          element={
            <SuspensedView>
              <AnnouncementpageLazy />
            </SuspensedView>
          }
        />
        <Route
          path="/dashboard/attemptedtest/step3/:id"
          element={
            <SuspensedView>
              <AfterSubmissionScreenCaptureLazy />
            </SuspensedView>
          }
        />
        <Route
          path="/dashboard/getcertificate/:testid/:testname"
          element={
            <SuspensedView>
              <TestresultLazy />
            </SuspensedView>
          }
        />
        <Route
          path="/dashboard/mycertificates/:todowithdata/:requestid"
          element={
            <SuspensedView>
              <DownloadResultLazy />
            </SuspensedView>
          }
        />

        {/* normal routes */}

        <Route path="/dashboard/mycertificates" element={<Resultpage />} />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
      
      <Route path="/testoverviewpage/:testid" element={<Prerequisites />} />
      <Route path="/testoverview/:testid" element={<TestOverview />} />
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--kt-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
