import "./Colorlabel.css"

const Colorlabel = () => {
    return (
        <>
            <div className="d-flex align-items-center justify-content-between parentdivcolorlabel mx-1">
                <div className="d-flex align-items-center justify-content-between">

                    <div className='colordiv grey'>

                    </div>
                    <p className='para-color'>Not Answered</p>
                </div>
                <div className="d-flex align-items-center justify-content-between">

                    <div className='colordiv green'>

                    </div>
                    <p className='para-color'>Answered</p>
                </div>

            </div>
        </>
    )
}

export default Colorlabel