import { useEffect, useLayoutEffect, useState } from "react";
import { Button, OverlayTrigger } from "react-bootstrap";
import { FaPlay } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import DashboardModal from "../../../../../app/pages/dashboard/dashboardModal/DashboardModal";
import { VscInspect } from "react-icons/vsc";
import { BsCamera, BsMouse } from "react-icons/bs";
import Tooltip from "react-bootstrap/Tooltip";
import { FiCopy, FiDownload } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { GrDocumentPdf, GrDownload } from "react-icons/gr";
import { MdOutlineSchedule } from "react-icons/md";
import "./CardsWidget21.css";
import Modalfortestreview from "../../../../../app/pages/submittedtest/Modalfortestreview/Modalfortestreview";
import Modalforremarks from "../../../../../app/pages/submittedtest/Modalforremarks/Modalforremarks";
import { AiOutlineReload } from "react-icons/ai";

type Props = {
  className: string;
  description: string;
  resultname: string;
  declaredon: string;
  color: string;
  reportid: number;
  sendinmail: number;
  requestid: number
};

const renderTooltip = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    This is a scheduled test
  </Tooltip>
);
const renderTooltip2 = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    Download certificate pdf
  </Tooltip>
);
const renderTooltip3 = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    Download result pdf
  </Tooltip>
);
const renderTooltip4 = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    Camera needed
  </Tooltip>
);
const renderTooltip5 = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    Fullscreen
  </Tooltip>
);
const renderTooltip6 = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    Location needed
  </Tooltip>
);

export function CardsWidget22({
  className,
  description,
  resultname,
  declaredon,
  color,
  reportid,
  sendinmail,
  requestid
}: Props) {
  const [show, setShow] = useState(false);


  const navigate = useNavigate();
  const handleShow = () => {
    if (requestid) {
      navigate(`/dashboard/mycertificates/download/${requestid}`)
    } else {
      navigate(`/dashboard/mycertificates/view/${reportid}`)
    }
  }
  // alert(requestid) 

  useEffect(() => {
    if (requestid) {
      navigate(`/dashboard/mycertificates/download/${requestid}`)
    }
  }, [])

  let year, date, month, hour, minute, seconds

  [year, date, month] = (declaredon).slice(0, 10).split("-");
  [hour, minute, seconds] = (declaredon).slice(11).split(":")



  return (
    <>
      <div
        className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className} attemptedseriesmaindiv`}
        style={{
          backgroundColor: "#03045e",
          height: "fit-content",
        }}
      >
        <div
          className="card-header pt-5"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <div className="card-title d-flex flex-column">
            <span className="fs-2hx fw-bold text-white me-2 lh-1 ls-n2">
              {resultname}
            </span>

            {description?.length > 86 ? (
              <span className="text-white opacity-75 pt-1 fw-semibold fs-6">
                {description.slice(0, 87) + "..."}
              </span>
            ) : (
              <span className="text-white opacity-75 pt-1 fw-semibold fs-6">
                {description}
              </span>
            )}

            {/* description */}
            <div
              className="d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100  mb-4"
              style={{ marginTop: "-18px !important", height: "20px" }}
            >
              {description?.length > 89 && (
                <p
                  style={{
                    color: "white",
                    cursor: "pointer",
                    marginTop: "-2px",
                    textDecoration: "underline",
                  }}
                >
                  {" "}
                  Read more
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="card-body  align-items-end pt-0">
          <div className="d-flex align-items-center flex-column mt-3 w-100">
            <div
              className="d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100  mb-4"
              style={{ marginTop: "-18px !important", cursor: 'pointer' }}
            >
              <span>Result declared on {hour + ":" + minute + " " + date + "-" + month + "-" + year}</span>
              {sendinmail && (
                <span
                  style={{
                    cursor: "pointer !important",
                    textDecoration: "underline",
                  }}
                  onClick={handleShow}
                >
                  View Result
                </span>
              )}
            </div>
            <div
              className="d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100  mb-4"
              style={{ marginTop: "-18px !important" }}
            >
              {/* {JSON.parse(test_settings).randomphotos && JSON.parse(test_settings).randomphotos == 'true' && <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip4}
              >
                <p>

                  <BsCamera style={{ cursor: 'pointer' }} size={20} />
                </p>
              </OverlayTrigger>}
              {scheduled && <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <p>

                  <MdOutlineSchedule style={{ cursor: 'pointer' }} size={20} />
                </p>
              </OverlayTrigger>} */}
            </div>
            {/* {show && (
              <Modalforresult
                show={show}
                handleClose={handleClose}
                title={resultname}
                reportid={reportid}
              />
            )} */}
          </div>
        </div>
      </div>
    </>
  );
}
