import { Typography } from "@mui/material";
import axios from "axios";
import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import "./Modalfortest.css"

type Props = {
    show: boolean;
    handleClose: () => void;
};

const Termsandconditionmodal = (props: Props) => {
    const { show, handleClose } =
        props;

    // alert(remarks);

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Terms and conditions</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ width: "100vw !important" }}>
                    <p style={{ padding: "40px" }}>
                        I understand that Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid dolor ab sequi, ipsum nemo consequatur blanditiis doloremque vel accusantium maiores sit! Illum magni quod eligendi architecto consectetur totam suscipit, voluptatibus quae, hic, corporis voluptatem minus perferendis. A, debitis. Soluta aperiam numquam vero amet fugiat sit, id, ullam velit unde tempora nesciunt illo eius necessitatibus voluptatem ipsam saepe odio accusamus voluptatum. Doloremque, itaque. Officia excepturi fugit est explicabo harum quis repudiandae facere eligendi debitis laboriosam. Tenetur nulla, fugiat, ullam cum debitis magnam officia eum ab praesentium eligendi rerum nobis voluptate. Praesentium delectus quod rerum incidunt, voluptate vero at voluptates nesciunt unde fugiat minima quo aperiam cum. Maxime quis adipisci voluptatem architecto? Fugiat expedita velit libero voluptatum enim deleniti nesciunt consequatur laboriosam, pariatur dolorum optio vitae, ipsa incidunt, dicta molestiae sint consectetur. Illum earum nisi reprehenderit eveniet nobis necessitatibus accusamus veritatis, quidem ab, officiis assumenda enim eligendi temporibus dolores autem corporis qui quasi maxime a, similique adipisci. Voluptate, odio soluta! Laborum numquam id, corrupti atque at quidem. Totam tempore necessitatibus est eaque consequuntur, iste, nobis qui minima, dignissimos earum odit reprehenderit consequatur deleniti? Et magni, molestiae rerum sequi hic deserunt quo tempora dolorem accusamus commodi totam odit aspernatur error illo vero molestias sint enim quae eveniet. Id expedita corporis sunt laudantium explicabo? Numquam nihil eius et repellendus, repudiandae perspiciatis labore debitis nulla velit atque voluptatem rem expedita, ea sit eligendi vero deleniti, quo voluptatum natus ad aliquam blanditiis perferendis eos asperiores. Sint eius itaque inventore praesentium animi tenetur nisi voluptas. Reprehenderit, at?

                    </p>

                    <Modal.Footer className="modalfooterparent">




                    </Modal.Footer>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Termsandconditionmodal;
